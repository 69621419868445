<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="$store.state.cashier != ''">
      <div v-if="day_block > 0">
        <div
          v-if="
            partnerBalance.tempo_package == 0 ||
            partnerBalance.tempo_package >= 2 * partnerBalance.permanent_package
          "
          class="text-center mt-16 white--text"
        >
          <h2>Insufficient Balance, Please contact the Admin of this system</h2>
        </div>

        <div v-else>
          <div class="hidden-sm-and-down">
            <v-app-bar elevation="6" class="white pr-0" height="auto">
              <span
                href="#"
                style="
                  color: #37b34a;
                  font-size: 19px;
                  text-decoration: none;
                  margin-top: -29px;
                  margin-right: 295px;
                "
                >Retail Logo</span
              >

              <v-btn
                @click="
                  cashierOption = true;
                  getMoneyOnHands();
                  getSpinMoneyOnHands();
                  repoDate = Date.now();
                  getAllSummary();
                "
                class="pt-1 pb-1 mt-1 mb-1 mr-2"
                style="font-size: 13px"
                dark
                text
                id="loginBtn"
              >
                <span class="text-capitalize">Cashier Options</span>
              </v-btn>
              <v-btn
                v-if="whichToShow == 'keno'"
                @click="cancelDialog = true"
                class="pt-1 pb-1 mt-1 mb-1 mr-2"
                style="
                  font-size: 13px;

                  background-color: #f0ad4e;
                "
                dark
                text
              >
                <span class="text-capitalize mr-2">Cancel</span
                ><v-icon
                  style="font-size: 16px; font-weight: bold"
                  small
                  class="white--text"
                  >block</v-icon
                >
              </v-btn>
              <v-btn
                v-if="whichToShow == 'spin'"
                @click="spinCancelDialog = true"
                class="pt-1 pb-1 mt-1 mb-1 mr-2"
                style="
                  font-size: 13px;

                  background-color: #f0ad4e;
                "
                dark
                text
              >
                <span class="text-capitalize mr-2">Cancel</span
                ><v-icon
                  style="font-size: 16px; font-weight: bold"
                  small
                  class="white--text"
                  >block</v-icon
                >
              </v-btn>
              <v-btn
                v-if="whichToShow == 'keno'"
                @click="verifyDialog = true"
                class="pt-1 pb-1 mt-1 mb-1 mr-2"
                style="font-size: 13px"
                dark
                text
                id="loginBtn"
              >
                <span class="text-capitalize mr-2">Redeem</span>
                <v-img height="25px" contain src="../assets/usd.png"> </v-img>
                <!-- <v-icon
                  style="font-size: 20px; font-weight: normal"
                  class="white--text"
                  >attach_money</v-icon
                > -->
              </v-btn>

              <v-btn
                v-if="whichToShow == 'spin'"
                @click="SpinVerifyDialog = true"
                class="pt-1 pb-1 mt-1 mb-1 mr-2"
                style="font-size: 13px"
                dark
                text
                id="loginBtn"
              >
                <span class="text-capitalize mr-2">Redeem</span>
                <v-img height="25px" contain src="../assets/usd.png"> </v-img>
                <!-- <v-icon
                  style="font-size: 20px; font-weight: normal"
                  class="white--text"
                  >attach_money</v-icon
                > -->
              </v-btn>

              <v-spacer></v-spacer>

              <v-layout
                row
                wrap
                class=""
                style="margin-right: -10px; margin-top: -15px"
              >
                <v-flex xs12 class="text-right">
                  <span
                    style="
                      font-size: 11px;
                      font-family: Sans-serif;
                      clear: right;
                      float: right;
                    "
                    class="grey--text"
                    >{{ new Date(Date.now()) | formatDateNew }}</span
                  >
                </v-flex>

                <v-flex xs12 class="text-right pr-1" style="margin-top: -3px">
                  <span
                    style="font-size: 15px; font-family: Sans-serif"
                    class="grey--text"
                  >
                    {{
                      brachData.branch_name +
                      "  " +
                      $store.state.cashier.cashier_full_name.split(".")[0]
                    }}
                    ({{
                      $store.state.cashier.cashier_full_name
                    }})&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <a
                      @click="logoutClicked"
                      class="mr-0"
                      href="#"
                      style="
                        color: #37b34a;
                        font-size: 15px;
                        text-decoration: none;
                      "
                      >Logout</a
                    >
                  </span>
                </v-flex>
              </v-layout>

              <!-- <v-layout row wrap class="" style="font-size: 14px">
                  <v-flex xs2 class="pt-0" v-if="currentGame.length > 0">
                   
                  </v-flex>
                  <v-flex xs2 class="mr-2 pr-1 pl-1">
                    <p>
                      <v-progress-linear
                        v-model="timePercent"
                        height="8"
                      ></v-progress-linear>
                    </p>
                  </v-flex>
                  <v-flex
                    class="red--text"
                    xs2
                    v-if="minutes == '00' && parseInt(seconds) <= 20"
                  >
                    <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                  </v-flex>

                  <v-flex class="" xs2 v-else>
                    <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="verifyDialog = true"
                    >
                      <span class="text-capitalize">Verify</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="cancelDialog = true"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs1 class="pl-2">
                    <v-btn text x-small @click="tmhPopUp = true">
                      <span class="text-capitalize"
                        >TMH
                       
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout> -->

              <!-- <v-spacer></v-spacer> -->

              <!-- <v-btn small text @click="whichToShow = 'spin'">
                  <span class="mr-2 text-capitalize orange--text text--darken-1"
                    >Switch to Spin</span
                  >
                </v-btn> -->
              <!-- <v-btn x-small text @click="getUnpaid" :loading="unpaidLoading">
                  <span class="text-capitalize red--text text--darken-1"
                    >Tickets
                  </span>
                </v-btn> -->
              <!-- <v-btn small text @click="reportTicketDialog = true">
                  <span class="mr-2 text-capitalize">Report keno</span>
                </v-btn>
                <v-btn small text @click="logoutClicked">
                  <span class="mr-2 text-capitalize">Logout</span>
                </v-btn>
                <a @click="helpDialog = true">Help</a> -->
            </v-app-bar>
            <v-layout row wrap>
              <v-flex xs9 class="pa-2 pr-0 pt-2 pl-1">
                <v-layout row wrap>
                  <v-flex xs8>
                    <a @click="whichToShow = 'horse'" class="mr-2"
                      ><img
                        v-if="whichToShow == 'horse'"
                        class="clickedicons"
                        height="28px"
                        width="37px"
                        contain
                        src="../assets/DashingDerbyIcon.svg" />
                      <img
                        v-else
                        class="icon imgicons"
                        height="28px"
                        width="37px"
                        contain
                        src="../assets/DashingDerbyIcon.svg"
                    /></a>
                    <a @click="whichToShow = 'dog'" class="mr-2"
                      ><img
                        v-if="whichToShow == 'dog'"
                        class="clickedicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/PlatinumHoundsIcon.svg" />
                      <img
                        v-else
                        class="dicon imgicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/PlatinumHoundsIcon.svg"
                    /></a>

                    <a @click="whichToShow = 'car'"
                      ><img
                        v-if="whichToShow == 'car'"
                        class="clickedicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/MotorRacingIcon.svg" />
                      <img
                        v-else
                        class="dicon imgicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/MotorRacingIcon.svg"
                    /></a>

                    <a @click="whichToShow = 'lucky'"
                      ><img
                        v-if="whichToShow == 'lucky'"
                        class="clickedicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/LuckyLoot624Icon.svg" />
                      <img
                        v-else
                        class="dicon imgicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/LuckyLoot624Icon.svg"
                    /></a>

                    <a
                      @click="
                        whichToShow = 'spin';
                        whichIcon = gameItems[4];
                      "
                      style="margin-left: -11px"
                      ><img
                        v-if="whichToShow == 'spin'"
                        class="clickedicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/SpinAndWinIcon.svg" />
                      <img
                        v-else
                        class="dicon imgicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/SpinAndWinIcon.svg"
                    /></a>

                    <a
                      @click="
                        whichToShow = 'keno';
                        whichIcon = gameItems[5];
                      "
                      style="margin-left: -12px"
                      ><img
                        v-if="whichToShow == 'keno'"
                        class="clickedicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/SmartPlayKenoIcon.svg" />
                      <img
                        v-else
                        class="dicon imgicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/SmartPlayKenoIcon.svg"
                    /></a>

                    <a
                      @click="getUnpaid"
                      :loading="unpaidLoading"
                      style="margin-top: -10px"
                      class="text-capitalize red--text text--darken-1 pa-2 ml-3 pt-0"
                    >
                      .
                    </a>
                  </v-flex>
                  <v-flex
                    xs4
                    class="pl-12"
                    style="margin-top: -3px; margin-left: -14px"
                  >
                    <v-layout row wrap>
                      <v-flex xs2>
                        <v-menu
                          rounded
                          transition="scale-transition"
                          location="bottom"
                          internal-activator
                          max-height="270px"
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <a
                              small
                              text
                              class="text-capitalize"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon x-large class="green--text">apps</v-icon>
                            </a>
                          </template>

                          <v-list>
                            <v-list-item-group
                              v-model="group4"
                              active-class="white--text  green"
                            >
                              <div v-for="item in gameItems" :key="item.name">
                                <v-list-item
                                  class="green pl-7 pr-7"
                                  v-if="whichToShow == item.name"
                                  @click="
                                    whichToShow = item.name;
                                    whichIcon = item;
                                  "
                                >
                                  <v-list-item-title class="pr-2">
                                    <v-layout row wrap>
                                      <v-flex xs3 class="pt-1">
                                        <img
                                          class="gicon"
                                          height="28px"
                                          width="37px"
                                          contain
                                          :src="
                                            require(`../assets/${item.img}.svg`)
                                          "
                                        />
                                      </v-flex>
                                      <v-flex xs9 class="pt-1">
                                        <span
                                          style="font-size: 14px"
                                          class="white--text"
                                        >
                                          {{ item.title }}
                                        </span>

                                        <p
                                          style="
                                            font-size: 11px;
                                            margin-top: -2px;
                                            margin-bottom: 0px;
                                          "
                                          class="white--text"
                                        >
                                          {{ item.cap }}
                                        </p>
                                      </v-flex>
                                      <!-- <v-flex xs9 class="pt-1" v-else>
                                  <span style="font-size: 14px" class="green--text">
                                {{ item.title }}
                              </span>
                              
                              <p style="font-size: 11px; margin-top: -2px; margin-bottom: 0px" class="green--text">
                                {{ item.cap }}
                              </p>
                                </v-flex> -->
                                    </v-layout>
                                  </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  class="pl-7 pr-7"
                                  v-else
                                  @click="
                                    whichToShow = item.name;
                                    whichIcon = item;
                                  "
                                >
                                  <v-list-item-title class="pr-2">
                                    <v-layout row wrap>
                                      <v-flex xs3 class="pt-1">
                                        <img
                                          class="icon imgicons"
                                          height="28px"
                                          width="37px"
                                          contain
                                          :src="
                                            require(`../assets/${item.img}.svg`)
                                          "
                                        />
                                      </v-flex>
                                      <v-flex xs9 class="pt-1">
                                        <span
                                          style="font-size: 14px"
                                          class="green--text"
                                        >
                                          {{ item.title }}
                                        </span>

                                        <p
                                          style="
                                            font-size: 11px;
                                            margin-top: -2px;
                                            margin-bottom: 0px;
                                          "
                                          class="green--text"
                                        >
                                          {{ item.cap }}
                                        </p>
                                      </v-flex>
                                    </v-layout>
                                  </v-list-item-title>
                                </v-list-item>
                              </div>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <v-flex xs2 class="pt-1" style="margin-left: -11px">
                        <span>
                          <img
                            class="seicon"
                            height="30px"
                            width="37px"
                            contain
                            :src="require(`../assets/${whichIcon.img}.svg`)"
                        /></span>
                      </v-flex>

                      <v-flex
                        style="margin-left: -5px"
                        xs7
                        class="pt-2"
                        v-if="
                          whichIcon.name == 'spin' ||
                          whichIcon.name == 'keno' ||
                          whichIcon.name == 'lucky'
                        "
                      >
                        <span style="font-size: 14px" class="grey--text">
                          {{ whichIcon.title }}
                        </span>

                        <p
                          style="
                            font-size: 11px;
                            margin-top: -2px;
                            margin-bottom: 0px;
                          "
                          class="grey--text"
                        >
                          {{ whichIcon.cap }}
                        </p>
                      </v-flex>

                      <v-flex xs7 class="pt-0" v-else style="margin-left: -5px">
                        <span style="font-size: 14px" class="grey--text">
                          {{ whichIcon.title }}
                        </span>

                        <p
                          style="
                            font-size: 11px;
                            margin-top: -4px;
                            margin-bottom: 0px;
                          "
                          class="grey--text"
                        >
                          {{ whichIcon.cap }}
                        </p>
                      </v-flex>
                      <v-flex xs1 class="pl-3 pt-1">
                        <a @click="helpDialog = true">
                          <v-icon style="font-size: 28px" large>help</v-icon>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  class="pl-3 pt-6"
                  style="margin-left: -2px"
                  v-if="whichToShow == 'keno'"
                >
                  <v-flex xs8>
                    <v-layout row wrap style="font-size: 16px" class="pr-1">
                      <v-flex
                        xs3
                        class="pa-2 pt-0 pb-0"
                        style="
                          background-color: #ff0000;
                          border-radius: 3px;
                          border-bottom-right-radius: 0;
                          border-top-right-radius: 0;
                        "
                      >
                        <div style="margin-top: -2px" class="white--text pt-1">
                          NEXT DRAW &nbsp;&nbsp;
                          <span style="color: #ffff00"
                            >{{ minutes }}:{{ seconds }}</span
                          >
                        </div>
                      </v-flex>

                      <v-flex
                        xs2
                        class="pa-2 pr-0 pt-0 pb-0 pl-1"
                        style="
                          background-color: #09b517;
                          border-radius: 3px;
                          border-bottom-left-radius: 0;
                          border-top-left-radius: 0;
                        "
                      >
                        <div style="margin-top: -2px" class="pt-1 white--text">
                          <v-layout row wrap>
                            <v-flex xs7> REPEAT </v-flex>
                            <v-flex xs5 class="text-right pr-1">
                              <v-menu
                                rounded
                                transition="scale-transition"
                                internal-activator
                              >
                                <template v-slot:activator="{ attrs, on }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    style="
                                      margin-top: -3px;
                                      font-size: 16px;
                                      border-radius: 2px;
                                    "
                                    x-small
                                    text
                                    class="white pa-0 black--text"
                                  >
                                    <span style="margin-right: -4px">{{
                                      selectedrepeat
                                    }}</span>
                                    <v-icon
                                      class="black--text"
                                      style="margin-right: -4px"
                                      >expand_more</v-icon
                                    ></v-btn
                                  >
                                </template>

                                <v-list class="pl-1">
                                  <v-list-item-group v-model="group4">
                                    <span v-for="item in 9" :key="item">
                                      <a
                                        class="black--text"
                                        @click="selectedrepeat = item"
                                      >
                                        <v-list-item-title>
                                          <span>
                                            {{ item }}
                                          </span>
                                        </v-list-item-title>
                                      </a>
                                    </span>
                                  </v-list-item-group>
                                </v-list>
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  class="pl-0 pt-6"
                  style="margin-left: 0px"
                  v-if="whichToShow == 'spin'"
                >
                  <v-flex xs8>
                    <v-layout row wrap style="font-size: 16px" class="pr-1">
                      <v-flex
                        xs3
                        class="pa-2 pt-0 pb-0"
                        style="
                          background-color: #ff0000;
                          border-radius: 3px;
                          border-bottom-right-radius: 0;
                          border-top-right-radius: 0;
                        "
                      >
                        <div style="margin-top: -2px" class="white--text pt-1">
                          NEXT DRAW &nbsp;&nbsp;
                          <span style="color: #ffff00"
                            >{{ spinMinutes }}:{{ spinSeconds }}</span
                          >
                        </div>
                      </v-flex>

                      <v-flex
                        xs2
                        class="pa-2 pr-0 pt-0 pb-0 pl-1"
                        style="
                          background-color: #09b517;
                          border-radius: 3px;
                          border-bottom-left-radius: 0;
                          border-top-left-radius: 0;
                        "
                      >
                        <div style="margin-top: -2px" class="pt-1 white--text">
                          <v-layout row wrap>
                            <v-flex xs7> REPEAT </v-flex>
                            <v-flex xs5 class="text-right pr-1">
                              <v-menu
                                rounded
                                transition="scale-transition"
                                internal-activator
                              >
                                <template v-slot:activator="{ attrs, on }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    style="
                                      margin-top: -3px;
                                      font-size: 16px;
                                      border-radius: 2px;
                                    "
                                    x-small
                                    text
                                    class="white pa-0 black--text"
                                  >
                                    <span style="margin-right: -4px">{{
                                      selectedrepeat
                                    }}</span>
                                    <v-icon
                                      class="black--text"
                                      style="margin-right: -4px"
                                      >expand_more</v-icon
                                    ></v-btn
                                  >
                                </template>

                                <v-list class="pl-1">
                                  <v-list-item-group v-model="group4">
                                    <span v-for="item in 9" :key="item">
                                      <a
                                        class="black--text"
                                        @click="selectedrepeat = item"
                                      >
                                        <v-list-item-title>
                                          <span>
                                            {{ item }}
                                          </span>
                                        </v-list-item-title>
                                      </a>
                                    </span>
                                  </v-list-item-group>
                                </v-list>
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex></v-layout
                >

                <v-layout
                  row
                  wrap
                  class="pl-3 pt-0"
                  style="margin-left: -2px"
                  v-if="whichToShow == 'keno'"
                >
                  <v-flex xs12 class="mb-2"> </v-flex>

                  <v-flex xs3 class="pl-0 white--text pr-15">
                    <a>
                      <div
                        style="background-color: #008601; border-radius: 3px"
                        class="pa-1 pl-3"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -3px">
                          <v-flex
                            xs7
                            style="font-size: 16px"
                            class="white--text"
                            >QUICK PICK</v-flex
                          >
                          <v-flex xs3 class="text-left pr-1">
                            <v-menu
                              rounded
                              transition="scale-transition"
                              internal-activator
                            >
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  style="
                                    margin-top: -1px;
                                    font-size: 16px;
                                    border-radius: 2px;
                                  "
                                  x-small
                                  text
                                  class="white pa-1 black--text"
                                >
                                  <span>{{ selectedquick }}</span>
                                  <v-icon right class="black--text"
                                    >expand_more</v-icon
                                  ></v-btn
                                >
                              </template>

                              <v-list class="pl-1">
                                <v-list-item-group v-model="group4">
                                  <span v-for="item in 9" :key="item">
                                    <a
                                      class="black--text"
                                      @click="selectedquick = item"
                                    >
                                      <v-list-item-title>
                                        <span>
                                          {{ item }}
                                        </span>
                                      </v-list-item-title>
                                    </a>
                                  </span>
                                </v-list-item-group>
                              </v-list>
                            </v-menu>
                          </v-flex>
                          <v-flex xs2 class="">
                            <v-img
                              height="28px"
                              width="100"
                              contain
                              src="../assets/qu.png"
                            >
                            </v-img>
                          </v-flex>
                        </v-layout></div
                    ></a>
                  </v-flex>
                  <v-flex xs2 class="pl-0 white--text" style="">
                    <a
                      class="white--text"
                      @click="selectEvenOddAndHighLow('HEADS')"
                    >
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('HEADS') == -1"
                        style="border-radius: 3px; margin-left: -30px"
                        class="pa-1 pl-1"
                        id="btns"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -2px">
                          <v-flex xs9 style="font-size: 16px">HEADS</v-flex>
                          <v-flex xs3 class="text-center">
                            <div
                              style="
                                font-size: 16px;
                                border-radius: 3px;
                                border: 1px solid green;
                                font-weight: bold;
                              "
                              class="black--text pa-0 pt-0 pb-0 ml-2"
                              id="inbtns"
                            >
                              2
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div
                        v-else
                        style="
                          border-radius: 3px;
                          margin-left: -30px;
                          background-color: #0b6919;
                        "
                        class="pa-1 pl-1"
                        id="btns"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -2px">
                          <v-flex xs9 style="font-size: 16px">HEADS</v-flex>
                          <v-flex xs3 class="text-center">
                            <div
                              style="
                                font-size: 16px;
                                border-radius: 3px;
                                border: 0px solid green;
                                font-weight: bold;
                              "
                              class="black--text pa-0 pt-0 pb-0 ml-2"
                              id="inbtns"
                            >
                              2
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </a>
                  </v-flex>

                  <v-flex xs2 class="pl-2 white--text" style="">
                    <a
                      class="white--text"
                      @click="selectEvenOddAndHighLow('EVENS')"
                    >
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('EVENS') == -1"
                        style="
                          border-radius: 3px;
                          margin-right: -14px;
                          margin-left: -3px;
                        "
                        class="pa-1 pl-1"
                        id="ebtns"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -2px">
                          <v-flex xs9 style="font-size: 16px">EVENS</v-flex>
                          <v-flex xs3 class="text-center">
                            <div
                              style="
                                font-size: 16px;
                                border-radius: 3px;
                                border: 1px solid green;
                                font-weight: bold;
                              "
                              class="black--text pa-0 pt-0 pb-0 ml-2"
                              id="inbtns"
                            >
                              4
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>

                      <div
                        v-else
                        style="
                          border-radius: 3px;
                          margin-right: -14px;
                          margin-left: -3px;
                          background-color: #0b6919;
                        "
                        class="pa-1 pl-1"
                        id="ebtns"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -2px">
                          <v-flex xs9 style="font-size: 16px">EVENS</v-flex>
                          <v-flex xs3 class="text-center">
                            <div
                              style="
                                font-size: 16px;
                                border-radius: 3px;
                                border: 0px solid green;
                                font-weight: bold;
                              "
                              class="black--text pa-0 pt-0 pb-0 ml-2"
                              id="inbtns"
                            >
                              4
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </a>
                  </v-flex>
                  <v-flex xs2 class="pl-5 white--text" style="">
                    <a
                      class="white--text"
                      @click="selectEvenOddAndHighLow('TAILS')"
                    >
                      <div
                        v-if="selectedEvenOddAndHighLow.indexOf('TAILS') == -1"
                        style="border-radius: 3px; margin-right: -42px"
                        class="pa-1 pl-1"
                        id="tbtns"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -2px">
                          <v-flex xs9 style="font-size: 16px">TAILS</v-flex>
                          <v-flex xs3 class="text-center">
                            <div
                              style="
                                font-size: 16px;
                                border-radius: 3px;
                                border: 1px solid green;
                                font-weight: bold;
                              "
                              class="black--text pa-0 pt-0 pb-0 ml-2"
                              id="inbtns"
                            >
                              2
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>

                      <div
                        v-else
                        style="
                          border-radius: 3px;
                          margin-right: -42px;
                          background-color: #0b6919;
                        "
                        class="pa-1 pl-1"
                        id="tbtns"
                      >
                        <v-layout row wrap class="" style="margin-bottom: -2px">
                          <v-flex xs9 style="font-size: 16px">TAILS</v-flex>
                          <v-flex xs3 class="text-center">
                            <div
                              style="
                                font-size: 16px;
                                border-radius: 3px;
                                border: 0px solid green;
                                font-weight: bold;
                              "
                              class="black--text pa-0 pt-0 pb-0 ml-2"
                              id="inbtns"
                            >
                              2
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </a>
                  </v-flex>
                  <v-flex xs3 class="pl-16 pt-1">
                    <v-btn
                      small
                      text
                      class="pa-0"
                      @click="
                        selectedNumbers = [];
                        selectedEvenOddAndHighLow = [];
                        evenOddInputMoney = [];
                        selectedNumberOptionCounter = 0;
                        numberInputMoney = [];
                      "
                    >
                      <v-img contain src="../assets/re.jpg"> </v-img>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-layout row wrap v-if="whichToShow == 'keno'">
                  <v-flex xs9>
                    <v-layout
                      row
                      wrap
                      class="text-center pl-2 pr-2 pt-3"
                      justify-space-around
                    >
                      <v-flex
                        xs12
                        v-if="partnerBalance.tempo_package <= 1000"
                        class="pa-1 red--text"
                      >
                        <span style="font-size: 16px"
                          >Before the system shuts down, please let your manager
                          know that your package is low.
                        </span>
                      </v-flex>
                      <v-flex
                        xs1
                        v-for="number in 40"
                        :key="number"
                        class="ma-1 mt-0 mb-1"
                      >
                        <div
                          class="pt-0 pb-0"
                          id="selectedNum"
                          style="margin-top: -2px"
                          v-if="selectedNumbers.length > 0"
                        >
                          <a @click="selectNumber(number)">
                            <div
                              class="circle"
                              v-if="
                                selectedNumbers[
                                  selectedNumbers.length - 1
                                ].indexOf(number) == -1
                              "
                            >
                              <span class="pa-1">{{ number }}</span>
                            </div>

                            <div
                              class="circle1"
                              v-if="
                                selectedNumbers[
                                  selectedNumbers.length - 1
                                ].indexOf(number) != -1
                              "
                            >
                              <span class="pa-1">{{ number }}</span>
                            </div>
                          </a>
                        </div>

                        <div
                          class="pt-0 pb-0"
                          id="selectedNum"
                          style="margin-top: -2px"
                          v-else
                        >
                          <a @click="selectNumber(number)">
                            <div class="circle">
                              <span class="pa-1">{{ number }}</span>
                            </div>
                          </a>
                        </div>
                      </v-flex>
                      <v-flex xs12 style="margin-top: -5px">
                        <v-img height="14" contain src="../assets/hl.png">
                        </v-img>
                      </v-flex>
                      <v-flex
                        xs1
                        v-for="number in 40"
                        :key="number + 40"
                        class="ma-1 mt-0 mb-1"
                      >
                        <div
                          class="pt-0 pb-0"
                          id="selectedNum"
                          style="margin-top: -1px"
                          v-if="selectedNumbers.length > 0"
                        >
                          <a @click="selectNumber(number + 40)">
                            <div
                              class="circle2"
                              v-if="
                                selectedNumbers[
                                  selectedNumbers.length - 1
                                ].indexOf(number + 40) == -1
                              "
                            >
                              <span class="pa-1">{{ number + 40 }}</span>
                            </div>
                            <div
                              class="circle21"
                              v-if="
                                selectedNumbers[
                                  selectedNumbers.length - 1
                                ].indexOf(number + 40) != -1
                              "
                            >
                              <span class="pa-1">{{ number + 40 }}</span>
                            </div>
                          </a>
                        </div>

                        <div
                          class="pt-0 pb-0"
                          id="selectedNum"
                          style="margin-top: -1px"
                          v-else
                        >
                          <a @click="selectNumber(number + 40)">
                            <div class="circle2">
                              <span class="pa-1">{{ number + 40 }}</span>
                            </div>
                          </a>
                        </div>
                      </v-flex>

                      <!-- <v-flex xs6 class="mt-2">
                      <v-layout row wrap class="">
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Even') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Even')">
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Even') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Even')">
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Odd') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Odd')">
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Odd') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Odd')">
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('High') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('High')">
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('High') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('High')">
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Low') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Low')">
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Low') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Low')">
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs3
                    class="pt-4"
                    style="margin-left: -10px"
                    v-if="selectedNumbers.length == 0"
                  >
                    <v-img contain src="../assets/pick10.png"></v-img>
                  </v-flex>

                  <v-flex
                    xs3
                    class="pt-6 text-center"
                    style="margin-left: 0px"
                    v-if="selectedNumbers.length > 0 && copyMessage != 'copy'"
                  >
                    <v-btn
                      @click="
                        if (
                          selectedNumbers[selectedNumberOptionCounter].length >
                          0
                        ) {
                          selectedNumbers.push([]);
                          selectedNumberOptionCounter++;
                          kenoCurrentMousePosition =
                            kenoCurrentMousePosition + 1;
                          inWhichToAddAmount = 'number';
                          numberInputMoney[kenoCurrentMousePosition] = 10;
                          numPosition = 1009;
                        }
                      "
                      id="addBet"
                      elevation="4"
                      class="white--text pl-2 pr-2 mr-4"
                      style=""
                    >
                      ADD TO BETSLIP
                    </v-btn>
                    <div
                      v-for="odds in $store.state.odds"
                      :key="odds.choosen_length"
                    >
                      <div
                        class="mt-3"
                        v-if="
                          odds.choosen_length ==
                          selectedNumbers[kenoCurrentMousePosition].length
                        "
                      >
                        <span
                          class="pa-1 white--text"
                          style="
                            background-color: #0bae16;
                            margin-left: -18px;
                            font-size: 15px;
                          "
                        >
                          HIGHEST PAYOUTS
                          {{ parseFloat(odds.odd_value.split(",")[0]) / 10 }}
                          FROM
                          {{ selectedNumbers[kenoCurrentMousePosition].length }}
                        </span>
                        <span
                          class="pa-1 white--text"
                          style="background-color: #0bae16; font-wheight: bold"
                        >
                          <a>
                            <v-icon small class="white--text">remove</v-icon></a
                          >
                        </span>

                        <v-layout
                          row
                          wrap
                          class="text-center ml-1 mr-5 "
                          v-for="(hit, i) in odds.hits.split(',')"
                          :key="hit"
                          style="
                            background-color: #d5eed9;
                            border-radius: 3px;
                            border-radius-top-left: 0px;
                            border-radius-top-right: 0px;
                          "
                        >
                         
                          <v-flex xs6 class="pt-1 pb-1">
                            {{
                              odds.hits.split(",")[
                                odds.hits.split(",").length - i - 1
                              ]
                            }}
                          </v-flex>
                          <v-flex xs6 class="pt-1 pb-1">
                            {{
                              parseFloat(
                                odds.odd_value.split(",")[
                                  odds.hits.split(",").length - i - 1
                                ]
                              ) / 10
                            }}
                          </v-flex>
                          <v-flex xs12 v-if="i != odds.hits.split(',').length-1" >
                            <v-divider></v-divider>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout
                  row
                  wrap
                  class="white--text mt-2 pt-3 pl-8 pr-8 pb-5"
                  v-if="whichToShow == 'spin'"
                  style="margin-left: 0px; background-color: #1b401b"
                >
                  <v-flex
                    @mouseenter="changeCursor"
                    xs1
                    class="text-center pt-2 pb-2"
                    style="
                      border: 1px solid white;
                      background-color: #008000;
                      font-size: 18px;
                    "
                    @click="selectSpinNumbers('0 Green')"
                  >
                    <div v-if="selectedSpinNumbers.length > 0">
                      <div
                        v-if="selectedSpinNumbers.indexOf('0 Green') == -1"
                        class="white--text pt-1 pb-3 mt-13"
                      >
                        {{ 0 }}
                      </div>
                      <div v-else class="white--text pt-1 pb-3">
                        <div class="mt-13">{{ 0 }}</div>
                        <div
                          id="selectedSpinNumcircle"
                          style="margin-top: -77px"
                          class="ml-8"
                        ></div>
                      </div>
                    </div>
                    <div v-else class="white--text pt-1 pb-3 mt-13">
                      {{ 0 }}
                    </div>
                  </v-flex>
                  <v-flex xs10 class="">
                    <v-layout row wrap>
                      <v-flex
                        v-for="snums in spinAllNums"
                        :key="snums.num"
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers(snums.num)"
                        xs1
                      >
                        <div
                          v-if="snums.cl == 'red'"
                          id="snum"
                          class="text-center"
                          style="border: 1px solid white; font-size: 18px"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf(snums.num) == -1
                              "
                              class="white--text pt-3 pb-3"
                            >
                              {{ snums.num }}
                            </div>
                            <div v-else class="white--text pt-3 pb-3">
                              <div>{{ snums.num }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px"
                                class="ml-5"
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ snums.num }}
                          </div>
                        </div>

                        <div
                          v-else
                          id="snumb"
                          class="text-center"
                          style="border: 1px solid white; font-size: 18px"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf(snums.num) == -1
                              "
                              class="white--text pt-3 pb-3"
                            >
                              {{ snums.num }}
                            </div>
                            <div v-else class="white--text pt-3 pb-3">
                              <div>{{ snums.num }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px"
                                class="ml-5"
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ snums.num }}
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs1 class>
                    <v-layout row wrap class="pl-1 text-center">
                      <v-flex
                        @mouseenter="changeCursor"
                        xs12
                        style="
                          border: 1px solid rgb(109, 109, 109);

                          font-size: 18px;
                        "
                        class="pt-3 pb-3"
                      >
                        <span>2 to 1</span>
                      </v-flex>
                      <v-flex
                        @mouseenter="changeCursor"
                        xs12
                        style="
                          border: 1px solid rgb(109, 109, 109);
                          border-top: 0px;
                          border-bottom: 0px;

                          font-size: 18px;
                        "
                        class="pt-3 pb-3"
                      >
                        <span>2 to 1</span>
                      </v-flex>
                      <v-flex
                        @mouseenter="changeCursor"
                        xs12
                        style="
                          border: 1px solid rgb(109, 109, 109);

                          font-size: 18px;
                        "
                        class="pt-3 pb-3"
                      >
                        <span>2 to 1</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex
                    xs1
                    class="text-center pt-2 pb-2 mt-4"
                    style="font-size: 18px"
                  >
                  </v-flex>
                  <v-flex xs10 class="mt-4">
                    <v-layout row wrap>
                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('1st Donzen')"
                        xs4
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('1st Donzen') == -1
                              "
                              class="white--text pt-3 pb-3"
                            >
                              {{ "1st 12" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "1st 12" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 80px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "1st 12" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('2nd Donzen')"
                        xs4
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('2nd Donzen') == -1
                              "
                              class="white--text pt-3 pb-3"
                            >
                              {{ "2nd 12" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "2nd 12" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px"
                                class="ml-5"
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "2nd 12" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('3rd Donzen')"
                        xs4
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('3rd Donzen') == -1
                              "
                              class="white--text pt-3 pb-3"
                            >
                              {{ "3rd 12" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "3rd 12" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px"
                                class="ml-5"
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "3rd 12" }}
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs1
                    class="text-center pt-2 pb-2 mt-4"
                    style="font-size: 18px"
                  >
                  </v-flex>

                  <v-flex
                    xs1
                    class="text-center pt-2 pb-2 mt-4"
                    style="font-size: 18px"
                  >
                  </v-flex>
                  <v-flex xs10 class="mt-4">
                    <v-layout row wrap>
                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Low')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.indexOf('Low') == -1"
                              class="white--text pt-3 pb-3"
                            >
                              {{ "1-18" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "1-18" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "1-18" }}
                          </div>
                        </div>
                      </v-flex>
                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Even')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.indexOf('Even') == -1"
                              class="white--text pt-3 pb-3"
                            >
                              {{ "Even" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "Even" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "Even" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Red')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            font-size: 18px;
                            background-color: #ff0000;
                            border: 1px solid rgb(109, 109, 109);
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.indexOf('Red') == -1"
                              class="white--text pt-3 pb-3"
                            >
                              {{ "Red" }}
                            </div>
                            <div v-else class="white--text pt-3 pb-3">
                              <div>{{ "Red" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "Red" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Black')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            font-size: 18px;
                            background-color: black;
                            border: 1px solid black;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.indexOf('Black') == -1"
                              class="white--text pt-3 pb-3"
                            >
                              {{ "Black" }}
                            </div>
                            <div v-else class="white--text pt-3 pb-3">
                              <div>{{ "Black" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "Black" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Odd')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.indexOf('Odd') == -1"
                              class="white--text pt-3 pb-3"
                            >
                              {{ "Odd" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "Odd" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "Odd" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('High')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            border: 1px solid rgb(109, 109, 109);
                            font-size: 18px;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.indexOf('High') == -1"
                              class="white--text pt-3 pb-3"
                            >
                              {{ "19-36" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-3 pb-3"
                              style="background-color: #257832"
                            >
                              <div>{{ "19-36" }}</div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-3 pb-3">
                            {{ "19-36" }}
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs1
                    class="text-center pt-2 pb-2 mt-4"
                    style="font-size: 18px"
                  >
                  </v-flex>

                  <v-flex
                    xs1
                    class="text-center pt-2 pb-2 mt-4"
                    style="font-size: 13px"
                  >
                  </v-flex>
                  <v-flex xs10 class="mt-4">
                    <v-layout row wrap>
                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Sector A')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="font-size: 13px; background-color: #ffa631"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('Sector A') == -1
                              "
                              class="white--text pt-4 pb-4"
                            >
                              {{ "32/15/19/4/21/2" }}
                            </div>
                            <div v-else class="white--text pt-4 pb-4">
                              <div style="">
                                {{ "32/15/19/4/21/2" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-4 pb-4">
                            {{ "32/15/19/4/21/2" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Sector B')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="font-size: 13px; background-color: #163fff"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('Sector B') == -1
                              "
                              class="white--text pt-4 pb-4"
                            >
                              {{ "25/17/34/6/27/13" }}
                            </div>
                            <div v-else class="white--text pt-4 pb-4">
                              <div style="">
                                {{ "25/17/34/6/27/13" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-4 pb-4">
                            {{ "25/17/34/6/27/13" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Sector C')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="font-size: 13px; background-color: #ff5aff"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('Sector C') == -1
                              "
                              class="white--text pt-4 pb-4"
                            >
                              {{ "36/11/30/8/23/10" }}
                            </div>
                            <div v-else class="white--text pt-4 pb-4">
                              <div style="">
                                {{ "36/11/30/8/23/10" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-4 pb-4">
                            {{ "36/11/30/8/23/10" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Sector D')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="font-size: 13px; background-color: #00cf9e"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('Sector D') == -1
                              "
                              class="white--text pt-4 pb-4"
                            >
                              {{ "5/24/16/33/1/20" }}
                            </div>
                            <div v-else class="white--text pt-4 pb-4">
                              <div style="">
                                {{ "5/24/16/33/1/20" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-4 pb-4">
                            {{ "5/24/16/33/1/20" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Sector E')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="font-size: 13px; background-color: #fff300"
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('Sector E') == -1
                              "
                              class="white--text pt-4 pb-4"
                            >
                              {{ "14/31/9/22/18/29" }}
                            </div>
                            <div v-else class="white--text pt-4 pb-4">
                              <div style="">
                                {{ "14/31/9/22/18/29" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="white--text pt-4 pb-4">
                            {{ "14/31/9/22/18/29" }}
                          </div>
                        </div>
                      </v-flex>

                      <v-flex
                        @mouseenter="changeCursor"
                        @click="selectSpinNumbers('Sector F')"
                        xs2
                      >
                        <div
                          class="text-center"
                          style="
                            font-size: 13px;
                            background-color: #ffffff;
                            color: grey;
                          "
                        >
                          <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="
                                selectedSpinNumbers.indexOf('Sector F') == -1
                              "
                              class="grey--text pt-4 pb-4"
                            >
                              {{ "7/28/12/35/3/26" }}
                            </div>
                            <div
                              v-else
                              class="white--text pt-4 pb-4"
                              style="background-color: #808080; color: white"
                            >
                              <div style="">
                                {{ "7/28/12/35/3/26" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px; margin-left: 40px"
                                class=""
                              ></div>
                            </div>
                          </div>
                          <div v-else class="grey--text pt-4 pb-4">
                            {{ "7/28/12/35/3/26" }}
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs1
                    class="text-center pt-2 pb-2 mt-4"
                    style="font-size: 18px"
                  >
                  </v-flex>
                </v-layout>

                <v-layout
                  row
                  wrap
                  class="white--text pb-3 mb-5"
                  v-if="whichToShow == 'spin'"
                  style="
                    margin-left: 0px;
                    background-color: #1b401b;
                    font-size: 16px;
                  "
                >
                  <v-flex xs12 class="pl-2 mt-2 pb-1">
                    <span>NEIGHBORS</span>
                  </v-flex>
                  <v-flex xs12 class="pl-3">
                    <horizontal-scroll
                      class="horizontal-scroll"
                      style="border-radius: 0px; font-size: 18px"
                    >
                      <div class="outer">
                        <div
                          @mouseenter="changeCursor"
                          @click="chooseNeighbor('0')"
                          class="inner-content pr-4 pl-4 pt-3 pb-4 text-center"
                          style="
                            border: 1px solid white;
                            background-color: green;
                          "
                        >
                          <!-- <div v-if="selectedSpinNumbers.length > 0">
                            <div
                              v-if="selectedSpinNumbers.includes('0 ') == true"
                            >
                              <div style="">
                                {{ "0" }}
                              </div>
                              <div
                                id="selectedSpinNumcircle"
                                style="margin-top: -17px"
                                class=""
                              ></div>
                            </div>
                            <div v-else>
                              {{ "0" }}
                            </div>
                          </div>
                          <div v-else>
                            {{ "0" }}
                          </div> -->
                          {{ "0" }}
                        </div>
                        <div
                          @mouseenter="changeCursor"
                          @click="chooseNeighbor(nighbours.num)"
                          class="inner-content"
                          v-for="nighbours in neighborsList"
                          :key="nighbours.num"
                        >
                          <div
                            v-if="nighbours.cl == 'red'"
                            class="pr-4 pl-4 pt-3 pb-4 text-center"
                            style="
                              border: 1px solid white;
                              background-color: red;
                            "
                          >
                            {{ nighbours.num }}
                          </div>

                          <div
                            v-else
                            class="pr-4 pl-4 pt-3 pb-4 text-center"
                            style="
                              border: 1px solid white;
                              background-color: black;
                            "
                          >
                            {{ nighbours.num }}
                          </div>
                        </div>
                      </div>
                    </horizontal-scroll>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs3 class="pl-2" v-if="whichToShow == 'keno'">
                <div
                  class="ml-0 pb-4 text-center pt-4"
                  elevation="4"
                  style="
                    box-shadow: 0px 6px 7px 3px rgb(245, 241, 241);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-radius: 5px;
                  "
                >
                  <p
                    style="font-size: 17px; opacity: 1; margin-top: -9px"
                    class="text-center green--text text-darken-3"
                  >
                    Betslip
                  </p>
                  <v-divider
                    class="grey--text"
                    style="margin-top: -4px; font-size: 24px"
                  ></v-divider>

                  <span style="font-size: 14px">
                    <a>
                      <span
                        class="green white--text pl-5"
                        style="border-radius: 2px; border: 2px solid green"
                      >
                        SINGLE

                        <a>
                          <span
                            class="white grey--text pr-5 pl-5 ml-5"
                            style="border-radius: 1px"
                          >
                            MULTIPLES
                          </span>
                        </a>
                      </span></a
                    >
                  </span>
                  <p
                    v-if="
                      selectedNumbers.length == 0 &&
                      selectedEvenOddAndHighLow.length == 0 &&
                      successfullyPlaced == ''
                    "
                    style="font-size: 14px"
                    class="mt-4 text-center grey--text text-lighten-2"
                  >
                    Add more bets
                  </p>
                  <p v-else></p>

                  <v-layout
                    row
                    wrap
                    style="font-size: 16px; margin-top: -7px"
                    class="pr-0"
                    v-if="
                      selectedNumbers.length > 0 ||
                      selectedEvenOddAndHighLow.length > 0
                    "
                  >
                    <v-flex
                      data-aos="zoom-in-right"
                      xs12
                      class="white--text mr-3"
                      style="
                        opacity: 0.9;
                        background-color: #969696;
                        border-radius: 3px;
                        margin-bottom: 2px;
                      "
                      v-for="(selectedNumber, i) in selectedNumbers"
                      :key="i + 100"
                    >
                      <v-layout row wrap v-if="selectedNumbers.length > 0">
                        <v-flex xs1 class="pa-1">
                          <img
                            class="kid"
                            height="20px"
                            width="20px"
                            contain
                            src="../assets/SmartPlayKenoIcon.svg"
                          />
                        </v-flex>
                        <v-flex
                          xs10
                          class="pl-1 text-left"
                          style="font-size: 13px"
                        >
                          <div style="margin-top: -3px">Win</div>
                          <div style="margin-top: -4px">
                            {{ selectedNumber.toString() }}&nbsp;
                            <span
                              v-for="odds in $store.state.odds"
                              :key="odds.choosen_length"
                            >
                              <span
                                class="pa-1 pt-0 pb-0"
                                style="background-color: #257832"
                                v-if="
                                  odds.choosen_length == selectedNumber.length
                                "
                              >
                                {{
                                  parseFloat(odds.odd_value.split(",")[0]) / 10
                                }}
                              </span>
                            </span>
                          </div>
                          <div style="margin-top: -6px; font-weight: bold">
                            {{
                              new Date(Number(currentGame[0].game_date))
                                | formatDate
                            }}
                            <span style="font-weight: normal"
                              >ID{{ currentGame[0].game_number }}</span
                            >
                          </div>
                          <v-layout row wrap>
                            <v-flex
                              class="text-center"
                              xs1
                              style="background-color: #bcbcbc"
                            >
                              <a
                                @click="
                                  if (numberInputMoney[i] > 10) {
                                    numberInputMoney[i] =
                                      parseFloat(numberInputMoney[i]) - 10;
                                  }
                                "
                                ><v-icon
                                  style="font-weight: bolder; font-size: 20px"
                                  small
                                  class="white--text"
                                  >horizontal_rule</v-icon
                                ></a
                              >
                            </v-flex>
                            <v-flex xs9>
                              <input
                                @click="
                                  kenoCurrentMousePosition = i;
                                  inWhichToAddAmount = 'number';
                                  numPosition = i;
                                  snumPosition = 1009;
                                "
                                size="21"
                                v-model="numberInputMoney[i]"
                                style="
                                  background-color: white;
                                  height: 25px;
                                  text-align: right;
                                  padding-right: 15px;
                                  font-size: 16px;
                                "
                                id="fname"
                                name="fname"
                                autocomplete="off"
                              />
                            </v-flex>
                            <v-flex
                              class="text-center"
                              xs1
                              style="background-color: #bcbcbc"
                            >
                              <a
                                @click="
                                  if (numberInputMoney[i] < 2000) {
                                    numberInputMoney[i] =
                                      parseFloat(numberInputMoney[i]) + 10;
                                  }
                                "
                                ><v-icon
                                  style="font-weight: bold; font-size: 20px"
                                  small
                                  class="white--text"
                                  >add</v-icon
                                ></a
                              >
                            </v-flex>

                            <v-flex xs12 class="text-right pr-3">
                              <div v-if="selectedNumber.length == 0">
                                <br />
                              </div>

                              <div
                                style="margin-top: -1px; font-weight: normal"
                                v-for="odds in $store.state.odds"
                                :key="odds.choosen_length"
                                class="pr-3"
                              >
                                <span
                                  v-if="
                                    odds.choosen_length == selectedNumber.length
                                  "
                                >
                                  To Win:
                                  <span style="font-weight: bold"
                                    >Br
                                    {{
                                      (
                                        (parseFloat(
                                          odds.odd_value.split(",")[0]
                                        ) /
                                          10) *
                                        parseFloat(numberInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                </span>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs1 class="pa-1 pr-1">
                          <h1
                            style="
                              margin-top: -9px;
                              margin-left: -6px;
                              font-weight: bold;
                            "
                          >
                            <a
                              @click="removeSelectedNumbers(i)"
                              class=""
                              style="color: #636363"
                              ><img
                                id="removeT"
                                contain
                                src="../assets/x.jpg"
                                alt=""
                            /></a>
                          </h1>
                        </v-flex>
                        <v-flex
                          xs12
                          class="pt-1 text-left pl-2"
                          v-if="
                            numPosition == i &&
                            (selectedNumbers.length > 1 ||
                              selectedEvenOddAndHighLow.length > 0)
                          "
                        >
                          <v-btn
                            @click="
                              if (numberInputMoney[i] < 2000) {
                                if (numberInputMoney[i] == '10') {
                                  numberInputMoney[i] = 20;
                                } else
                                  numberInputMoney[i] =
                                    parseFloat(numberInputMoney[i]) + 10;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #c9580f;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 10 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (numberInputMoney[i] < 2000) {
                                if (numberInputMoney[i] == '10') {
                                  numberInputMoney[i] = 20;
                                } else
                                  numberInputMoney[i] =
                                    parseFloat(numberInputMoney[i]) + 20;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #c93362;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 20 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (numberInputMoney[i] < 2000) {
                                if (numberInputMoney[i] == '10') {
                                  numberInputMoney[i] = 50;
                                } else
                                  numberInputMoney[i] =
                                    parseFloat(numberInputMoney[i]) + 50;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #8830ad;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 50 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>

                          <v-btn
                            @click="
                              if (numberInputMoney[i] < 2000) {
                                if (numberInputMoney[i] == '10') {
                                  numberInputMoney[i] = 100;
                                } else
                                  numberInputMoney[i] =
                                    parseFloat(numberInputMoney[i]) + 100;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                            style="
                              background-color: #5a95f0;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 100 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (numberInputMoney[i] < 2000) {
                                if (numberInputMoney[i] == '10') {
                                  numberInputMoney[i] = 150;
                                } else
                                  numberInputMoney[i] =
                                    parseFloat(numberInputMoney[i]) + 150;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                            style="
                              background-color: #688a37;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 150 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      data-aos="zoom-in-right"
                      xs12
                      class="white--text mr-3"
                      style="
                        opacity: 0.9;
                        background-color: #969696;
                        border-radius: 3px;
                        margin-bottom: 2px;
                      "
                      v-for="(evenOdd, i) in selectedEvenOddAndHighLow"
                      :key="i"
                    >
                      <v-layout
                        row
                        wrap
                        v-if="selectedEvenOddAndHighLow.length > 0"
                      >
                        <v-flex xs1 class="pa-1">
                          <img
                            class="kid"
                            height="20px"
                            width="20px"
                            contain
                            src="../assets/SmartPlayKenoIcon.svg"
                          />
                        </v-flex>
                        <v-flex
                          xs10
                          class="pl-1 text-left"
                          style="font-size: 13px"
                        >
                          <div style="margin-top: -3px">Heads and Tails</div>
                          <div style="margin-top: -4px">
                            {{ evenOdd }}&nbsp;
                            <span
                              v-for="odds in $store.state.odds"
                              :key="odds.choosen_length"
                            >
                              <span
                                class="pa-1 pt-0 pb-0"
                                style="background-color: #257832"
                                v-if="odds.choosen_length == evenOdd"
                              >
                                {{ parseFloat(odds.odd_value.split(",")[0]) }}
                              </span>
                            </span>
                          </div>
                          <div style="margin-top: -6px; font-weight: bold">
                            {{
                              new Date(Number(currentGame[0].game_date))
                                | formatDate
                            }}
                            <span style="font-weight: normal"
                              >ID{{ currentGame[0].game_number }}</span
                            >
                          </div>
                          <v-layout row wrap>
                            <v-flex
                              class="text-center"
                              xs1
                              style="background-color: #bcbcbc"
                            >
                              <a
                                @click="
                                  if (evenOddInputMoney[i] > 10) {
                                    evenOddInputMoney[i] =
                                      parseFloat(evenOddInputMoney[i]) - 10;
                                  }
                                "
                                ><v-icon
                                  style="font-weight: bolder; font-size: 20px"
                                  small
                                  class="white--text"
                                  >horizontal_rule</v-icon
                                ></a
                              >
                            </v-flex>
                            <v-flex xs9>
                              <input
                                @click="
                                  kenoEvenOddCurrentMousePosition = i;
                                  inWhichToAddAmount = 'evenodd';
                                  snumPosition = i;
                                  numPosition = 1009;
                                "
                                size="21"
                                v-model="evenOddInputMoney[i]"
                                style="
                                  background-color: white;
                                  height: 25px;
                                  text-align: right;
                                  padding-right: 15px;
                                  font-size: 16px;
                                "
                                id="fname"
                                name="fname"
                                autocomplete="off"
                              />
                            </v-flex>
                            <v-flex
                              class="text-center"
                              xs1
                              style="background-color: #bcbcbc"
                            >
                              <a
                                @click="
                                  if (evenOddInputMoney[i] < 2000) {
                                    evenOddInputMoney[i] =
                                      parseFloat(evenOddInputMoney[i]) + 10;
                                  }
                                "
                                ><v-icon
                                  style="font-weight: bold; font-size: 20px"
                                  small
                                  class="white--text"
                                  >add</v-icon
                                ></a
                              >
                            </v-flex>

                            <v-flex xs12 class="text-right pr-3">
                              <div
                                style="margin-top: -1px; font-weight: normal"
                                v-for="odds in $store.state.odds"
                                :key="odds.choosen_length"
                                class="pr-3"
                              >
                                <span v-if="odds.choosen_length == evenOdd">
                                  To Win:
                                  <span style="font-weight: bold"
                                    >Br
                                    {{
                                      (
                                        parseFloat(
                                          odds.odd_value.split(",")[0]
                                        ) * parseFloat(evenOddInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                </span>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs1 class="pa-1 pr-1">
                          <h1
                            style="
                              margin-top: -9px;
                              margin-left: -6px;
                              font-weight: bold;
                            "
                          >
                            <a
                              @click="removeEvenOddKenoList(i)"
                              class=""
                              style="color: #636363"
                              ><img
                                id="removeT"
                                contain
                                src="../assets/x.jpg"
                                alt=""
                            /></a>
                          </h1>
                        </v-flex>
                        <v-flex
                          xs12
                          class="pt-1 text-left pl-2"
                          v-if="
                            snumPosition == i &&
                            selectedEvenOddAndHighLow.length > 0
                          "
                        >
                          <v-btn
                            @click="
                              if (evenOddInputMoney[i] < 2000) {
                                if (evenOddInputMoney[i] == '10') {
                                  evenOddInputMoney[i] = 20;
                                } else
                                  evenOddInputMoney[i] =
                                    parseFloat(evenOddInputMoney[i]) + 10;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #c9580f;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 10 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (evenOddInputMoney[i] < 2000) {
                                if (evenOddInputMoney[i] == '10') {
                                  evenOddInputMoney[i] = 20;
                                } else
                                  evenOddInputMoney[i] =
                                    parseFloat(evenOddInputMoney[i]) + 20;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #c93362;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 20 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (evenOddInputMoney[i] < 2000) {
                                if (evenOddInputMoney[i] == '10') {
                                  evenOddInputMoney[i] = 50;
                                } else
                                  evenOddInputMoney[i] =
                                    parseFloat(evenOddInputMoney[i]) + 50;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #8830ad;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 50 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>

                          <v-btn
                            @click="
                              if (evenOddInputMoney[i] < 2000) {
                                if (evenOddInputMoney[i] == '10') {
                                  evenOddInputMoney[i] = 100;
                                } else
                                  evenOddInputMoney[i] =
                                    parseFloat(evenOddInputMoney[i]) + 100;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                            style="
                              background-color: #5a95f0;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 100 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (evenOddInputMoney[i] < 2000) {
                                if (evenOddInputMoney[i] == '10') {
                                  evenOddInputMoney[i] = 150;
                                } else
                                  evenOddInputMoney[i] =
                                    parseFloat(evenOddInputMoney[i]) + 150;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-3 pl-3 text-capitalize white--text"
                            style="
                              background-color: #688a37;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 150 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 class="pt-1 text-left">
                      <v-btn
                        @click="
                          if (intest < 2000) {
                            intest = parseFloat(intest) + 10;
                            getIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-5 pl-5 text-capitalize white--text mr-1"
                        style="background-color: #c9580f; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 10 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                      <v-btn
                        @click="
                          if (intest < 2000) {
                            intest = parseFloat(intest) + 20;
                            getIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-5 pl-5 text-capitalize white--text mr-1"
                        style="background-color: #c93362; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 20 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                      <v-btn
                        @click="
                          if (intest < 2000) {
                            intest = parseFloat(intest) + 50;
                            getIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-5 pl-5 text-capitalize white--text mr-1"
                        style="background-color: #8830ad; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 50 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>

                      <v-btn
                        @click="
                          if (intest < 2000) {
                            intest = parseFloat(intest) + 100;
                            getIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                        style="background-color: #5a95f0; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 100 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                      <v-btn
                        @click="
                          if (intest < 2000) {
                            intest = parseFloat(intest) + 150;
                            getIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-3 pl-3 text-capitalize white--text"
                        style="background-color: #688a37; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 150 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                    </v-flex>

                    <v-flex
                      xs12
                      class="pa-1 pb-0 pl-7 text-left grey--text"
                      v-if="
                        selectedNumbers.length +
                          selectedEvenOddAndHighLow.length >
                        1
                      "
                    >
                      <span style="font-size: 17px">STAKE</span>
                    </v-flex>
                    <v-flex
                      v-if="
                        selectedNumbers.length +
                          selectedEvenOddAndHighLow.length >
                        1
                      "
                      class="text-right ml-7"
                      xs1
                      style="background-color: #bcbcbc"
                    >
                      <a
                        @click="
                          if (intest > 10) {
                            intest = parseFloat(intest) - 10;
                            getIcrease();
                          }
                        "
                        ><v-icon
                          style="font-weight: bolder; font-size: 20px"
                          small
                          class="white--text"
                          >horizontal_rule</v-icon
                        ></a
                      >
                    </v-flex>
                    <v-flex
                      class="red text-left"
                      xs7
                      v-if="
                        selectedNumbers.length +
                          selectedEvenOddAndHighLow.length >
                        1
                      "
                    >
                      <input
                        @change="getIcrease()"
                        size="20"
                        v-model="intest"
                        style="
                          background-color: white;
                          height: 25px;
                          text-align: right;
                          padding-right: 15px;
                          font-size: 16px;
                          border-top: 1px solid grey;
                          border-bottom: 1px solid grey;
                        "
                        id="fname"
                        name="fname"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex
                      v-if="
                        selectedNumbers.length +
                          selectedEvenOddAndHighLow.length >
                        1
                      "
                      class="text-center"
                      xs1
                      style="background-color: #bcbcbc; margin-left: -3px"
                    >
                      <a
                        @click="
                          if (intest < 2000) {
                            intest = parseFloat(intest) + 10;
                            getIcrease();
                          }
                        "
                        ><v-icon
                          style="font-weight: bold; font-size: 20px"
                          small
                          class="white--text"
                          >add</v-icon
                        ></a
                      >
                    </v-flex>

                    <v-flex
                      xs8
                      style="font-size: 17px"
                      class="pl-3 text-left mt-2"
                    >
                      <span class="grey--text">TOTAL STAKE</span>
                    </v-flex>
                    <v-flex xs4 class="text-right pr-4 mt-2">
                      <strong class="grey--text"
                        >Br {{ totalInputMoney.toFixed(2) }}</strong
                      >
                    </v-flex>

                    <v-flex xs8 style="font-size: 19px" class="pl-3 text-left">
                      <strong class="grey--text">TOTAL "TO WIN"</strong>
                    </v-flex>
                    <v-flex xs4 class="text-right pr-4">
                      <strong class="grey--text"
                        >Br {{ totalPossibleWin.toFixed(2) }}</strong
                      >
                    </v-flex>
                  </v-layout>

                  <v-layout
                    row
                    wrap
                    style="font-size: 16px"
                    class="pr-1"
                    v-if="
                      selectedNumbers.length == 0 &&
                      selectedEvenOddAndHighLow.length == 0
                    "
                  >
                    <v-flex
                      xs12
                      v-if="successfullyPlaced != ''"
                      class="pt-0 pb-3 ml-3"
                    >
                      <div
                        class="pa-1 red white--text"
                        style="border-radius: 3px"
                        v-if="isDuplicated"
                      >
                        {{
                          successfullyPlaced +
                          "#" +
                          $store.state.kenoTicketNumber
                        }}
                      </div>
                      <div
                        class="pa-1 white--text"
                        style="border-radius: 3px; background-color: #84bb46"
                        v-else
                      >
                        {{
                          successfullyPlaced +
                          "#" +
                          $store.state.kenoTicketNumber
                        }}
                      </div>
                    </v-flex>
                    <v-flex
                      xs3
                      class="pa-2 pt-3 pb-3"
                      style="opacity: 0.3; background-color: #f9847d"
                    >
                      <a class="white--text">CLEAR</a>
                    </v-flex>

                    <v-flex xs9 class="green pt-3 pb-3" style="opacity: 0.4">
                      <a class="white--text">PLACE BET 0</a>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    row
                    wrap
                    style="font-size: 16px"
                    class="pr-1 mt-2"
                    v-else
                  >
                    <v-flex
                      xs3
                      class="pa-2 pt-2 pb-2"
                      style="opacity: 1; background-color: #f9847d"
                    >
                      <a
                        @click="
                          selectedNumbers = [];
                          selectedEvenOddAndHighLow = [];
                          evenOddInputMoney = [];
                          selectedNumberOptionCounter = 0;
                          numberInputMoney = [];
                        "
                        class="white--text"
                        >CLEAR</a
                      >
                    </v-flex>

                    <v-flex
                      xs9
                      class="pt-2 pb-2"
                      style="
                        opacity: 1;
                        background-color: #0ab517;
                        border-left: 1px solid white;
                      "
                    >
                      <a class="white--text"
                        >PLACE BET
                        <v-btn
                          @click="recietOverview('')"
                          style="
                            background-color: #0ac419;
                            font-size: 17px;
                            border-radius: 2px;
                          "
                          text
                          class="white--text pa-1 ml-1 pt-0 pb-0"
                        >
                          BR {{ totalInputMoney.toFixed(2) }}
                        </v-btn></a
                      >
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>

              <v-flex xs3 class="pl-2" v-if="whichToShow == 'spin'">
                <div
                  class="ml-0 pb-4 text-center pt-4"
                  elevation="4"
                  style="
                    box-shadow: 0px 6px 7px 3px rgb(245, 241, 241);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-radius: 5px;
                  "
                >
                  <p
                    style="font-size: 17px; opacity: 1; margin-top: -9px"
                    class="text-center green--text text-darken-3"
                  >
                    Betslip
                  </p>
                  <v-divider
                    class="grey--text"
                    style="margin-top: -4px; font-size: 24px"
                  ></v-divider>

                  <span style="font-size: 14px">
                    <a>
                      <span
                        class="green white--text pl-5"
                        style="border-radius: 2px; border: 2px solid green"
                      >
                        SINGLE

                        <a>
                          <span
                            class="white grey--text pr-5 pl-5 ml-5"
                            style="border-radius: 1px"
                          >
                            MULTIPLES
                          </span>
                        </a>
                      </span></a
                    >
                  </span>
                  <p
                    v-if="
                      selectedSpinNumbers.length == 0 &&
                      successfullyPlaced == ''
                    "
                    style="font-size: 14px"
                    class="mt-4 text-center grey--text text-lighten-2"
                  >
                    Add more bets
                  </p>
                  <p v-else></p>

                  <v-layout
                    row
                    wrap
                    style="font-size: 16px; margin-top: -7px"
                    class="pr-0"
                    v-if="selectedSpinNumbers.length > 0"
                  >
                    <v-flex
                      data-aos="zoom-in-right"
                      xs12
                      class="white--text mr-3"
                      style="
                        opacity: 0.9;
                        background-color: #969696;
                        border-radius: 3px;
                        margin-bottom: 2px;
                      "
                      v-for="(selectedNumber, i) in selectedSpinNumbers"
                      :key="i + 100"
                    >
                      <v-layout row wrap v-if="selectedSpinNumbers.length > 0">
                        <v-flex xs1 class="pa-1">
                          <img
                            class="kid"
                            height="20px"
                            width="20px"
                            contain
                            src="../assets/SpinAndWinIcon.svg"
                          />
                        </v-flex>
                        <v-flex
                          xs10
                          class="pl-1 text-left"
                          style="font-size: 13px"
                        >
                          <div v-if="selectedNumber.includes('Neighbors')">
                            <div style="margin-top: -3px">Neighbors</div>
                            <div style="margin-top: -4px">
                              {{
                                selectedNumber.split("(")[1].split(")")[0]
                              }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].neighbors }}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div v-if="selectedNumber.includes('&')">
                            <div style="margin-top: -3px">
                              High/Low And Color
                            </div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].high_low_color }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div v-if="selectedNumber.includes('Donzen')">
                            <div style="margin-top: -3px">Dozens</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].dozen }}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div v-if="selectedNumber.includes('Sector')">
                            <div style="margin-top: -3px">Sector (Colour)</div>
                            <div style="margin-top: -4px">
                              <span v-if="selectedNumber == 'Sector A'"
                                >32/15/19/4/21/2</span
                              >
                              <span v-if="selectedNumber == 'Sector B'"
                                >25/17/34/6/27/13</span
                              >
                              <span v-if="selectedNumber == 'Sector C'"
                                >36/11/30/8/23/10</span
                              >
                              <span v-if="selectedNumber == 'Sector D'"
                                >5/24/16/33/1/20</span
                              >
                              <span v-if="selectedNumber == 'Sector E'"
                                >14/31/9/22/18/29</span
                              >
                              <span v-if="selectedNumber == 'Sector F'"
                                >7/28/12/35/3/26</span
                              >&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].sector }}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div v-if="selectedNumber.includes('Final')">
                            <div style="margin-top: -3px">Final</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].final }}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            v-if="
                              selectedNumber == 'Red' ||
                              selectedNumber == 'Black'
                            "
                          >
                            <div style="margin-top: -3px">Color</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].high_low }}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            v-if="
                              selectedNumber.includes('Even') ||
                              selectedNumber.includes('Odd')
                            "
                          >
                            <div style="margin-top: -3px">Odd/Even</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].high_low }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div
                            v-if="
                              selectedNumber == 'High' ||
                              selectedNumber == 'Low'
                            "
                          >
                            <div style="margin-top: -3px">High/Low</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].high_low }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div v-if="selectedNumber.includes('Green')">
                            <div style="margin-top: -3px">Win</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber.split(" Green")[0] }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].number }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div
                            v-if="
                              selectedNumber.includes(',') &&
                              selectedNumber.split(',').length == 2
                            "
                          >
                            <div style="margin-top: -3px">Win</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].number / 2 }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div v-if="selectedNumber.includes('Twins')">
                            <div style="margin-top: -3px">Twins</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].twins }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div v-if="selectedNumber.includes('|')">
                            <div style="margin-top: -3px">Mirror</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].mirror }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div v-if="!isNaN(selectedNumber)">
                            <div style="margin-top: -3px">Win</div>
                            <div style="margin-top: -4px">
                              {{ selectedNumber }}&nbsp;
                              <span>
                                <span
                                  class="pa-1 pt-0 pb-0"
                                  style="background-color: #257832"
                                >
                                  {{ $store.state.spinOdds[0].number }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div style="margin-top: -6px; font-weight: bold">
                            {{
                              new Date(Number(spinCurrentGame[0].game_date))
                                | formatDate
                            }}
                            <span style="font-weight: normal"
                              >ID{{ spinCurrentGame[0].game_number }}</span
                            >
                          </div>
                          <v-layout row wrap>
                            <v-flex
                              class="text-center"
                              xs1
                              style="background-color: #bcbcbc"
                            >
                              <a
                                @click="
                                  if (spinInputMoney[i] > 10) {
                                    spinInputMoney[i] =
                                      parseFloat(spinInputMoney[i]) - 10;
                                  }
                                "
                                ><v-icon
                                  style="font-weight: bolder; font-size: 20px"
                                  small
                                  class="white--text"
                                  >horizontal_rule</v-icon
                                ></a
                              >
                            </v-flex>
                            <v-flex xs9>
                              <input
                                @click="
                                  spinCurrentMousePosition = i;
                                  spnumPosition = i;
                                "
                                size="21"
                                v-model="spinInputMoney[i]"
                                style="
                                  background-color: white;
                                  height: 25px;
                                  text-align: right;
                                  padding-right: 15px;
                                  font-size: 16px;
                                "
                                id="fname"
                                name="fname"
                                autocomplete="off"
                              />
                            </v-flex>
                            <v-flex
                              class="text-center"
                              xs1
                              style="background-color: #bcbcbc"
                            >
                              <a
                                @click="
                                  if (spinInputMoney[i] < 2000) {
                                    spinInputMoney[i] =
                                      parseFloat(spinInputMoney[i]) + 10;
                                  }
                                "
                                ><v-icon
                                  style="font-weight: bold; font-size: 20px"
                                  small
                                  class="white--text"
                                  >add</v-icon
                                ></a
                              >
                            </v-flex>

                            <v-flex xs12 class="text-right pr-3">
                              <div
                                style="margin-top: -1px; font-weight: normal"
                                class="pr-3"
                              >
                                <span>
                                  To Win:
                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('Neighbors')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].neighbors *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >

                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('&')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0]
                                          .high_low_color *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('Donzen')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].dozen *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('Sector')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].sector *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('Final')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].final *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >

                                  <span
                                    style="font-weight: bold"
                                    v-if="
                                      selectedNumber == 'Red' ||
                                      selectedNumber == 'Black' ||
                                      selectedNumber.includes('Even') ||
                                      selectedNumber.includes('Odd') ||
                                      selectedNumber == 'High' ||
                                      selectedNumber == 'Low'
                                    "
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].high_low *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >

                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('Green')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].number *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                  <span
                                    style="font-weight: bold"
                                    v-if="
                                      selectedNumber.includes(',') &&
                                      selectedNumber.split(',').length == 2
                                    "
                                    >Br
                                    {{
                                      (
                                        ($store.state.spinOdds[0].number / 2) *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >

                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('Twins')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].twins *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >

                                  <span
                                    style="font-weight: bold"
                                    v-if="selectedNumber.includes('|')"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].mirror *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >

                                  <span
                                    style="font-weight: bold"
                                    v-if="!isNaN(selectedNumber)"
                                    >Br
                                    {{
                                      (
                                        $store.state.spinOdds[0].number *
                                        parseFloat(spinInputMoney[i])
                                      ).toFixed(2)
                                    }}</span
                                  >
                                </span>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs1 class="pa-1 pr-1">
                          <h1
                            style="
                              margin-top: -9px;
                              margin-left: -6px;
                              font-weight: bold;
                            "
                          >
                            <a
                              @click="spinRemoveSelectedNumbers(i)"
                              class=""
                              style="color: #636363"
                              ><img
                                id="removeT"
                                contain
                                src="../assets/x.jpg"
                                alt=""
                            /></a>
                          </h1>
                        </v-flex>

                        <v-flex
                          xs12
                          class="pt-1 text-left pl-2"
                          v-if="
                            spnumPosition == i && selectedSpinNumbers.length > 1
                          "
                        >
                          <v-btn
                            @click="
                              if (spinInputMoney[i] < 2000) {
                                if (spinInputMoney[i] == '10') {
                                  spinInputMoney[i] = 20;
                                } else
                                  spinInputMoney[i] =
                                    parseFloat(spinInputMoney[i]) + 10;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #c9580f;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 10 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (spinInputMoney[i] < 2000) {
                                if (spinInputMoney[i] == '10') {
                                  spinInputMoney[i] = 20;
                                } else
                                  spinInputMoney[i] =
                                    parseFloat(spinInputMoney[i]) + 20;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #c93362;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 20 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (spinInputMoney[i] < 2000) {
                                if (spinInputMoney[i] == '10') {
                                  spinInputMoney[i] = 50;
                                } else
                                  spinInputMoney[i] =
                                    parseFloat(spinInputMoney[i]) + 50;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-4 text-capitalize white--text mr-1"
                            style="
                              background-color: #8830ad;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 50 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>

                          <v-btn
                            @click="
                              if (spinInputMoney[i] < 2000) {
                                if (spinInputMoney[i] == '10') {
                                  spinInputMoney[i] = 100;
                                } else
                                  spinInputMoney[i] =
                                    parseFloat(spinInputMoney[i]) + 100;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                            style="
                              background-color: #5a95f0;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 100 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                          <v-btn
                            @click="
                              if (spinInputMoney[i] < 2000) {
                                if (spinInputMoney[i] == '10') {
                                  spinInputMoney[i] = 150;
                                } else
                                  spinInputMoney[i] =
                                    parseFloat(spinInputMoney[i]) + 150;
                              }
                            "
                            small
                            text
                            class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                            style="
                              background-color: #688a37;
                              border-radius: 3px;
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12>
                                <div
                                  class=""
                                  style="
                                    font-size: 10px;
                                    margin-left: -40px;
                                    margin-top: 0px;
                                  "
                                >
                                  Br
                                  <br />
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="mb-1" style="font-size: 17px">
                                  {{ 150 }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 class="pt-1 text-left">
                      <v-btn
                        @click="
                          if (spinIntest < 2000) {
                            spinIntest = parseFloat(spinIntest) + 10;
                            spinGetIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-5 pl-5 text-capitalize white--text mr-1"
                        style="background-color: #c9580f; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 10 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                      <v-btn
                        @click="
                          if (spinIntest < 2000) {
                            spinIntest = parseFloat(spinIntest) + 20;
                            spinGetIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-5 pl-5 text-capitalize white--text mr-1"
                        style="background-color: #c93362; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 20 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                      <v-btn
                        @click="
                          if (spinIntest < 2000) {
                            spinIntest = parseFloat(spinIntest) + 50;
                            spinGetIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-5 pl-5 text-capitalize white--text mr-1"
                        style="background-color: #8830ad; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 50 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>

                      <v-btn
                        @click="
                          if (spinIntest < 2000) {
                            spinIntest = parseFloat(spinIntest) + 100;
                            spinGetIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-4 pl-3 text-capitalize white--text mr-1"
                        style="background-color: #5a95f0; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 100 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                      <v-btn
                        @click="
                          if (spinIntest < 2000) {
                            spinIntest = parseFloat(spinIntest) + 150;
                            spinGetIcrease();
                          }
                        "
                        small
                        text
                        class="pt-5 pb-5 pr-3 pl-3 text-capitalize white--text"
                        style="background-color: #688a37; border-radius: 3px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div
                              class=""
                              style="
                                font-size: 10px;
                                margin-left: -40px;
                                margin-top: 0px;
                              "
                            >
                              Br
                              <br />
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mb-1" style="font-size: 17px">
                              {{ 150 }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-btn>
                    </v-flex>

                    <v-flex
                      xs12
                      class="pa-1 pb-0 pl-7 text-left grey--text"
                      v-if="selectedSpinNumbers.length > 1"
                    >
                      <span style="font-size: 17px">STAKE</span>
                    </v-flex>
                    <v-flex
                      v-if="selectedSpinNumbers.length > 1"
                      class="text-right ml-7"
                      xs1
                      style="background-color: #bcbcbc"
                    >
                      <a
                        @click="
                          if (spinIntest > 10) {
                            spinIntest = parseFloat(spinIntest) - 10;
                            spinGetIcrease();
                          }
                        "
                        ><v-icon
                          style="font-weight: bolder; font-size: 20px"
                          small
                          class="white--text"
                          >horizontal_rule</v-icon
                        ></a
                      >
                    </v-flex>
                    <v-flex
                      class="red text-left"
                      xs7
                      v-if="selectedSpinNumbers.length > 1"
                    >
                      <input
                        @change="spinGetIcrease()"
                        size="20"
                        v-model="spinIntest"
                        style="
                          background-color: white;
                          height: 25px;
                          text-align: right;
                          padding-right: 15px;
                          font-size: 16px;
                          border-top: 1px solid grey;
                          border-bottom: 1px solid grey;
                        "
                        id="fname"
                        name="fname"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex
                      v-if="selectedSpinNumbers.length > 1"
                      class="text-center"
                      xs1
                      style="background-color: #bcbcbc; margin-left: -3px"
                    >
                      <a
                        @click="
                          if (spinIntest < 2000) {
                            spinIntest = parseFloat(spinIntest) + 10;
                            spinGetIcrease();
                          }
                        "
                        ><v-icon
                          style="font-weight: bold; font-size: 20px"
                          small
                          class="white--text"
                          >add</v-icon
                        ></a
                      >
                    </v-flex>

                    <v-flex
                      xs8
                      style="font-size: 17px"
                      class="pl-3 text-left mt-2"
                    >
                      <span class="grey--text">TOTAL STAKE</span>
                    </v-flex>
                    <v-flex xs4 class="text-right pr-4 mt-2">
                      <strong class="grey--text"
                        >Br {{ spinTotalInputMoney.toFixed(2) }}</strong
                      >
                    </v-flex>

                    <v-flex xs8 style="font-size: 19px" class="pl-3 text-left">
                      <strong class="grey--text">TOTAL "TO WIN"</strong>
                    </v-flex>
                    <v-flex xs4 class="text-right pr-4">
                      <strong class="grey--text"
                        >Br {{ spinTotalPossibleWin.toFixed(2) }}</strong
                      >
                    </v-flex>
                  </v-layout>

                  <v-layout
                    row
                    wrap
                    style="font-size: 16px"
                    class="pr-1"
                    v-if="selectedSpinNumbers.length == 0"
                  >
                    <v-flex
                      xs12
                      v-if="successfullyPlaced != ''"
                      class="pt-0 pb-3 ml-3"
                    >
                      <div
                        v-if="spinIsDuplicated"
                        class="pa-1 white--text red"
                        style="border-radius: 3px"
                      >
                        {{
                          successfullyPlaced +
                          "#" +
                          $store.state.spinTicketNumber
                        }}
                      </div>

                      <div
                        v-else
                        class="pa-1 white--text"
                        style="border-radius: 3px; background-color: #84bb46"
                      >
                        {{
                          successfullyPlaced +
                          "#" +
                          $store.state.spinTicketNumber
                        }}
                      </div>
                    </v-flex>
                    <v-flex
                      xs3
                      class="pa-2 pt-3 pb-3"
                      style="opacity: 0.3; background-color: #f9847d"
                    >
                      <a class="white--text">CLEAR</a>
                    </v-flex>

                    <v-flex xs9 class="green pt-3 pb-3" style="opacity: 0.4">
                      <a class="white--text">PLACE BET 0</a>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    row
                    wrap
                    style="font-size: 16px"
                    class="pr-1 mt-2"
                    v-else
                  >
                    <v-flex
                      xs3
                      class="pa-2 pt-2 pb-2"
                      style="opacity: 1; background-color: #f9847d"
                    >
                      <a
                        @click="
                          selectedSpinNumbers = [];

                          spinInputMoney = [];
                        "
                        class="white--text"
                        >CLEAR</a
                      >
                    </v-flex>

                    <v-flex
                      xs9
                      class="pt-2 pb-2"
                      style="
                        opacity: 1;
                        background-color: #0ab517;
                        border-left: 1px solid white;
                      "
                    >
                      <a class="white--text"
                        >PLACE BET
                        <v-btn
                          @click="spinRecietOverview('')"
                          style="
                            background-color: #0ac419;
                            font-size: 17px;
                            border-radius: 2px;
                          "
                          text
                          class="white--text pa-1 ml-1 pt-0 pb-0"
                        >
                          BR {{ spinTotalInputMoney.toFixed(2) }}
                        </v-btn></a
                      >
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
            <div v-if="whichToShow == 'keno'">
              <v-layout row wrap class="ml-1 mt-0">
                <!-- <v-flex xs6 class="">
                  <v-layout
                    row
                    wrap
                    class="text-center mr-3"
                    justify-space-around
                  >
                    <v-flex
                      xs12
                      v-if="partnerBalance.tempo_package <= 1000"
                      class="pa-1 red--text"
                    >
                      <span style="font-size: 14px"
                        >Before the system shuts down, please let your manager
                        know that your package is low.
                      </span>
                    </v-flex>
                    <v-flex
                      xs1
                      v-for="number in 40"
                      :key="number"
                      class="ma-1 mt-0 mb-2"
                      id="numbers"
                    >
                      <div class="pt-2 pb-2" id="selectedNum">
                        <a @click="selectNumber(number)">
                          <div class="circle pt-1">
                            <span class="pa-1">{{ number }}</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <br />
                    </v-flex>
                    <v-flex
                      xs1
                      v-for="number in 40"
                      :key="number + 40"
                      class="ma-1"
                      id="numbers"
                      k
                    >
                      <div class="pt-2 pb-2" id="selectedNum">
                        <a @click="selectNumber(number + 40)">
                          <div class="circle pt-1">
                            <span class="pa-1">{{ number + 40 }}</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>

                    <v-flex xs6 class="mt-2">
                      <v-layout row wrap class="">
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Even') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Even')">
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Even') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Even')">
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Odd') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Odd')">
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Odd') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Odd')">
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('High') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('High')">
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('High') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('High')">
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Low') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Low')">
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Low') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Low')">
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex> -->

                <!-- <v-flex xs3>
                  <v-layout
                    row
                    wrap
                    class="mr-1"
                    v-if="
                      selectedNumbers.length > 0 ||
                      selectedEvenOddAndHighLow.length > 0
                    "
                  >
               
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-2 pb-2"
                      id="selectedNumbers"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pl-8 pt-1">
                          <a
                            class="pa-1 pt-2 pb-3 white--text"
                            dark
                            @click="
                              selectedNumbers = [];
                              selectedEvenOddAndHighLow = [];
                              evenOddInputMoney = [];
                              selectedNumberOptionCounter = 0;
                              numberInputMoney = [];
                            "
                          >
                            <span class="text-capitalize">Clear</span></a
                          >
                        </v-flex>

                        <v-flex xs6 class="text-center pl-3">
                          <a class="pa-1 pt-2 pb-3" @click="recietOverview('')">
                            <v-icon large class="white--text">print</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>

                     
                    </v-flex>
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(selectedNumber, i) in selectedNumbers"
                      :key="i + 100"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs8 class="pt-2">
                          <span class="pa-1">{{
                            selectedNumber.toString()
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px"
                            v-model="numberInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            outlined
                            @click="
                              kenoCurrentMousePosition = i;
                              inWhichToAddAmount = 'number';
                            "
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs1 class="pr-3">
                          <a @click="removeSelectedNumbers(i)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      class="text-right mb-1 ml-0 pt-1 pb-1 mt-1 mb-4"
                      v-if="selectedNumbers.length > 0"
                    >
                      <a
                        class="pa-1 pt-2 pb-3"
                        style="border: 1px solid cyan; border-radius: 3px"
                        @click="
                          if (
                            selectedNumbers[selectedNumberOptionCounter]
                              .length > 0
                          ) {
                            selectedNumbers.push([]);
                            selectedNumberOptionCounter++;
                            kenoCurrentMousePosition++;
                            inWhichToAddAmount = 'number';
                          }
                        "
                      >
                        <v-icon class="white--text">add</v-icon>
                      </a>
                    </v-flex>

                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(evenOdd, i) in selectedEvenOddAndHighLow"
                      :key="i"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs8 class="pt-2">
                          <span class="pa-1">{{ evenOdd }}</span>
                        </v-flex>

                        <v-flex xs3>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px; color: black"
                            v-model="evenOddInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            @click="
                              kenoevenoddCurrentMousePosition = i;
                              inWhichToAddAmount = 'evenodd';
                            "
                            outlined
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs1 class="pr-3">
                          <a @click="removeEvenOddKenoList(evenOdd)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                  
                  </v-layout>
                </v-flex> -->

                <!-- <v-flex xs3>
                  <v-layout row wrap class="text-center mr-1">
                    <v-flex xs6 class="mt-0 pb-1">
                      <v-btn
                        :loading="gameLoading"
                        @click="getLastTwentyGames('keno')"
                        outlined
                        small
                        dark
                        text
                        class="white--text text-capitalize"
                      >
                        <span>Print 20 keno games</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="mt-0 pb-1">
                      <span class="red--text" style="font-size: 14px"
                        >{{ day_block }} Days left</span
                      >
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <a @click="applyToAll">
                        <v-card
                          dark
                          class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="applyToAll"
                        >
                          <span>Apply to All</span>
                        </v-card>
                      </a>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <div class="ma-1 mt-0">
                        <v-text-field
                          v-model="selectedCustomAmount"
                          class="white"
                          placeholder="Birr"
                          :rules="rules"
                          hide-details="true"
                          single-line
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex
                      dark
                      xs4
                      v-for="amount in customAmount"
                      :key="amount + 600"
                    >
                      <a @click="assignSelectedAmount(amount)">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="numbers"
                        >
                          <span>{{ amount }}</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex dark xs4>
                      <a @click="clearAll">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="clearBtn"
                        >
                          <span>Clear</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12>
                      <v-card class="ma-1 pa-1 pt-0">
                        <div id="ticketListVertically">
                          <h4
                            v-if="isDuplicated"
                            class="red--text"
                            style="transform: scaleX(-1)"
                          >
                            Ticket Duplicated
                          </h4>
                          <v-form
                            @submit.prevent=""
                            style="transform: scaleX(-1)"
                          >
                            <v-card-text style="font-size: 11px">
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-for="(lastGame, i) in lastTwoGames"
                                  :key="i"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(ticket, j) in lastGame.getTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{ lastGame.game_number }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />
                                      <span> {{ ticket.others }} </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="printAgain(ticket)"
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>

                                        <v-btn
                                          v-if="
                                            lastGame.game_status == 'pending'
                                          "
                                          text
                                          small
                                          class="pink--text"
                                          @click="
                                            cancelDuplicatedTicket(
                                              ticket,
                                              lastGame.getTickets,
                                              j
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Cancel
                                          </span>
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout
                                    row
                                    wrap
                                    v-for="(
                                      ticket, j
                                    ) in lastGame.getLastTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                    class="mt-7"
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{
                                          ticket.getGame[0].game_number
                                        }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />
                                      <span> {{ ticket.others }} </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="
                                            getCopy(ticket, ticket.getGame[0])
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-form>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
              </v-layout>

              <v-dialog v-model="isPlaying" persistent max-width="420">
                <v-btn
                  small
                  text
                  @click="
                    whichToShow = 'spin';
                    whichIcon = gameItems[4];
                  "
                >
                  <span class="mr-2 text-capitalize white--text text--darken-1"
                    ><img
                      class="clickedicons"
                      height="25px"
                      width="40px"
                      contain
                      src="../assets/SpinAndWinIcon.svg"
                    />
                  </span>
                </v-btn>
                <!-- <p
                  class="headline white--text pink pa-5"
                  style="border-radius: 5px"
                >
                  Keno is in playing mode, please try later! <br />
                  <v-btn small text @click="whichToShow = 'spin'">
                    <span
                      class="mr-2 text-capitalize white--text text--darken-1"
                      >Switch to Spin</span
                    >
                  </v-btn>
                </p> -->
              </v-dialog>

              <v-dialog v-model="verifyDialog" persistent max-width="1500">
                <v-card class="text-left">
                  <div
                    class="pa-2 text-left white--text"
                    style="background-color: #37b34a; font-size: 18px"
                  >
                    Redeem Betslip

                    <a
                      class="text-capitalize grey--text text--lighten-1"
                      style="clear: right; float: right"
                      text
                      @click="
                        verifyDialog = false;
                        userIDToVerify = '';
                        totalWinMoneyForOneUser = 0;
                        payableList = [];
                        selectedPrint = [];
                        selectedWinMoney = 0;
                        verifyUserData = [];
                      "
                    >
                      X
                    </a>
                  </div>
                  <v-form @submit.prevent="">
                    <v-card-text class="pb-6">
                      <v-layout row wrap>
                        <v-flex xs4 style="font-size: 19px" class="pl-5">
                          <P class="green--text">Enter betslip code or scan</P>
                          <div style="padding-right: 80px">
                            <v-text-field
                              autofocus
                              v-model="userIDToVerify"
                              required
                              dense
                              outlined
                            ></v-text-field>
                          </div>

                          <v-btn
                            @click="userIDToVerify = userIDToVerify + '' + num"
                            text
                            v-for="num in 9"
                            :key="num"
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ num }} </span>
                          </v-btn>

                          <v-btn
                            @click="userIDToVerify = userIDToVerify + 'L'"
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "L" }} </span>
                          </v-btn>
                          <v-btn
                            text
                            @click="userIDToVerify = userIDToVerify + '' + 0"
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "0" }} </span>
                          </v-btn>
                          <v-btn
                            @click="
                              if (userIDToVerify != '')
                                userIDToVerify = userIDToVerify.substring(
                                  0,
                                  userIDToVerify.split('').length - 1
                                );
                            "
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-11 pr-11 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> <v-icon small>west</v-icon> </span>
                          </v-btn>

                          <br />
                          <br />

                          <v-btn
                            @click="userIDToVerify = ''"
                            style="background-color: #f0f0f0; font-size: 13px"
                            text
                            class="text-capitalize"
                          >
                            <span> clear </span>
                          </v-btn>

                          <v-btn
                            @click="verify"
                            type="submit"
                            style="
                              background-color: #37b34a;
                              font-size: 13px;
                              clear: right;
                              float: right;
                            "
                            text
                            class="text-capitalize mr-12 white--text"
                          >
                            <span> enter </span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs4 v-if="verifyLoading">
                          <div class="text-center">
                            <v-progress-circular
                              :size="70"
                              :width="2"
                              color="grey"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="" v-if="redeemErrorMessage != ''">
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-7"
                          >
                            {{ redeemErrorMessage }}
                          </div>
                        </v-flex>
                        <v-flex
                          xs8
                          class=""
                          v-if="
                            verifyUserData.length > 0 &&
                            redeemErrorMessage == ''
                          "
                        >
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-4"
                          >
                            <h3 class="green--text">Betslip</h3>

                            <v-layout row wrap>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>ID</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Date</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Time</strong>
                              </v-flex>
                              <v-flex xs12 class="pt-1 pr-10">
                                <hr />
                              </v-flex>

                              <v-flex xs4 class="pl-1 pt-1">
                                {{ verifyUserData[0].user_id }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(verifyUserData[0].ticket_date)
                                  ) | formatDateo
                                }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(verifyUserData[0].ticket_date)
                                  ) | formatDatet
                                }}
                              </v-flex>
                            </v-layout>
                            <br />
                            <h3 class="green--text">Bets</h3>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs12 class="pt-1">
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs2 class="pl-1 pt-1 grey lighten-3">
                              </v-flex>

                              <v-flex xs2 class="pl-0 pt-1 grey lighten-3">
                                <strong>BetID</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Game</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Event No</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-3 pt-1 grey lighten-3">
                                <strong>Market</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-1 pt-1 grey lighten-3">
                                <strong>Selection</strong>
                              </v-flex>
                              <v-flex
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-3"
                              >
                                <strong>Win</strong>
                              </v-flex>

                              <v-flex xs12 class="">
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout
                              row
                              wrap
                              class="pr-10"
                              v-for="(wonList, i) in payableList"
                              :key="i"
                            >
                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5 pb-1"
                                v-if="wonList.includes(' Loss ')"
                              >
                                <a
                                  class="ml-1 mr-3 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >visibility</v-icon
                                  >
                                </a>

                                <a
                                  @click="
                                    payWinnerAward(payableList[i], 'single')
                                  "
                                  class="ml-1 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >print</v-icon
                                  >
                                </a>
                              </v-flex>

                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-1 pt-1 pb-1"
                                v-else
                              >
                                <a
                                  class="ml-1 mr-3 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >visibility</v-icon
                                  >
                                </a>

                                <a
                                  @click="
                                    payWinnerAward(payableList[i], 'single')
                                  "
                                  class="ml-1 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >print</v-icon
                                  >
                                </a>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-0 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                {{ verifyUserData[0].user_id + (i + 1) }}
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-0 pt-1 white--text"
                                v-else
                              >
                                {{ verifyUserData[0].user_id + (i + 1) }}
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                Keno
                              </v-flex>
                              <v-flex
                                xs1
                                style="background-color: #d0b15e"
                                class="pl-0 pt-1 white--text"
                                v-else
                              >
                                Keno
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                {{ verifyUserData[0].getGame[0].game_number }}
                              </v-flex>
                              <v-flex
                                xs1
                                style="background-color: #d0b15e"
                                class="pl-0 pt-1 white--text"
                                v-else
                              >
                                {{ verifyUserData[0].getGame[0].game_number }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-3 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                <span
                                  v-if="
                                    payableList[i].split('*')[0] == 'HEADS' ||
                                    payableList[i].split('*')[0] == 'EVENS' ||
                                    payableList[i].split('*')[0] == 'TAILS'
                                  "
                                  >Heads and Tails</span
                                >
                                <span v-else>Win</span>
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-3 pt-1 white--text"
                                v-else
                              >
                                <span
                                  v-if="
                                    payableList[i].split('*')[0] == 'HEADS' ||
                                    payableList[i].split('*')[0] == 'EVENS' ||
                                    payableList[i].split('*')[0] == 'TAILS'
                                  "
                                  >Heads and Tails</span
                                >
                                <span v-else>Win</span>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                {{ payableList[i].split("*")[0] }}
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-1 pt-1 white--text"
                                v-else
                              >
                                {{ payableList[i].split("*")[0] }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                <span
                                  class=""
                                  v-if="wonList.includes(' Loss ')"
                                >
                                  Br {{ "0.00" }}
                                </span>

                                <span v-else>
                                  Br
                                  {{
                                    payableList[i]
                                      .split("= ")[1]
                                      .split(" Birr")[0]
                                  }}
                                </span>
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-1 pt-1 pr-7 white--text text-right"
                                v-else
                              >
                                <span
                                  class=""
                                  v-if="wonList.includes(' Loss ')"
                                >
                                  Br {{ "0.00" }}
                                </span>

                                <span v-else>
                                  Br
                                  {{
                                    payableList[i]
                                      .split("= ")[1]
                                      .split(" Birr")[0]
                                  }}
                                </span>
                              </v-flex>

                              <v-flex xs12 class="">
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs4 class="pl-1 pt-1 grey lighten-5 pb-1">
                                {{
                                  new Date(
                                    Number(
                                      verifyUserData[0].getGame[0].game_date
                                    )
                                  ) | formatDate
                                }}
                              </v-flex>
                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 text-center"
                              >
                                {{
                                  verifyUserData[0].getJackpotHistory.length > 0
                                    ? "Jackpot"
                                    : ""
                                }}
                              </v-flex>

                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 pr-7 text-right"
                              >
                                Br
                                {{
                                  verifyUserData[0].getJackpotHistory.length > 0
                                    ? verifyUserData[0].getJackpotHistory[0]
                                        .amount
                                    : "0.00"
                                }}
                              </v-flex>
                            </v-layout>

                            <div class="text-right mr-2 pt-12">
                              <span v-if="totalWinMoneyForOneUser > 0">
                                <strong
                                  class="mr-2"
                                  v-if="
                                    verifyUserData[0].getJackpotHistory.length >
                                    0
                                  "
                                  >Unclaimed Winnings Br
                                  {{
                                    (
                                      totalWinMoneyForOneUser +
                                      verifyUserData[0].getJackpotHistory[0]
                                        .amount
                                    ).toFixed(2)
                                  }}</strong
                                >

                                <strong class="mr-2" v-else
                                  >Unclaimed Winnings Br
                                  {{
                                    totalWinMoneyForOneUser.toFixed(2)
                                  }}</strong
                                >
                                <v-btn
                                  @click="payWinnerAward(payableList, 'pay')"
                                  class="pt-1 pb-1 mt-1 mb-1 mr-2 pr-2 pl-2"
                                  style="font-size: 13px"
                                  dark
                                  text
                                  small
                                  id="loginBtn"
                                >
                                  <span class="text-capitalize">Redeem</span>
                                  <v-img
                                    height="20px"
                                    contain
                                    src="../assets/usd.png"
                                  >
                                  </v-img>
                                </v-btn>
                              </span>

                              <span v-else>
                                <strong class="mr-2"
                                  >Not a Winning Ticket
                                </strong>
                                <v-btn
                                  @click="payWinnerAward(payableList, 'empty')"
                                  class="pt-1 pb-1 mt-1 mb-1 mr-2 pr-2 pl-2"
                                  style="font-size: 13px"
                                  dark
                                  text
                                  small
                                  id="loginBtn"
                                >
                                  <span class="text-capitalize">Redeem</span>
                                  <v-img
                                    height="20px"
                                    contain
                                    src="../assets/usd.png"
                                  >
                                  </v-img>
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <div class="mr-4">
                      <v-layout row wrap>
                        <v-flex xs11> </v-flex>

                        <v-flex xs1 class="pl-15">
                          <v-switch
                            class="text-right"
                            v-model="isRedeemPrint"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="winnersDialog"
                persistent
                max-width="420"
                v-if="2 == 4"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Check ticket </v-card-title>

                  <v-card-text>
                    <P class="green--text" v-if="totalWinMoneyForOneUser > 0">
                      <v-icon class="green--text">check</v-icon>
                      User won total
                      <strong
                        v-if="verifyUserData[0].getJackpotHistory.length > 0"
                        >{{
                          totalWinMoneyForOneUser +
                          verifyUserData[0].getJackpotHistory[0].amount
                        }}</strong
                      >
                      <strong v-else>{{ totalWinMoneyForOneUser }}</strong>
                      Birr <br /><br />
                      <span>
                        <p
                          v-if="verifyUserData[0].getJackpotHistory.length > 0"
                          class="green--text"
                        >
                          {{
                            verifyUserData[0].getJackpotHistory[0].jack_type
                          }}:
                          {{ verifyUserData[0].getJackpotHistory[0].amount }}
                          Birr Won
                        </p></span
                      >

                      <span>
                        <div v-for="(wonList, i) in payableList" :key="i">
                          <span
                            class="red--text"
                            v-if="wonList.includes(' Loss ')"
                          >
                            {{ wonList }}
                          </span>

                          <span v-else>
                            {{ wonList }}
                          </span>
                        </div></span
                      >
                    </P>
                    <P class="red--text" v-else
                      ><v-icon class="red--text">cancel</v-icon> User loss the
                      game!
                    </P>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="payLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="totalWinMoneyForOneUser > 0"
                    >
                      Pay
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="winnersDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="cancelDialog"
                persistent
                max-width="420"
                top
                v-if="1 == 2"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Cancel Ticket </v-card-title>

                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar to
                        cancel the ticket</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIDToCancel"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>

                      <P>Are you sure you want to cancel this ticket?</P>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="cancelLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="normalCancelTicket"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="cancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="cancelDialog" persistent max-width="1500">
                <v-card class="text-left">
                  <div
                    class="pa-2 text-left white--text"
                    style="background-color: #37b34a; font-size: 18px"
                  >
                    Cancel Betslip

                    <a
                      class="text-capitalize grey--text text--lighten-1"
                      style="clear: right; float: right"
                      text
                      @click="
                        cancelDialog = false;
                        ticketToDelete = [];
                        stakeToCancel = 0;
                        userIDToCancel = '';
                      "
                    >
                      X
                    </a>
                  </div>
                  <v-form @submit.prevent="">
                    <v-card-text class="pb-6">
                      <v-layout row wrap>
                        <v-flex xs4 style="font-size: 19px" class="pl-5">
                          <P class="green--text">Enter betslip code or scan</P>
                          <div style="padding-right: 80px">
                            <v-text-field
                              autofocus
                              v-model="userIDToCancel"
                              required
                              dense
                              outlined
                            ></v-text-field>
                          </div>

                          <v-btn
                            @click="userIDToCancel = userIDToCancel + '' + num"
                            text
                            v-for="num in 9"
                            :key="num"
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ num }} </span>
                          </v-btn>

                          <v-btn
                            @click="userIDToCancel = userIDToCancel + 'L'"
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "L" }} </span>
                          </v-btn>
                          <v-btn
                            text
                            @click="userIDToCancel = userIDToCancel + '' + 0"
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "0" }} </span>
                          </v-btn>
                          <v-btn
                            @click="
                              if (userIDToCancel != '')
                                userIDToCancel = userIDToCancel.substring(
                                  0,
                                  userIDToCancel.split('').length - 1
                                );
                            "
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-11 pr-11 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> <v-icon small>west</v-icon> </span>
                          </v-btn>

                          <br />
                          <br />

                          <v-btn
                            @click="userIDToCancel = ''"
                            style="background-color: #f0f0f0; font-size: 13px"
                            text
                            class="text-capitalize"
                          >
                            <span> clear </span>
                          </v-btn>

                          <v-btn
                            type="submit"
                            @click="getTicketToCancel"
                            style="
                              background-color: #37b34a;
                              font-size: 13px;
                              clear: right;
                              float: right;
                            "
                            text
                            class="text-capitalize mr-12 white--text"
                          >
                            <span> enter </span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs4 v-if="cancelLoading">
                          <div class="text-center">
                            <v-progress-circular
                              :size="70"
                              :width="2"
                              color="grey"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="" v-if="redeemErrorMessage != ''">
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-7"
                          >
                            {{ redeemErrorMessage }}
                          </div>
                        </v-flex>

                        <v-flex
                          xs8
                          class=""
                          v-if="
                            ticketToDelete.length > 0 &&
                            redeemErrorMessage == ''
                          "
                        >
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-4"
                          >
                            <h3 class="green--text">Betslip</h3>

                            <v-layout row wrap>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>ID</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Date</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Time</strong>
                              </v-flex>
                              <v-flex xs12 class="pt-1 pr-10">
                                <hr />
                              </v-flex>

                              <v-flex xs4 class="pl-1 pt-1">
                                {{ ticketToDelete[0].user_id }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(ticketToDelete[0].ticket_date)
                                  ) | formatDateo
                                }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(ticketToDelete[0].ticket_date)
                                  ) | formatDatet
                                }}
                              </v-flex>
                            </v-layout>
                            <br />
                            <h3 class="green--text">Bets</h3>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs12 class="pt-1">
                                <v-divider></v-divider>
                              </v-flex>

                              <v-flex xs4 class="pl-0 pt-1 grey lighten-3">
                                <strong>BetID</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Game</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Event No</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-3 pt-1 grey lighten-3">
                                <strong>Market</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-1 pt-1 grey lighten-3">
                                <strong>Selection</strong>
                              </v-flex>
                              <v-flex
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-3"
                              >
                                <strong>Win</strong>
                              </v-flex>

                              <v-flex xs12 class="">
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout
                              row
                              wrap
                              class="pr-10"
                              v-for="(
                                wonList, i
                              ) in ticketToDelete[0].choosen_numbers.split(':')"
                              :key="i"
                            >
                              <v-flex
                                xs4
                                class="pl-0 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                {{ ticketToDelete[0].user_id + (i + 1) }}
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                Keno
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                {{
                                  ticketToDelete[0].getGameForShifts[0]
                                    .game_number
                                }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-3 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                <span
                                  v-if="
                                    wonList.split('*')[0] == 'HEADS' ||
                                    wonList.split('*')[0] == 'EVENS' ||
                                    wonList.split('*')[0] == 'TAILS'
                                  "
                                  >Heads and Tails</span
                                >
                                <span v-else>Win</span>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                {{ wonList.split("*")[0] }}
                              </v-flex>

                              <v-flex
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-5"
                              >
                                <span class=""> Br {{ "0.00" }} </span>
                              </v-flex>

                              <v-flex
                                xs12
                                class=""
                                v-if="
                                  ticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>

                            <v-layout
                              row
                              wrap
                              class="pr-10"
                              v-for="(
                                wonList, i
                              ) in ticketToDelete[0].others.split(':')"
                              :key="i + 100"
                            >
                              <v-flex
                                xs4
                                class="pl-0 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                              >
                                {{ ticketToDelete[0].user_id + (i + 1) }}
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                              >
                                Keno
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                              >
                                {{
                                  ticketToDelete[0].getGameForShifts[0]
                                    .game_number
                                }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-3 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                              >
                                <span
                                  v-if="
                                    wonList.split('*')[0] == 'HEADS' ||
                                    wonList.split('*')[0] == 'EVENS' ||
                                    wonList.split('*')[0] == 'TAILS'
                                  "
                                  >Heads and Tails</span
                                >
                                <span v-else>Win</span>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                              >
                                {{ wonList.split("*")[0] }}
                              </v-flex>

                              <v-flex
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-5"
                              >
                                <span class=""> Br {{ "0.00" }} </span>
                              </v-flex>

                              <v-flex
                                xs12
                                class=""
                                v-if="
                                  ticketToDelete[0].others.split(':')[0] != ''
                                "
                              >
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs4 class="pl-1 pt-1 grey lighten-5 pb-1">
                                {{
                                  new Date(
                                    Number(
                                      ticketToDelete[0].getGameForShifts[0]
                                        .game_date
                                    )
                                  ) | formatDate
                                }}
                              </v-flex>
                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 text-center"
                              >
                                {{ "" }}
                              </v-flex>

                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 pr-7 text-right"
                              >
                                Br
                                {{ "0.00" }}
                              </v-flex>
                            </v-layout>

                            <div class="text-right mr-2 pt-12">
                              <span>
                                <strong class="mr-2"
                                  >Total Stake Br
                                  {{ stakeToCancel.toFixed(2) }}</strong
                                >

                                <v-btn
                                  @click="normalCancelTicket()"
                                  class="pt-1 pb-1 mt-1 mb-1 mr-2 pr-2 pl-2"
                                  style="
                                    font-size: 13px;
                                    background-color: #f0ad4e;
                                  "
                                  dark
                                  text
                                  small
                                  id="loginBtn"
                                >
                                  <span class="text-capitalize mr-2"
                                    >Cancel</span
                                  ><v-icon
                                    style="font-size: 16px; font-weight: bold"
                                    small
                                    class="white--text"
                                    >block</v-icon
                                  >
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card-text>

                    <div class="mr-4">
                      <v-layout row wrap>
                        <v-flex xs11> </v-flex>

                        <v-flex xs1 class="pl-15">
                          <v-switch
                            class="text-right"
                            v-model="isCancelPrint"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="recietDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-text
                    ref="printReceipt"
                    v-if="verifyUserData.length > 0"
                    style="font-family: Arial; margin-left: 0px; color: black"
                  >
                    <div>
                      <div
                        style="
                          font-size: 14px;
                          text-align: right;
                          font-weight: normal;
                        "
                      >
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>

                        <div style="margin-top: -1px">
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </div>

                        <div style="margin-top: -1px">
                          {{ new Date(Date.now()) | formatDate }}

                          (UTC +3)
                        </div>
                      </div>
                    </div>
                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -5px;
                        text-align: center;
                        padding-right: 5px;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Redeem Receipt</div>
                          <div style="margin-top: -2px">
                            {{ verifyUserData[0].user_id }}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -8px;
                        text-align: left;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Redeemed Amount :</div>
                        </td>
                        <td style="font-weight: bold; text-align: right">
                          <div>
                            <Strong>B</Strong>r
                            {{ selectedWinMoney.toFixed(2) }}
                          </div>
                        </td>
                      </tr>

                      <tr v-if="verifyUserData[0].getJackpotHistory.length > 0">
                        <td style="font-weight: bold">
                          <div>Jackpot Amount :</div>
                        </td>
                        <td style="font-weight: bold; text-align: right">
                          <div>
                            <Strong>B</Strong>r
                            {{
                              verifyUserData[0].getJackpotHistory[0].amount.toFixed(
                                2
                              )
                            }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold">
                          <div style="margin-top: -8px">Redeemed Time:</div>
                        </td>
                        <td style="text-align: right; font-size: 14px">
                          <div style="margin-top: -8px">
                            {{ new Date(Date.now()) | formatDates }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          <div
                            style="margin-top: -3px"
                            v-if="selectedWinMoney > 0"
                          >
                            Winning Bets
                          </div>

                          <div style="margin-top: -3px" v-else>
                            Not a Winning Ticket
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="width: 100%; font-size: 14px"
                      v-if="totalWinMoneyForOneUser > 0"
                    >
                      <span v-for="(wonList, i) in selectedPrint" :key="i">
                        <div v-if="!wonList.includes(' Loss ')">
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <div
                              style="margin-top: -6px"
                              v-if="
                                selectedPrint[i].split('*')[0] != 'HEADS' &&
                                selectedPrint[i].split('*')[0] != 'EVENS' &&
                                selectedPrint[i].split('*')[0] != 'TAILS'
                              "
                            >
                              <tr
                                v-if="
                                  odds.choosen_length ==
                                  selectedPrint[i].split('*')[0].split(',')
                                    .length
                                "
                              >
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Keno
                                  {{
                                    new Date(
                                      Number(verifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    verifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    selectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    (
                                      parseFloat(odds.odd_value.split(",")[0]) /
                                      10
                                    ).toFixed(2)
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    selectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>
                            <div v-else style="margin-top: -6px">
                              <tr
                                v-if="
                                  odds.choosen_length.toLowerCase() ==
                                  selectedPrint[i].split('*')[0].toLowerCase()
                                "
                              >
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 14px"
                                    >Heads and Tails</span
                                  >
                                  <br />
                                  Keno
                                  {{
                                    new Date(
                                      Number(verifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    verifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    selectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    parseFloat(
                                      odds.odd_value.split(",")[0]
                                    ).toFixed(2)
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    selectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>
                          </span>
                        </div>
                      </span>

                      <!-- <span
                          style="padding-top: 2px"
                          v-for="(other, i) in selectedEvenOddAndHighLow"
                          :key="i + 10001"
                        >
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <span
                              v-if="
                                odds.choosen_length.toLowerCase() ==
                                  other.toLowerCase() &&
                                selectedEvenOddAndHighLow.length ==
                                  evenOddInputMoney.length
                              "
                            >
                              <tr>
                                <td style="text-align: left; font-size: 11px">
                                  <span
                                    v-if="
                                      other == 'TAILS' ||
                                      other == 'HEADS' ||
                                      other == 'EVENS'
                                    "
                                    style="font-weight: bold; font-size: 11px"
                                    >Heads and Tails</span
                                  >

                                  <br />
                                  Keno {{ new Date(gameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : currentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ other }}&nbsp;&nbsp;{{
                                    odds.odd_value
                                  }}.00
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 11px">Br </span
                                  >{{ evenOddInputMoney[i] }}.00
                                  <span><br /></span><span><br /><br /></span>
                                </td>
                              </tr>
                            </span>
                          </span>
                        </span> -->
                    </table>
                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -4px;
                        text-align: center;
                      "
                    >
                      <tr>
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          <div>
                            {{ randomSring }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      :loading="payLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="totalWinMoneyForOneUser > 0"
                    >
                      Waiting...
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="recietDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="crecietDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-text
                    ref="cprintReceipt"
                    v-if="ticketToDelete.length > 0"
                    style="font-family: Arial; margin-left: 0px; color: black"
                  >
                    <div>
                      <div
                        style="
                          font-size: 14px;
                          text-align: right;
                          font-weight: normal;
                        "
                      >
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>

                        <div style="margin-top: -1px">
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </div>

                        <div style="margin-top: -1px">
                          {{ new Date(Date.now()) | formatDate }}

                          (UTC +3)
                        </div>
                      </div>
                    </div>
                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -5px;
                        text-align: center;
                        padding-right: 5px;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Cancel Receipt</div>
                          <div style="margin-top: -2px">
                            {{ ticketToDelete[0].user_id }}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -8px;
                        text-align: left;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Cancelled Amount :</div>
                        </td>
                        <td style="font-weight: bold; text-align: right">
                          <div>
                            <Strong>B</Strong>r
                            {{ stakeToCancel.toFixed(2) }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold">
                          <div style="margin-top: -8px">Cancelled Time:</div>
                        </td>
                        <td style="text-align: right; font-size: 14px">
                          <div style="margin-top: -8px">
                            {{ new Date(Date.now()) | formatDates }}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -4px;
                        text-align: center;
                      "
                    >
                      <tr>
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          <div>
                            {{ randomSring }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="crecietDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <div v-if="whichToShow == 'spin'">
              <!-- <v-app-bar elevation="0" app id="navBar" dark>
                <v-layout row wrap class="" style="font-size: 14px">
                  <v-flex xs2 class="" v-if="spinCurrentGame.length > 0">
                    <p>GAME# {{ spinCurrentGame[0].game_number }}</p>
                  </v-flex>
                  <v-flex xs2 class="mt-2 mr-2 pr-1 pl-1">
                    <p>
                      <v-progress-linear
                        v-model="spinTimePercent"
                        height="8"
                      ></v-progress-linear>
                    </p>
                  </v-flex>
                  <v-flex
                    class="red--text"
                    xs2
                    v-if="spinMinutes == '00' && parseInt(spinSeconds) <= 20"
                  >
                    <p>GAME CLOSES IN {{ spinMinutes }}:{{ spinSeconds }}</p>
                  </v-flex>

                  <v-flex class="" xs2 v-else>
                    <p>GAME CLOSES IN {{ spinMinutes }}:{{ spinSeconds }}</p>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="SpinVerifyDialog = true"
                    >
                      <span class="text-capitalize">Verify</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="spinCancelDialog = true"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs1 class="pl-2">
                    <v-btn text x-small @click="tmhPopUp = true">
                      <span class="text-capitalize"
                        >TMH
                       
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-btn small text @click="whichToShow = 'keno'">
                  <span class="mr-2 text-capitalize orange--text text--darken-1"
                    >Switch to Keno</span
                  >
                </v-btn>

                <v-btn x-small text @click="getUnpaid" :loading="unpaidLoading">
                  <span class="text-capitalize red--text text--darken-1"
                    >Tickets
                  </span>
                </v-btn>

                <v-btn small text @click="spinReportTicketDialog = true">
                  <span class="mr-2 text-capitalize">Report spin</span>
                </v-btn>
                <v-btn small text @click="logoutClicked">
                  <span class="mr-2 text-capitalize">Logout</span>
                </v-btn>
                <a @click="helpDialog = true">Help</a>
              </v-app-bar> -->

              <!-- <v-layout row wrap class="ml-1 mt-0">
                <v-flex xs6 class="">
                  <v-layout row wrap class="text-center">
                    <v-flex
                      xs12
                      v-if="partnerBalance.tempo_package <= 1000"
                      class="pa-1 red--text"
                    >
                      <span style="font-size: 14px"
                        >Before the system shuts down, please let your manager
                        know that your package is low.
                      </span>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout row wrap>
                        <v-flex xs6>
                          <a @click="selectSpinNumbers('Red')">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Red</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('High')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>High</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('Even')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Even</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs6>
                          <a @click="selectSpinNumbers('Black')">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Black</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('Low')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Low</span>
                            </v-card></a
                          >
                          <a @click="selectSpinNumbers('Odd')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Odd</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs12 class="mt-7">
                          <a @click="neighboursPopUp = true">
                            <v-card
                              class="cyan ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Neighbours</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs12 class="mb-5">
                          <a @click="twoNumbersPopUp = true">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Two Numbers</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs6>
                          <a @click="selectSpinNumbers('High & Red')">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>High & Red</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('High & Black')">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>High & Black</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs6>
                          <a @click="selectSpinNumbers('Low & Black')">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Low & Black</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('Low & Red')">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Low & Red</span>
                            </v-card></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs6>
                      <v-layout row wrap>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Twins')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Twins</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('0 Green')">
                            <v-card
                              class="green ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>0 Green</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="generateRandomBet">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Random</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number }}</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs2>
                          <a
                            v-for="number in 4"
                            :key="number"
                            @click="selectSpinNumbers(number + 6)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 6 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 6 }}</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers(11)">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 11 }}</span>
                            </v-card></a
                          >
                          <a @click="selectSpinNumbers(12)">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 12 }}</span>
                            </v-card></a
                          >
                        </v-flex>

                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number + 12)"
                          >
                            <v-card
                              v-if="number % 2 != 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 12 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 12 }}</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number + 18)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 18 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 18 }}</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 4"
                            :key="number"
                            @click="selectSpinNumbers(number + 24)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 24 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 24 }}</span>
                            </v-card>
                          </a>

                          <a @click="selectSpinNumbers(29)">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 29 }}</span>
                            </v-card></a
                          >
                          <a @click="selectSpinNumbers(30)">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 30 }}</span>
                            </v-card></a
                          >
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number + 30)"
                          >
                            <v-card
                              v-if="number % 2 != 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 30 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 30 }}</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs4>
                          <a @click="selectSpinNumbers('1st Donzen')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>1st Donzen</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('2nd Donzen')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>2nd Donzen</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('3rd Donzen')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>3rd Donzen</span>
                            </v-card>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs1>
                      <a @click="selectSpinNumbers('12|21')">
                        <v-card
                          class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                          id="numbers"
                        >
                          <span
                            >12<br />
                            |<br />
                            21</span
                          >
                        </v-card>
                      </a>
                      <a @click="selectSpinNumbers('13|31')">
                        <v-card
                          class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                          id="numbers"
                        >
                          <span
                            >13 <br />|<br />
                            31</span
                          >
                        </v-card>
                      </a>
                      <a @click="selectSpinNumbers('23|32')">
                        <v-card
                          class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                          id="numbers"
                        >
                          <span
                            >23 <br />
                            |<br />
                            32</span
                          >
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12 class="mt-4 mb-4">
                      <v-layout row wrap>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector A')">
                            <v-card
                              class="orange darken-2 ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector A </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector B')">
                            <v-card
                              class="blue darken-4 ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector B </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector C')">
                            <v-card
                              class="purple ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector C</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector D')">
                            <v-card
                              class="teal accent-3 ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector D </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector E')">
                            <v-card
                              class="yellow accent-2 ma-1 mt-0 mb-2 pt-2 pb-2 customamount black--text"
                              id="numbers"
                            >
                              <span> Sector E </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector F')">
                            <v-card
                              class="grey lighten-3 ma-1 mt-0 mb-2 pt-2 pb-2 customamount black--text"
                              id="numbers"
                            >
                              <span> Sector F </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 0')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 0 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 1')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 1 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 2')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 2 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 3')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 3 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 4')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 4 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 5')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 5 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 6')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 6 </span>
                            </v-card>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3 class="pl-2 pb-2">
                  <v-layout
                    row
                    wrap
                    class="mr-3"
                    v-if="selectedSpinNumbers.length > 0"
                  >
                   
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-2 pb-2"
                      id="selectedNumbers"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pl-8 pt-1">
                          <a
                            class="pa-1 pt-2 pb-3 white--text"
                            dark
                            @click="
                              selectedSpinNumbers = [];
                              spinInputMoney = [];
                            "
                          >
                            <span class="text-capitalize">Clear</span></a
                          >
                        </v-flex>

                        <v-flex xs6 class="text-center pl-3">
                          <a
                            class="pa-1 pt-2 pb-3"
                            @click="spinRecietOverview('')"
                          >
                            <v-icon large class="white--text">print</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>

                      
                    </v-flex>
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(selectedNumber, i) in selectedSpinNumbers"
                      :key="i"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pt-2">
                          <span class="pa-1">{{ selectedNumber }}</span>
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px"
                            v-model="spinInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            outlined
                            @click="spinCurrentMousePosition = i"
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs1 class="pr-3">
                          <a @click="spinRemoveSelectedNumbers(i)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3 class="mt-0">
                  <v-layout row wrap class="text-center mr-1">
                    <v-flex xs6 class="mt-0 pb-1">
                      <v-btn
                        :loading="gameLoading"
                        @click="getLastTwentyGames('spin')"
                        outlined
                        small
                        dark
                        text
                        class="white--text text-capitalize"
                      >
                        <span>Print 20 spin games</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="mt-0 pb-1">
                      <span class="red--text" style="font-size: 14px"
                        >{{ day_block }} Days left</span
                      >
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <a @click="spinApplyToAll">
                        <v-card
                          dark
                          class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="applyToAll"
                        >
                          <span>Apply to All</span>
                        </v-card>
                      </a>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <div class="ma-1 mt-0">
                        <v-text-field
                          v-model="spinSelectedCustomAmount"
                          class="white"
                          placeholder="Birr"
                          :rules="rules"
                          hide-details="true"
                          single-line
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex
                      dark
                      xs4
                      v-for="amount in customAmount"
                      :key="amount + 600"
                    >
                      <a @click="spinAssignSelectedAmount(amount)">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="numbers"
                        >
                          <span>{{ amount }}</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex dark xs4>
                      <a @click="spinclearAll">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="clearBtn"
                        >
                          <span>Clear</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12>
                      <v-card class="ma-1 pa-1 pt-0">
                        <div id="ticketListVertically">
                          <h4
                            v-if="spinIsDuplicated"
                            class="red--text"
                            style="transform: scaleX(-1)"
                          >
                            Ticket Duplicated
                          </h4>
                          <v-form
                            @submit.prevent=""
                            style="transform: scaleX(-1)"
                          >
                            <v-card-text style="font-size: 11px">
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-for="(lastGame, i) in spinLastTwoGames"
                                  :key="i"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(ticket, j) in lastGame.getTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                  >
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{ lastGame.game_number }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />
                                      <span> {{ ticket.others }} </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="spinPrintAgain(ticket)"
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>

                                        <v-btn
                                          v-if="
                                            lastGame.game_status == 'pending'
                                          "
                                          text
                                          small
                                          class="pink--text"
                                          @click="
                                            spinCancelDuplicatedTicket(
                                              ticket,
                                              lastGame.getTickets,
                                              j
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Cancel ticket</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(
                                      ticket, j
                                    ) in lastGame.getLastTickets"
                                    :key="ticket.ticket_id+1000004"
                                    justify-space-around
                                    class="mt-7"
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{
                                          ticket.getGame[0].game_number
                                        }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="
                                            getSpinCopy(
                                              ticket,
                                              ticket.getGame[0]
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-form>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout> -->

              <v-dialog
                v-model="spinIsPlaying"
                persistent
                max-width="420"
                class="red"
              >
                <v-btn
                  small
                  text
                  @click="
                    whichToShow = 'keno';
                    whichIcon = gameItems[5];
                  "
                >
                  <span class="mr-2 text-capitalize white--text text--darken-1"
                    ><img
                      class="clickedicons"
                      height="25px"
                      width="40px"
                      contain
                      src="../assets/SmartPlayKenoIcon.svg"
                  /></span>
                </v-btn>
                <!-- <p
                  class="headline white--text pink pa-5"
                  style="border-radius: 5px"
                >
                  Spin is in playing mode, please try later! <br />
                  <v-btn small text @click="whichToShow = 'keno'">
                    <span
                      class="mr-2 text-capitalize white--text text--darken-1"
                      ><img
                       
                        class="clickedicons"
                        height="32px"
                        width="55px"
                        contain
                        src="../assets/SmartPlayKenoIcon.svg" /></span
                    >
                  </v-btn>
                </p> -->
              </v-dialog>

              <v-dialog
                v-if="1 == 3"
                v-model="SpinVerifyDialog"
                persistent
                max-width="420"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Verify user </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="spinUserIDToVerify"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="spinVerify"
                      >
                        verify
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          SpinVerifyDialog = false;
                          spinUserIDToVerify = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="SpinVerifyDialog" persistent max-width="1500">
                <v-card class="text-left">
                  <div
                    class="pa-2 text-left white--text"
                    style="background-color: #37b34a; font-size: 18px"
                  >
                    Redeem Betslip

                    <a
                      class="text-capitalize grey--text text--lighten-1"
                      style="clear: right; float: right"
                      text
                      @click="
                        SpinVerifyDialog = false;
                        spinUserIDToVerify = '';
                        spinTotalWinMoneyForOneUser = 0;
                        payableList = [];
                        spinSelectedPrint = [];
                        spinSelectedWinMoney = 0;
                        spinVerifyUserData = [];
                      "
                    >
                      X
                    </a>
                  </div>
                  <v-form @submit.prevent="">
                    <v-card-text class="pb-6">
                      <v-layout row wrap>
                        <v-flex xs4 style="font-size: 19px" class="pl-5">
                          <P class="green--text">Enter betslip code or scan</P>
                          <div style="padding-right: 80px">
                            <v-text-field
                              autofocus
                              v-model="spinUserIDToVerify"
                              required
                              dense
                              outlined
                            ></v-text-field>
                          </div>

                          <v-btn
                            @click="
                              spinUserIDToVerify = spinUserIDToVerify + '' + num
                            "
                            text
                            v-for="num in 9"
                            :key="num"
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ num }} </span>
                          </v-btn>

                          <v-btn
                            @click="
                              spinUserIDToVerify = spinUserIDToVerify + 'L'
                            "
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "L" }} </span>
                          </v-btn>
                          <v-btn
                            text
                            @click="
                              spinUserIDToVerify = spinUserIDToVerify + '' + 0
                            "
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "0" }} </span>
                          </v-btn>
                          <v-btn
                            @click="
                              if (spinUserIDToVerify != '')
                                spinUserIDToVerify =
                                  spinUserIDToVerify.substring(
                                    0,
                                    spinUserIDToVerify.split('').length - 1
                                  );
                            "
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-11 pr-11 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> <v-icon small>west</v-icon> </span>
                          </v-btn>

                          <br />
                          <br />

                          <v-btn
                            @click="spinUserIDToVerify = ''"
                            style="background-color: #f0f0f0; font-size: 13px"
                            text
                            class="text-capitalize"
                          >
                            <span> clear </span>
                          </v-btn>

                          <v-btn
                            @click="spinVerify"
                            type="submit"
                            style="
                              background-color: #37b34a;
                              font-size: 13px;
                              clear: right;
                              float: right;
                            "
                            text
                            class="text-capitalize mr-12 white--text"
                          >
                            <span> enter </span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs4 v-if="spinVerifyLoading">
                          <div class="text-center">
                            <v-progress-circular
                              :size="70"
                              :width="2"
                              color="grey"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="" v-if="redeemErrorMessage != ''">
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-7"
                          >
                            {{ redeemErrorMessage }}
                          </div>
                        </v-flex>
                        <v-flex
                          xs8
                          class=""
                          v-if="
                            spinVerifyUserData.length > 0 &&
                            redeemErrorMessage == ''
                          "
                        >
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-4"
                          >
                            <h3 class="green--text">Betslip</h3>

                            <v-layout row wrap>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>ID</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Date</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Time</strong>
                              </v-flex>
                              <v-flex xs12 class="pt-1 pr-10">
                                <hr />
                              </v-flex>

                              <v-flex xs4 class="pl-1 pt-1">
                                {{ spinVerifyUserData[0].user_id }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(spinVerifyUserData[0].ticket_date)
                                  ) | formatDateo
                                }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(spinVerifyUserData[0].ticket_date)
                                  ) | formatDatet
                                }}
                              </v-flex>
                            </v-layout>
                            <br />
                            <h3 class="green--text">Bets</h3>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs12 class="pt-1">
                                <v-divider></v-divider>
                              </v-flex>
                              <v-flex xs2 class="pl-1 pt-1 grey lighten-3">
                              </v-flex>

                              <v-flex xs2 class="pl-0 pt-1 grey lighten-3">
                                <strong>BetID</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Game</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Event No</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-3 pt-1 grey lighten-3">
                                <strong>Market</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-1 pt-1 grey lighten-3">
                                <strong>Selection</strong>
                              </v-flex>
                              <v-flex
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-3"
                              >
                                <strong>Win</strong>
                              </v-flex>

                              <v-flex xs12 class="">
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout
                              row
                              wrap
                              class="pr-10"
                              v-for="(wonList, i) in payableList"
                              :key="i"
                            >
                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5 pb-1"
                                v-if="wonList.includes(' Loss ')"
                              >
                                <a
                                  class="ml-1 mr-3 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >visibility</v-icon
                                  >
                                </a>

                                <a
                                  @click="
                                    spinPayWinnerAward(payableList[i], 'single')
                                  "
                                  class="ml-1 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >print</v-icon
                                  >
                                </a>
                              </v-flex>

                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-1 pt-1 pb-1"
                                v-else
                              >
                                <a
                                  class="ml-1 mr-3 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >visibility</v-icon
                                  >
                                </a>

                                <a
                                  @click="
                                    spinPayWinnerAward(payableList[i], 'single')
                                  "
                                  class="ml-1 pt-0 pa-1 pb-0"
                                  style="
                                    border-radius: 3px;
                                    border: 1px solid green;
                                    background-color: white;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >print</v-icon
                                  >
                                </a>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-0 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                {{ spinVerifyUserData[0].user_id + (i + 1) }}
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-0 pt-1 white--text"
                                v-else
                              >
                                {{ spinVerifyUserData[0].user_id + (i + 1) }}
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                Spin
                              </v-flex>
                              <v-flex
                                xs1
                                style="background-color: #d0b15e"
                                class="pl-0 pt-1 white--text"
                                v-else
                              >
                                Spin
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                {{
                                  spinVerifyUserData[0].getGame[0].game_number
                                }}
                              </v-flex>
                              <v-flex
                                xs1
                                style="background-color: #d0b15e"
                                class="pl-0 pt-1 white--text"
                                v-else
                              >
                                {{
                                  spinVerifyUserData[0].getGame[0].game_number
                                }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-3 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                <span>{{ payableList[i].split("*")[0] }} </span>
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-3 pt-1 white--text"
                                v-else
                              >
                                <span>{{ payableList[i].split("*")[0] }}</span>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                {{ payableList[i].split("*")[0] }}
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-1 pt-1 white--text"
                                v-else
                              >
                                {{ payableList[i].split("*")[0] }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-5"
                                v-if="wonList.includes(' Loss ')"
                              >
                                <span
                                  class=""
                                  v-if="wonList.includes(' Loss ')"
                                >
                                  Br {{ "0.00" }}
                                </span>

                                <span v-else>
                                  Br
                                  {{
                                    payableList[i]
                                      .split("= ")[1]
                                      .split(" Birr")[0]
                                  }}
                                </span>
                              </v-flex>
                              <v-flex
                                xs2
                                style="background-color: #d0b15e"
                                class="pl-1 pt-1 pr-7 white--text text-right"
                                v-else
                              >
                                <span
                                  class=""
                                  v-if="wonList.includes(' Loss ')"
                                >
                                  Br {{ "0.00" }}
                                </span>

                                <span v-else>
                                  Br
                                  {{
                                    payableList[i]
                                      .split("= ")[1]
                                      .split(" Birr")[0]
                                  }}
                                </span>
                              </v-flex>

                              <v-flex xs12 class="">
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs4 class="pl-1 pt-1 grey lighten-5 pb-1">
                                {{
                                  new Date(
                                    Number(
                                      spinVerifyUserData[0].getGame[0].game_date
                                    )
                                  ) | formatDate
                                }}
                              </v-flex>
                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 text-center"
                              >
                                {{
                                  spinVerifyUserData[0].getJackpotHistory
                                    .length > 0
                                    ? "Jackpot"
                                    : ""
                                }}
                              </v-flex>

                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 pr-7 text-right"
                              >
                                Br
                                {{
                                  spinVerifyUserData[0].getJackpotHistory
                                    .length > 0
                                    ? spinVerifyUserData[0].getJackpotHistory[0]
                                        .amount
                                    : "0.00"
                                }}
                              </v-flex>
                            </v-layout>

                            <div class="text-right mr-2 pt-12">
                              <span v-if="spinTotalWinMoneyForOneUser > 0">
                                <strong
                                  class="mr-2"
                                  v-if="
                                    spinVerifyUserData[0].getJackpotHistory
                                      .length > 0
                                  "
                                  >Unclaimed Winnings Br
                                  {{
                                    (
                                      spinTotalWinMoneyForOneUser +
                                      spinVerifyUserData[0].getJackpotHistory[0]
                                        .amount
                                    ).toFixed(2)
                                  }}</strong
                                >

                                <strong class="mr-2" v-else
                                  >Unclaimed Winnings Br
                                  {{
                                    spinTotalWinMoneyForOneUser.toFixed(2)
                                  }}</strong
                                >
                                <v-btn
                                  @click="
                                    spinPayWinnerAward(payableList, 'pay')
                                  "
                                  class="pt-1 pb-1 mt-1 mb-1 mr-2 pr-2 pl-2"
                                  style="font-size: 13px"
                                  dark
                                  text
                                  small
                                  id="loginBtn"
                                >
                                  <span class="text-capitalize">Redeem</span>
                                  <v-img
                                    height="20px"
                                    contain
                                    src="../assets/usd.png"
                                  >
                                  </v-img>
                                </v-btn>
                              </span>

                              <span v-else>
                                <strong class="mr-2"
                                  >Not a Winning Ticket
                                </strong>
                                <v-btn
                                  @click="
                                    spinPayWinnerAward(payableList, 'empty')
                                  "
                                  class="pt-1 pb-1 mt-1 mb-1 mr-2 pr-2 pl-2"
                                  style="font-size: 13px"
                                  dark
                                  text
                                  small
                                  id="loginBtn"
                                >
                                  <span class="text-capitalize">Redeem</span>
                                  <v-img
                                    height="20px"
                                    contain
                                    src="../assets/usd.png"
                                  >
                                  </v-img>
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <div class="mr-4">
                      <v-layout row wrap>
                        <v-flex xs11> </v-flex>

                        <v-flex xs1 class="pl-15">
                          <v-switch
                            class="text-right"
                            v-model="isRedeemPrint"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-form>
                </v-card>
              </v-dialog>

              <!-- <v-dialog v-model="spinWinnersDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Check ticket </v-card-title>

                  <v-card-text>
                    <P
                      class="green--text"
                      v-if="spinTotalWinMoneyForOneUser > 0"
                    >
                      <v-icon class="green--text">check</v-icon>
                      User won total

                      <strong
                        v-if="
                          spinVerifyUserData[0].getJackpotHistory.length > 0
                        "
                        >{{
                          spinTotalWinMoneyForOneUser +
                          spinVerifyUserData[0].getJackpotHistory[0].amount
                        }}</strong
                      >

                      <strong v-else>{{ spinTotalWinMoneyForOneUser }}</strong>
                      Birr <br /><br />
                      <span>
                        <p
                          v-if="
                            spinVerifyUserData[0].getJackpotHistory.length > 0
                          "
                          class="green--text"
                        >
                          {{
                            spinVerifyUserData[0].getJackpotHistory[0]
                              .jack_type
                          }}:
                          {{
                            spinVerifyUserData[0].getJackpotHistory[0].amount
                          }}
                          Birr Won
                        </p>
                        <div v-for="(wonList, i) in payableList" :key="i">
                          <span
                            class="red--text"
                            v-if="wonList.includes(' Loss ')"
                          >
                            {{ wonList }}
                          </span>

                          <span v-else>
                            {{ wonList }}
                          </span>
                        </div>
                      </span>
                    </P>
                    <P class="red--text" v-else
                      ><v-icon class="red--text">cancel</v-icon> User loss the
                      game!
                    </P>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="spinPayLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="spinTotalWinMoneyForOneUser > 0"
                      @click="spinPayWinnerAward"
                    >
                      Pay
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="spinWinnersDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->

              <v-dialog
                v-model="spinCancelDialog"
                persistent
                max-width="420"
                top
                v-if="1 == 4"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Cancel Ticket </v-card-title>

                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar to
                        cancel the ticket</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="spinUserIDToCancel"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>

                      <P>Are you sure you want to cancel this ticket?</P>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="spinCancelLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="normalSpinCancelTicket"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="spinCancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="spinCancelDialog" persistent max-width="1500">
                <v-card class="text-left">
                  <div
                    class="pa-2 text-left white--text"
                    style="background-color: #37b34a; font-size: 18px"
                  >
                    Cancel Betslip

                    <a
                      class="text-capitalize grey--text text--lighten-1"
                      style="clear: right; float: right"
                      text
                      @click="
                        spinCancelDialog = false;
                        sticketToDelete = [];
                        sstakeToCancel = 0;
                        spinUserIDToCancel = '';
                      "
                    >
                      X
                    </a>
                  </div>
                  <v-form @submit.prevent="">
                    <v-card-text class="pb-6">
                      <v-layout row wrap>
                        <v-flex xs4 style="font-size: 19px" class="pl-5">
                          <P class="green--text">Enter betslip code or scan</P>
                          <div style="padding-right: 80px">
                            <v-text-field
                              autofocus
                              v-model="spinUserIDToCancel"
                              required
                              dense
                              outlined
                            ></v-text-field>
                          </div>

                          <v-btn
                            @click="
                              spinUserIDToCancel = spinUserIDToCancel + '' + num
                            "
                            text
                            v-for="num in 9"
                            :key="num"
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ num }} </span>
                          </v-btn>

                          <v-btn
                            @click="
                              spinUserIDToCancel = spinUserIDToCancel + 'L'
                            "
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "L" }} </span>
                          </v-btn>
                          <v-btn
                            text
                            @click="
                              spinUserIDToCancel = spinUserIDToCancel + '' + 0
                            "
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-12 pr-12 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> {{ "0" }} </span>
                          </v-btn>
                          <v-btn
                            @click="
                              if (spinUserIDToCancel != '')
                                spinUserIDToCancel =
                                  spinUserIDToCancel.substring(
                                    0,
                                    spinUserIDToCancel.split('').length - 1
                                  );
                            "
                            text
                            style="font-size: 14px"
                            id="rbtn"
                            class="pl-11 pr-11 mr-4 mb-4 pt-5 pb-5"
                          >
                            <span> <v-icon small>west</v-icon> </span>
                          </v-btn>

                          <br />
                          <br />

                          <v-btn
                            @click="spinUserIDToCancel = ''"
                            style="background-color: #f0f0f0; font-size: 13px"
                            text
                            class="text-capitalize"
                          >
                            <span> clear </span>
                          </v-btn>

                          <v-btn
                            type="submit"
                            @click="sgetTicketToCancel"
                            style="
                              background-color: #37b34a;
                              font-size: 13px;
                              clear: right;
                              float: right;
                            "
                            text
                            class="text-capitalize mr-12 white--text"
                          >
                            <span> enter </span>
                          </v-btn>
                        </v-flex>
                        <v-flex xs4 v-if="cancelLoading">
                          <div class="text-center">
                            <v-progress-circular
                              :size="70"
                              :width="2"
                              color="grey"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="" v-if="redeemErrorMessage != ''">
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-7"
                          >
                            {{ redeemErrorMessage }}
                          </div>
                        </v-flex>

                        <v-flex
                          xs8
                          class=""
                          v-if="
                            sticketToDelete.length > 0 &&
                            redeemErrorMessage == ''
                          "
                        >
                          <div
                            style="border-left: 1px solid rgb(149, 240, 140)"
                            class="pl-4"
                          >
                            <h3 class="green--text">Betslip</h3>

                            <v-layout row wrap>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>ID</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Date</strong>
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-2">
                                <strong>Time</strong>
                              </v-flex>
                              <v-flex xs12 class="pt-1 pr-10">
                                <hr />
                              </v-flex>

                              <v-flex xs4 class="pl-1 pt-1">
                                {{ sticketToDelete[0].user_id }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(sticketToDelete[0].ticket_date)
                                  ) | formatDateo
                                }}
                              </v-flex>
                              <v-flex xs4 class="pl-1 pt-1">
                                {{
                                  new Date(
                                    Number(sticketToDelete[0].ticket_date)
                                  ) | formatDatet
                                }}
                              </v-flex>
                            </v-layout>
                            <br />
                            <h3 class="green--text">Bets</h3>
                            <v-layout row wrap class="pr-10">
                              <v-flex xs12 class="pt-1">
                                <v-divider></v-divider>
                              </v-flex>

                              <v-flex xs4 class="pl-0 pt-1 grey lighten-3">
                                <strong>BetID</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Game</strong>
                              </v-flex>
                              <v-flex xs1 class="pl-1 pt-1 grey lighten-3">
                                <strong>Event No</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-3 pt-1 grey lighten-3">
                                <strong>Market</strong>
                              </v-flex>
                              <v-flex xs2 class="pl-1 pt-1 grey lighten-3">
                                <strong>Selection</strong>
                              </v-flex>
                              <v-flex
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-3"
                              >
                                <strong>Win</strong>
                              </v-flex>

                              <v-flex xs12 class="">
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>
                            <v-layout
                              row
                              wrap
                              class="pr-10"
                              v-for="(
                                wonList, i
                              ) in sticketToDelete[0].choosen_numbers.split(
                                ':'
                              )"
                              :key="i"
                            >
                              <v-flex
                                xs4
                                class="pl-0 pt-1 grey lighten-5"
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                {{ sticketToDelete[0].user_id + (i + 1) }}
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                Keno
                              </v-flex>

                              <v-flex
                                xs1
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                {{
                                  sticketToDelete[0].getGameForShifts[0]
                                    .game_number
                                }}
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-3 pt-1 grey lighten-5"
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                <span
                                  v-if="
                                    wonList.split('*')[0] == 'HEADS' ||
                                    wonList.split('*')[0] == 'EVENS' ||
                                    wonList.split('*')[0] == 'TAILS'
                                  "
                                  >{{ wonList.split("*")[0] }}</span
                                >
                                <span v-else>{{ wonList.split("*")[0] }}</span>
                              </v-flex>

                              <v-flex
                                xs2
                                class="pl-1 pt-1 grey lighten-5"
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                {{ wonList.split("*")[0] }}
                              </v-flex>

                              <v-flex
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                                xs2
                                class="pl-1 pt-1 pr-7 text-right grey lighten-5"
                              >
                                <span class=""> Br {{ "0.00" }} </span>
                              </v-flex>

                              <v-flex
                                xs12
                                class=""
                                v-if="
                                  sticketToDelete[0].choosen_numbers.split(
                                    ':'
                                  )[0] != ''
                                "
                              >
                                <v-divider></v-divider>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap class="pr-10">
                              <v-flex xs4 class="pl-1 pt-1 grey lighten-5 pb-1">
                                {{
                                  new Date(
                                    Number(
                                      sticketToDelete[0].getGameForShifts[0]
                                        .game_date
                                    )
                                  ) | formatDate
                                }}
                              </v-flex>
                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 text-center"
                              >
                                {{ "" }}
                              </v-flex>

                              <v-flex
                                xs4
                                class="pl-1 pt-1 grey lighten-5 pb-1 pr-7 text-right"
                              >
                                Br
                                {{ "0.00" }}
                              </v-flex>
                            </v-layout>

                            <div class="text-right mr-2 pt-12">
                              <span>
                                <strong class="mr-2"
                                  >Total Stake Br
                                  {{ sstakeToCancel.toFixed(2) }}</strong
                                >

                                <v-btn
                                  @click="normalSpinCancelTicket()"
                                  class="pt-1 pb-1 mt-1 mb-1 mr-2 pr-2 pl-2"
                                  style="
                                    font-size: 13px;
                                    background-color: #f0ad4e;
                                  "
                                  dark
                                  text
                                  small
                                  id="loginBtn"
                                >
                                  <span class="text-capitalize mr-2"
                                    >Cancel</span
                                  ><v-icon
                                    style="font-size: 16px; font-weight: bold"
                                    small
                                    class="white--text"
                                    >block</v-icon
                                  >
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <div class="mr-4">
                      <v-layout row wrap>
                        <v-flex xs11> </v-flex>

                        <v-flex xs1 class="pl-15">
                          <v-switch
                            class="text-right"
                            v-model="isCancelPrint"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="neighboursPopUp"
                persistent
                max-width="420"
                top
              >
                <v-card class="text-center pt-5">
                  <v-card-title>
                    Choose Number <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="neighboursPopUp = false"
                    >
                      Close
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs2 v-for="(neighbors, i) in 37" :key="i">
                        <a @click="chooseNeighbor(i)">
                          <v-card
                            class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                            id="numbers"
                          >
                            <span>{{ i }}</span>
                          </v-card>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="twoNumbersPopUp"
                persistent
                max-width="420"
                top
              >
                <v-card class="text-center pt-5">
                  <v-card-title>
                    Choose two numbers <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="
                        twoNumbersPopUp = false;
                        twoNumbers = [];
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex v-if="twoNumbers.length > 0" xs12>
                        <h3 class="black--text mb-4">
                          {{ twoNumbers.toString() }}
                        </h3>
                      </v-flex>
                      <v-flex xs2 v-for="(neighbors, i) in 37" :key="i">
                        <a @click="chooseTwoNumbers(i)">
                          <v-card
                            class="grey lighten-2 black--text ma-1 mb-0 pt-2 pb-2 customamount"
                            id="numbers"
                          >
                            <h3>{{ i }}</h3>
                          </v-card>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize blue--text"
                      text
                      @click="saveTwoNumbers"
                    >
                      save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="srecietDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-text
                    ref="sprintReceipt"
                    v-if="spinVerifyUserData.length > 0"
                    style="font-family: Arial; margin-left: 0px; color: black"
                  >
                    <div>
                      <div
                        style="
                          font-size: 14px;
                          text-align: right;
                          font-weight: normal;
                        "
                      >
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>

                        <div style="margin-top: -1px">
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </div>

                        <div style="margin-top: -1px">
                          {{ new Date(Date.now()) | formatDate }}

                          (UTC +3)
                        </div>
                      </div>
                    </div>
                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -5px;
                        text-align: center;
                        padding-right: 5px;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Redeem Receipt</div>
                          <div style="margin-top: -2px">
                            {{ spinVerifyUserData[0].user_id }}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -8px;
                        text-align: left;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Redeemed Amount :</div>
                        </td>
                        <td style="font-weight: bold; text-align: right">
                          <div>
                            <Strong>B</Strong>r
                            {{ spinSelectedWinMoney.toFixed(2) }}
                          </div>
                        </td>
                      </tr>

                      <tr
                        v-if="
                          spinVerifyUserData[0].getJackpotHistory.length > 0
                        "
                      >
                        <td style="font-weight: bold">
                          <div>Jackpot Amount :</div>
                        </td>
                        <td style="font-weight: bold; text-align: right">
                          <div>
                            <Strong>B</Strong>r
                            {{
                              spinVerifyUserData[0].getJackpotHistory[0].amount.toFixed(
                                2
                              )
                            }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold">
                          <div style="margin-top: -8px">Redeemed Time:</div>
                        </td>
                        <td style="text-align: right; font-size: 14px">
                          <div style="margin-top: -8px">
                            {{ new Date(Date.now()) | formatDates }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          <div
                            style="margin-top: -6px"
                            v-if="spinSelectedWinMoney > 0"
                          >
                            Winning Bets
                          </div>

                          <div style="margin-top: -6px" v-else>
                            Not a Winning Ticket
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="width: 100%; font-size: 14px"
                      v-if="spinTotalWinMoneyForOneUser > 0"
                    >
                      <span v-for="(wonList, i) in spinSelectedPrint" :key="i">
                        <div v-if="!wonList.includes(' Loss ')">
                          <span>
                            <div
                              style="margin-top: -6px"
                              v-if="spinSelectedPrint[i].includes('Neighbors')"
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Neighbors</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i]
                                      .split("*")[0]
                                      .split("(")[1]
                                      .split(")")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].neighbors
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="spinSelectedPrint[i].includes('&')"
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                  >
                                    High/Low And Color</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low_color
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="spinSelectedPrint[i].includes('Donzen')"
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Dozens</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].dozen
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="spinSelectedPrint[i].includes('Sector')"
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Sector (Colour)</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;
                                  <span
                                    v-if="
                                      spinSelectedPrint[i].split('*')[0] ==
                                      'Sector A'
                                    "
                                    >32/15/19/4/21/2</span
                                  >
                                  <span
                                    v-if="
                                      spinSelectedPrint[i].split('*')[0] ==
                                      'Sector B'
                                    "
                                    >25/17/34/6/27/13</span
                                  >
                                  <span
                                    v-if="
                                      spinSelectedPrint[i].split('*')[0] ==
                                      'Sector C'
                                    "
                                    >36/11/30/8/23/10</span
                                  >
                                  <span
                                    v-if="
                                      spinSelectedPrint[i].split('*')[0] ==
                                      'Sector D'
                                    "
                                    >5/24/16/33/1/20</span
                                  >
                                  <span
                                    v-if="
                                      spinSelectedPrint[i].split('*')[0] ==
                                      'Sector E'
                                    "
                                    >14/31/9/22/18/29</span
                                  >
                                  <span
                                    v-if="
                                      spinSelectedPrint[i].split('*')[0] ==
                                      'Sector F'
                                    "
                                    >7/28/12/35/3/26</span
                                  >
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].sector
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>
                            <div
                              style="margin-top: -6px"
                              v-if="spinSelectedPrint[i].includes('Final')"
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Final</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].final
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i].split('*')[0] == 'Red' ||
                                spinSelectedPrint[i].split('*')[0] == 'Black'
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Color</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i]
                                  .split('*')[0]
                                  .includes('Even') ||
                                spinSelectedPrint[i]
                                  .split('*')[0]
                                  .includes('Odd')
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Odd/Even</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i].split('*')[0] == 'High' ||
                                spinSelectedPrint[i].split('*')[0] == 'Low'
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >High/Low</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i]
                                  .split('*')[0]
                                  .includes('Green')
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i]
                                      .split("*")[0]
                                      .split(" Green")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i]
                                  .split('*')[0]
                                  .includes(',') &&
                                spinSelectedPrint[i].split('*')[0].split(',')
                                  .length == 2
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number / 2
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i]
                                  .split('*')[0]
                                  .includes('Twins')
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Twins</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].twins
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="
                                spinSelectedPrint[i].split('*')[0].includes('|')
                              "
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Mirror</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].mirror
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>

                            <div
                              style="margin-top: -6px"
                              v-if="!isNaN(spinSelectedPrint[i].split('*')[0])"
                            >
                              <tr>
                                <td style="font-size: 14px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{
                                    new Date(
                                      Number(spinVerifyUserData[0].ticket_date)
                                    ) | formatDate
                                  }}
                                  #{{
                                    spinVerifyUserData[0].getGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    spinSelectedPrint[i].split("*")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 14px">Br </span>
                                  {{
                                    spinSelectedPrint[i]
                                      .split(" = ")[0]
                                      .split("*")[1]
                                  }}
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </div>
                          </span>
                        </div>
                      </span>

                      <!-- <span
                          style="padding-top: 2px"
                          v-for="(other, i) in selectedEvenOddAndHighLow"
                          :key="i + 10001"
                        >
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <span
                              v-if="
                                odds.choosen_length.toLowerCase() ==
                                  other.toLowerCase() &&
                                selectedEvenOddAndHighLow.length ==
                                  evenOddInputMoney.length
                              "
                            >
                              <tr>
                                <td style="text-align: left; font-size: 11px">
                                  <span
                                    v-if="
                                      other == 'TAILS' ||
                                      other == 'HEADS' ||
                                      other == 'EVENS'
                                    "
                                    style="font-weight: bold; font-size: 11px"
                                    >Heads and Tails</span
                                  >

                                  <br />
                                  Keno {{ new Date(gameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : currentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ other }}&nbsp;&nbsp;{{
                                    odds.odd_value
                                  }}.00
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 11px">Br </span
                                  >{{ evenOddInputMoney[i] }}.00
                                  <span><br /></span><span><br /><br /></span>
                                </td>
                              </tr>
                            </span>
                          </span>
                        </span> -->
                    </table>
                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -4px;
                        text-align: center;
                      "
                    >
                      <tr>
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          <div>
                            {{ randomSring }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      :loading="payLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="totalWinMoneyForOneUser > 0"
                    >
                      Waiting...
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="recietDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="screcietDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-text
                    ref="scprintReceipt"
                    v-if="sticketToDelete.length > 0"
                    style="font-family: Arial; margin-left: 0px; color: black"
                  >
                    <div>
                      <div
                        style="
                          font-size: 14px;
                          text-align: right;
                          font-weight: normal;
                        "
                      >
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>

                        <div style="margin-top: -1px">
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </div>

                        <div style="margin-top: -1px">
                          {{ new Date(Date.now()) | formatDate }}

                          (UTC +3)
                        </div>
                      </div>
                    </div>
                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -5px;
                        text-align: center;
                        padding-right: 5px;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Cancel Receipt</div>
                          <div style="margin-top: -2px">
                            {{ sticketToDelete[0].user_id }}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -8px;
                        text-align: left;
                      "
                    >
                      <tr>
                        <td style="font-weight: bold">
                          <div>Cancelled Amount :</div>
                        </td>
                        <td style="font-weight: bold; text-align: right">
                          <div>
                            <Strong>B</Strong>r
                            {{ sstakeToCancel.toFixed(2) }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold">
                          <div style="margin-top: -8px">Cancelled Time:</div>
                        </td>
                        <td style="text-align: right; font-size: 14px">
                          <div style="margin-top: -8px">
                            {{ new Date(Date.now()) | formatDates }}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table
                      style="
                        width: 100%;
                        font-size: 14px;
                        margin-top: -4px;
                        text-align: center;
                      "
                    >
                      <tr>
                        <td
                          style="
                            text-align: center;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          <div>
                            {{ randomSring }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="crecietDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <v-dialog v-model="cashierOption" persistent style="">
              <v-card class="text-left cashoption">
                <div
                  class="pa-2 pr-1 text-left white--text"
                  style="background-color: #37b34a; font-size: 18px"
                >
                  Cashier Options

                  <a
                    class="text-capitalize grey--text text--lighten-1"
                    style="clear: right; float: right"
                    text
                    @click="cashierOption = false"
                  >
                    X
                  </a>
                </div>
                <v-form @submit.prevent="">
                  <v-card-text class="pb-4">
                    <v-layout row wrap>
                      <v-flex
                        xs12
                        style="font-size: 20px"
                        class="text-right green--text pr-0"
                      >
                        Balance:
                        <strong
                          >Br
                          {{
                            (
                              totalMoneyOnHand -
                              totalMoneyPaid -
                              paidKenoMoneyFromYesterday +
                              (spinTotalMoneyOnHand -
                                spinTotalMoneyPaid -
                                paidSpinMoneyFromYesterday) -
                              paidJackpotMoney
                            ).toFixed(2)
                          }}
                        </strong>
                        <v-btn
                          @click="
                            getMoneyOnHands();
                            getSpinMoneyOnHands();
                          "
                          text
                          x-small
                          class="pt-3 pb-3 ml-1 pl-0 pr-0"
                          style="border: 1px solid rgb(13, 224, 13)"
                        >
                          <v-icon class="green--text">refresh </v-icon>
                        </v-btn>
                        <br />
                        <span class="red--text mr-3" style="font-size: 14px"
                          >{{ day_block }} Days left</span
                        >
                      </v-flex>
                      <v-flex xs1>
                        <v-btn
                          @click="isReport = 're'"
                          v-if="isReport == 're'"
                          text
                          class="green text-capitalize mt-1"
                          style="
                            border: 1px solid rgb(188, 240, 188);
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            font-size: 13px;
                            border-bottom: 0px;
                          "
                        >
                          Reports
                        </v-btn>
                        <v-btn
                          @click="isReport = 're'"
                          v-else
                          text
                          class="text-capitalize mt-1"
                          style="
                            border: 1px solid rgb(188, 240, 188);
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            font-size: 13px;
                            border-bottom: 0px;
                          "
                        >
                          Reports
                        </v-btn>
                      </v-flex>
                      <v-flex xs9>
                        <v-btn
                          v-if="isReport == 'res'"
                          @click="isReport = 'res'"
                          text
                          class="green mr-1 text-capitalize mt-1"
                          style="
                            border: 1px solid rgb(188, 240, 188);
                            border-bottom-left-radius: 0px;
                            border-bottom: 0px;
                            border-bottom-right-radius: 0px;
                            font-size: 13px;
                          "
                        >
                          Event Result Search
                        </v-btn>
                        <v-btn
                          v-else
                          @click="isReport = 'res'"
                          text
                          class="mr-1 text-capitalize mt-1"
                          style="
                            border: 1px solid rgb(188, 240, 188);
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            font-size: 13px;
                            border-bottom: 0px;
                          "
                        >
                          Event Result Search
                        </v-btn>
                      </v-flex>
                      <v-flex xs2 class="pl-10">
                        <v-btn
                          text
                          class="ml-1 mr-3 text-capitalize mt-1"
                          style="
                            border: 1px solid rgb(188, 240, 188);
                            border-bottom: 0px;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            font-size: 13px;
                          "
                        >
                          change password
                        </v-btn>
                      </v-flex>

                      <v-flex
                        xs12
                        style="border: 1px solid rgb(7, 185, 7)"
                        v-if="isReport == 're'"
                      >
                        <v-layout wrap row class="ma-4">
                          <v-flex xs1>
                            <v-btn
                              @click="isSummary = 'sum'"
                              v-if="isSummary == 'sum'"
                              text
                              class="green text-capitalize mt-1"
                              style="
                                border: 1px solid rgb(188, 240, 188);
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                                font-size: 13px;
                                border-bottom: 0px;
                              "
                            >
                              Summary
                            </v-btn>
                            <v-btn
                              @click="isSummary = 'sum'"
                              v-else
                              text
                              class="text-capitalize mt-1"
                              style="
                                border: 1px solid rgb(188, 240, 188);
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                                font-size: 13px;
                                border-bottom: 0px;
                              "
                            >
                              Summary
                            </v-btn>
                          </v-flex>
                          <v-flex xs9>
                            <v-btn
                              v-if="isSummary == 'rec'"
                              @click="isSummary = 'rec'"
                              text
                              class="green mr-1 text-capitalize mt-1"
                              style="
                                border: 1px solid rgb(188, 240, 188);
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                                font-size: 13px;
                                border-bottom: 0px;
                              "
                            >
                              Recall Bets
                            </v-btn>
                            <v-btn
                              v-else
                              @click="isSummary = 'rec'"
                              text
                              class="mr-1 text-capitalize mt-3"
                              style="
                                border: 1px solid rgb(188, 240, 188);
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                                font-size: 13px;
                                border-bottom: 0px;
                              "
                            >
                              Recall Bets
                            </v-btn>

                            <v-btn
                              text
                              class="mr-1 text-capitalize mt-1"
                              style="
                                border: 1px solid rgb(188, 240, 188);
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                                font-size: 13px;
                                border-bottom: 0px;
                              "
                              @click="reportTicketDialog = true"
                            >
                              <span class="mr-2 text-capitalize"
                                >Report keno</span
                              >
                            </v-btn>

                            <v-btn
                              text
                              class="mr-1 text-capitalize mt-1"
                              style="
                                border: 1px solid rgb(188, 240, 188);
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                                font-size: 13px;
                                border-bottom: 0px;
                              "
                              @click="spinReportTicketDialog = true"
                            >
                              <span class="mr-2 text-capitalize"
                                >Report spin</span
                              >
                            </v-btn>
                          </v-flex>

                          <v-flex
                            xs12
                            style="
                              border: 1px solid rgb(7, 185, 7);
                              background-color: rgb(250, 249, 249);
                            "
                            v-if="isSummary == 'sum'"
                          >
                            <v-layout wrap row class="ma-4 mr-5 ml-5">
                              <v-flex
                                xs12
                                style="
                                  border: 1px solid rgb(182, 184, 182);
                                  background-color: white;
                                "
                                class="pl-4 pa-1 pr-4"
                              >
                                <v-layout row wrap class="">
                                  <v-flex xs4>
                                    <span class="black--text">From Date</span
                                    ><br />
                                    <input
                                      type="text"
                                      size="40"
                                      style="
                                        border: 1px solid rgb(182, 184, 182);
                                        background-color: white;
                                      "
                                      class="pl-2"
                                      v-model="date1"
                                      disabled
                                    />

                                    <span
                                      class="grey lighten-3 pa-1"
                                      style="
                                        border-top-right-radius: 3px;
                                        border-bottom-right-radius: 3px;
                                      "
                                    >
                                      <v-icon
                                        class="green--text"
                                        style="opacity: 0.5"
                                        >date_range</v-icon
                                      >
                                      <v-icon
                                        class="green--text"
                                        style="opacity: 0.5"
                                        >access_time</v-icon
                                      >
                                    </span>
                                  </v-flex>

                                  <v-flex xs4 class="ml-3">
                                    <span class="black--text">To Date</span
                                    ><br />
                                    <input
                                      type="text"
                                      size="40"
                                      style="
                                        border: 1px solid rgb(182, 184, 182);
                                        background-color: white;
                                      "
                                      class="pl-2"
                                      v-model="date2"
                                      disabled
                                    />
                                    <span
                                      class="grey lighten-3 pa-1"
                                      style="
                                        border-top-right-radius: 3px;
                                        border-bottom-right-radius: 3px;
                                      "
                                    >
                                      <v-icon
                                        class="green--text"
                                        style="opacity: 0.5"
                                        >date_range</v-icon
                                      >
                                      <v-icon
                                        class="green--text"
                                        style="opacity: 0.5"
                                        >access_time</v-icon
                                      >
                                    </span>
                                  </v-flex>

                                  <v-flex xs12 class="pr-4">
                                    <v-btn
                                      @click="getAllSummary()"
                                      small
                                      text
                                      class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                      style="
                                        border: 1px solid rgb(100, 165, 100);

                                        font-size: 11px;
                                      "
                                    >
                                      <v-icon class="green--text"
                                        >refresh
                                      </v-icon>
                                      Refresh
                                    </v-btn>
                                  </v-flex>
                                </v-layout>

                                <v-layout row wrap class="mb-15 pa-0 pt-0">
                                  <v-flex
                                    class="text-right pa-3 pt-0 black--text pt-0"
                                    style="font-size: 12px"
                                    xs12
                                  >
                                    <strong
                                      >Report Date:
                                      {{
                                        new Date(repoDate) | formatDate
                                      }}</strong
                                    >
                                  </v-flex>
                                  <horizontal-scroll
                                    id="reportsum"
                                    class="horizontal-scroll white pa-0 pt-0"
                                    style="border-radius: 5px"
                                  >
                                    <table
                                      style="width: 100%; font-size: 12px"
                                      class="ma-0 mt-0"
                                    >
                                      <tr
                                        style="
                                          position: -webkit-sticky;
                                          position: sticky;
                                          top: 0;
                                        "
                                      >
                                        <th class="pa-3 pr-4 pl-1"></th>
                                        <th class="pa-3 pr-4">Retail User</th>
                                        <th class="pa-3 pr-0">
                                          From Date
                                          <v-icon small class="green--text ml-0"
                                            >south_sharp</v-icon
                                          >
                                        </th>
                                        <th class="pa-3 pr-4">To Date</th>
                                        <th class="pa-3 pr-4">Start Balance</th>
                                        <th class="pa-3 pr-4">Deposits</th>
                                        <th class="pa-3 pr-4">Bets</th>

                                        <th class="pa-3 pr-4">Cancellations</th>
                                        <th class="pa-3 pr-4">Redeemed</th>
                                        <th class="pa-3 pr-4">Withdraws</th>
                                        <th class="pa-3 pr-4">End Balance</th>
                                      </tr>
                                      <tr
                                        v-for="(dailyReport, i) in allDailyWork"
                                        :key="i"
                                        id="sumd"
                                      >
                                        <td class="pa-3 pr-5 pl-1">
                                          <a
                                            class="ml-1 pt-2 pb-2 pr-4 pl-4"
                                            style="
                                              border-radius: 3px;
                                              border: 1px solid green;
                                              background-color: white;
                                            "
                                          >
                                            <v-icon class="green--text"
                                              >print</v-icon
                                            >
                                          </a>
                                        </td>
                                        <td class="pa-3 pr-5">
                                          {{
                                            $store.state.cashier
                                              .cashier_full_name
                                          }}
                                        </td>
                                        <td class="pa-3 pr-5">
                                          {{
                                            [
                                              dailyReport.time.split("-")[0],
                                              dailyReport.time.split("-")[1],
                                              dailyReport.time.split("-")[2],
                                            ].join("/")
                                          }}
                                          06:00:00
                                        </td>
                                        <td class="pa-3 pr-5">
                                          {{
                                            [
                                              dailyReport.time.split("-")[0],
                                              dailyReport.time.split("-")[1],
                                              dailyReport.time.split("-")[2],
                                            ].join("/")
                                          }}
                                          23:59:00
                                        </td>
                                        <td class="pa-3 pr-7">
                                          Br {{ "0.00" }}
                                        </td>
                                        <td class="pa-3 pr-7">
                                          Br {{ "0.00" }}
                                        </td>
                                        <td class="pa-3 pr-7">
                                          Br {{ dailyReport.totalMoney }}
                                        </td>

                                        <td class="pa-3 pr-7">
                                          {{ dailyReport.nocancelledTicket }}
                                        </td>
                                        <td class="pa-3 pr-7">
                                          Br {{ dailyReport.paidMoney }}
                                        </td>
                                        <td class="pa-3 pr-7">
                                          Br {{ "0.00" }}
                                        </td>
                                        <td class="pa-3 pr-7">
                                          Br {{ dailyReport.onhand }}
                                        </td>

                                        <hr />
                                      </tr>
                                    </table>
                                  </horizontal-scroll>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex
                            xs12
                            style="
                              border: 1px solid rgb(7, 185, 7);
                              background-color: rgb(250, 249, 249);
                            "
                            v-if="isSummary == 'rec'"
                          >
                            <v-layout wrap row class="ma-4 mr-5 ml-5">
                              <v-flex
                                xs12
                                style="
                                  border: 1px solid rgb(182, 184, 182);
                                  background-color: white;
                                "
                                class="pl-4 pa-1 pr-4"
                              >
                                <v-layout row wrap class="pb-3">
                                  <v-flex xs12 class="pl-3">
                                    <span class="black--text">Retail User</span
                                    ><br />
                                    <select
                                      class="pl-2"
                                      style="
                                        border: 1px solid rgb(192, 190, 190);
                                        border-radius: 3px;
                                        background-color: rgb(236, 234, 234);
                                      "
                                      name="cars"
                                      id="dropm"
                                    >
                                      <option
                                        :value="
                                          $store.state.cashier.cashier_full_name
                                        "
                                      >
                                        {{
                                          $store.state.cashier.cashier_full_name
                                        }}
                                      </option>
                                    </select>
                                  </v-flex>

                                  <v-flex xs12 class="pl-3">
                                    <v-btn
                                      small
                                      text
                                      class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                      style="
                                        border: 1px solid rgb(100, 165, 100);

                                        font-size: 11px;
                                      "
                                    >
                                      <v-icon class="green--text"
                                        >refresh
                                      </v-icon>
                                      Refresh
                                    </v-btn>

                                    <v-btn
                                      v-if="whichRecall == 'spin'"
                                      @click="whichRecall = 'keno'"
                                      small
                                      text
                                      class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                      style="
                                        border: 1px solid rgb(100, 165, 100);

                                        font-size: 11px;
                                      "
                                    >
                                      Switch to Keno
                                    </v-btn>
                                    <v-btn
                                      v-if="whichRecall == 'keno'"
                                      @click="whichRecall = 'spin'"
                                      small
                                      text
                                      class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                      style="
                                        border: 1px solid rgb(100, 165, 100);

                                        font-size: 11px;
                                      "
                                    >
                                      Switch to Spin
                                    </v-btn>
                                  </v-flex>
                                </v-layout>

                                <v-layout row wrap class="mb-15 pa-0 pt-0 pl-5">
                                  <table
                                    style="
                                      width: 100%;
                                      font-size: 12px;
                                      border-collapse: collapse;
                                    "
                                    class="ma-0 mt-0"
                                    id="hover"
                                    v-if="whichRecall == 'keno'"
                                  >
                                    <tr id="resuid">
                                      <th
                                        style="border: 0px"
                                        class="pa-3 pr-10"
                                      >
                                        Retail User
                                      </th>

                                      <th style="border: 0px" class="pa-3 pr-4">
                                        Date
                                        <v-icon small class="green--text ml-0"
                                          >south_sharp</v-icon
                                        >
                                      </th>

                                      <th style="border: 0px" class="pa-3 pr-0">
                                        Stake
                                      </th>
                                      <th style="border: 0px" class="pa-3 pr-4">
                                        Description
                                      </th>

                                      <th
                                        style="border: 0px"
                                        class="pa-3 pr-4"
                                      ></th>
                                    </tr>

                                    <tr
                                      id="resuid"
                                      v-for="(dailyReport, i) in currentTickets"
                                      :key="i"
                                    >
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-10"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          {{
                                            $store.state.cashier
                                              .cashier_full_name
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-5"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          {{
                                            new Date(
                                              Number(dailyReport.ticket_date)
                                            ) | formatDate
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-5"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span
                                          v-if="
                                            dailyReport.choosen_numbers.split(
                                              ':'
                                            )[0].length > 0
                                          "
                                        >
                                          Br
                                          {{
                                            dailyReport.choosen_numbers
                                              .split(":")[0]
                                              .split("*")[1]
                                          }}
                                        </span>
                                        <span v-else>
                                          Br
                                          {{
                                            dailyReport.others
                                              .split(":")[0]
                                              .split("*")[1]
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-7"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          Single, Keno-{{
                                            dailyReport.getGameForShifts[0]
                                              .game_number
                                          }}
                                        </span>
                                      </td>

                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                            dailyReport.cashier_id &&
                                          isAllowedToCopy == 1
                                        "
                                        class="pa-3 pr-5 pl-0"
                                      >
                                        <a
                                          @click="
                                            getCopy(
                                              dailyReport,
                                              dailyReport.getGameForShifts[0]
                                            )
                                          "
                                          class="ml-1 pt-2 pb-2 pr-4 pl-4"
                                          style="
                                            border-radius: 3px;
                                            border: 1px solid green;
                                            background-color: white;
                                          "
                                        >
                                          <v-icon class="green--text"
                                            >print</v-icon
                                          >
                                        </a>

                                        <v-btn
                                          v-if="
                                            dailyReport.getGameForShifts[0]
                                              .game_status == 'pending'
                                          "
                                          small
                                          text
                                          @click="
                                            cancelDuplicatedTicket(
                                              dailyReport,
                                              currentTickets,
                                              i
                                            )
                                          "
                                        >
                                          <v-icon small class="red--text"
                                            >remove</v-icon
                                          >
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </table>

                                  <table
                                    style="
                                      width: 100%;
                                      font-size: 12px;
                                      border-collapse: collapse;
                                    "
                                    class="ma-0 mt-0"
                                    id="hover"
                                    v-if="whichRecall == 'spin'"
                                  >
                                    <tr id="resuid">
                                      <th
                                        style="border: 0px"
                                        class="pa-3 pr-10"
                                      >
                                        Retail User
                                      </th>

                                      <th style="border: 0px" class="pa-3 pr-4">
                                        Date
                                        <v-icon small class="green--text ml-0"
                                          >south_sharp</v-icon
                                        >
                                      </th>

                                      <th style="border: 0px" class="pa-3 pr-0">
                                        Stake
                                      </th>
                                      <th style="border: 0px" class="pa-3 pr-4">
                                        Description
                                      </th>

                                      <th
                                        style="border: 0px"
                                        class="pa-3 pr-4"
                                      ></th>
                                    </tr>

                                    <tr
                                      id="resuid"
                                      v-for="(
                                        dailyReport, i
                                      ) in spinLastTwoGames[0].getTickets"
                                      :key="i"
                                    >
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-10"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          {{
                                            $store.state.cashier
                                              .cashier_full_name
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-5"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          {{
                                            new Date(
                                              Number(dailyReport.ticket_date)
                                            ) | formatDate
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-5"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span
                                          v-if="
                                            dailyReport.choosen_numbers.split(
                                              ':'
                                            )[0].length > 0
                                          "
                                        >
                                          Br
                                          {{
                                            dailyReport.choosen_numbers
                                              .split(":")[0]
                                              .split("*")[1]
                                          }}
                                        </span>
                                        <span v-else>
                                          Br
                                          {{
                                            dailyReport.others
                                              .split(":")[0]
                                              .split("*")[1]
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-7"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          Single, Spin-{{
                                            spinLastTwoGames[0].game_number
                                          }}
                                        </span>
                                      </td>

                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                            dailyReport.cashier_id &&
                                          isAllowedToCopy == 1
                                        "
                                        class="pa-3 pr-5 pl-0"
                                      >
                                        <a
                                          @click="spinPrintAgain(dailyReport)"
                                          class="ml-1 pt-2 pb-2 pr-4 pl-4"
                                          style="
                                            border-radius: 3px;
                                            border: 1px solid green;
                                            background-color: white;
                                          "
                                        >
                                          <v-icon class="green--text"
                                            >print</v-icon
                                          >
                                        </a>

                                        <v-btn
                                          small
                                          text
                                          @click="
                                            spinCancelDuplicatedTicket(
                                              dailyReport,
                                              spinLastTwoGames[0].getTickets,
                                              i
                                            )
                                          "
                                        >
                                          <v-icon small class="red--text"
                                            >remove</v-icon
                                          >
                                        </v-btn>
                                      </td>
                                    </tr>

                                    <tr
                                      id="resuid"
                                      v-for="(
                                        dailyReport, i
                                      ) in spinLastTwoGames[0].getLastTickets"
                                      :key="i+1000004"
                                    >
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-10"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          {{
                                            $store.state.cashier
                                              .cashier_full_name
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-5"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          {{
                                            new Date(
                                              Number(dailyReport.ticket_date)
                                            ) | formatDate
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-5"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span
                                          v-if="
                                            dailyReport.choosen_numbers.split(
                                              ':'
                                            )[0].length > 0
                                          "
                                        >
                                          Br
                                          {{
                                            dailyReport.choosen_numbers
                                              .split(":")[0]
                                              .split("*")[1]
                                          }}
                                        </span>
                                        <span v-else>
                                          Br
                                          {{
                                            dailyReport.others
                                              .split(":")[0]
                                              .split("*")[1]
                                          }}
                                        </span>
                                      </td>
                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                          dailyReport.cashier_id
                                        "
                                        class="pa-3 pr-7"
                                        style="border-right: 1px solid rgb(221, 219, 219);"
                                      >
                                        <span>
                                          Single, Spin-{{
                                            dailyReport.getGame[0].game_number
                                          }}
                                        </span>
                                      </td>

                                      <td
                                        v-if="
                                          $store.state.cashier.cashier_id ==
                                            dailyReport.cashier_id &&
                                          isAllowedToCopy == 1
                                        "
                                        class="pa-3 pr-5 pl-0"
                                      >
                                        <a
                                          @click="
                                            getSpinCopy(
                                              dailyReport,
                                              dailyReport.getGame[0]
                                            )
                                          "
                                          class="ml-1 pt-2 pb-2 pr-4 pl-4"
                                          style="
                                            border-radius: 3px;
                                            border: 1px solid green;
                                            background-color: white;
                                          "
                                        >
                                          <v-icon class="green--text"
                                            >print</v-icon
                                          >
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        xs12
                        style="
                          border: 1px solid rgb(7, 185, 7);
                          background-color: rgb(250, 249, 249);
                        "
                        v-if="isReport == 'res'"
                      >
                        <v-layout wrap row class="ma-3 mr-3 ml-3">
                          <v-flex
                            xs12
                            style="
                              border: 1px solid rgb(182, 184, 182);
                              background-color: white;
                            "
                            class="pl-4 pa-1 pr-4"
                          >
                            <v-layout row wrap class="pb-3">
                              <v-flex xs3>
                                <span class="black--text">From Date</span><br />
                                <input
                                  type="text"
                                  size="28"
                                  style="
                                    border: 1px solid rgb(182, 184, 182);
                                    background-color: white;
                                  "
                                  class="pl-2"
                                  v-model="date1res"
                                  disabled
                                />

                                <span
                                  class="grey lighten-3 pa-1"
                                  style="
                                    border-top-right-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                  "
                                >
                                  <v-icon
                                    class="green--text"
                                    style="opacity: 0.5"
                                    >date_range</v-icon
                                  >
                                  <v-icon
                                    class="green--text"
                                    style="opacity: 0.5"
                                    >access_time</v-icon
                                  >
                                </span>
                              </v-flex>

                              <v-flex xs3 class="ml-3">
                                <span class="black--text">To Date</span><br />
                                <input
                                  type="text"
                                  size="28"
                                  style="
                                    border: 1px solid rgb(182, 184, 182);
                                    background-color: white;
                                  "
                                  class="pl-2"
                                  v-model="date2res"
                                  disabled
                                />
                                <span
                                  class="grey lighten-3 pa-1"
                                  style="
                                    border-top-right-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                  "
                                >
                                  <v-icon
                                    class="green--text"
                                    style="opacity: 0.5"
                                    >date_range</v-icon
                                  >
                                  <v-icon
                                    class="green--text"
                                    style="opacity: 0.5"
                                    >access_time</v-icon
                                  >
                                </span>
                              </v-flex>

                              <v-flex xs3 class="ml-3">
                                <span class="black--text">Event No</span><br />
                                <input
                                  type="text"
                                  size="30"
                                  style="
                                    border: 1px solid rgb(182, 184, 182);
                                    background-color: white;
                                  "
                                  class="pl-2"
                                  v-model="evno"
                                  disabled
                                />
                              </v-flex>

                              <v-flex xs6 class="pr-4">
                                <v-btn
                                  small
                                  text
                                  class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                  style="
                                    border: 1px solid rgb(100, 165, 100);

                                    font-size: 11px;
                                  "
                                >
                                  <v-icon class="green--text">refresh </v-icon>
                                  Refresh
                                </v-btn>

                                <v-btn
                                  small
                                  text
                                  class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                  style="
                                    border: 1px solid rgb(100, 165, 100);

                                    font-size: 11px;
                                  "
                                >
                                  <v-icon small class="green--text"
                                    >crop_portrait
                                  </v-icon>
                                  Export to Excel
                                </v-btn>
                              </v-flex>
                              <v-flex xs6 class="pr-4 text-right">
                                <v-btn
                                  @click="getLastTwentyGames('keno')"
                                  small
                                  text
                                  class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                  style="
                                    border: 1px solid rgb(100, 165, 100);

                                    font-size: 11px;
                                  "
                                >
                                  Last 20 Keno
                                </v-btn>

                                <v-btn
                                  @click="getLastTwentyGames('spin')"
                                  right
                                  small
                                  text
                                  class="mr-3 text-capitalize mt-3 pl-1 pr-1"
                                  style="
                                    border: 1px solid rgb(100, 165, 100);

                                    font-size: 11px;
                                  "
                                >
                                  Last 20 Spin
                                </v-btn>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap class="mb-15 pa-0 pt-0 pl-5">
                              <table
                                style="
                                  width: 100%;
                                  font-size: 12px;
                                  border-collapse: collapse;
                                "
                                class="ma-0 mt-0"
                                id="hover"
                              >
                                <tr id="resuid">
                                  <th style="border: 0px" class="pa-3 pr-10">
                                    Game
                                  </th>
                                  <th style="border: 0px" class="pa-3 pr-0">
                                    <v-icon small class="green--text ml-0"
                                      >filter_alt</v-icon
                                    >
                                    Feed ID
                                  </th>
                                  <th style="border: 0px" class="pa-3 pr-4">
                                    <v-icon small class="green--text ml-0"
                                      >filter_alt</v-icon
                                    >
                                    Event No
                                  </th>
                                  <th style="border: 0px" class="pa-3 pr-4">
                                    Date
                                    <v-icon small class="green--text ml-0"
                                      >south_sharp</v-icon
                                    >
                                  </th>

                                  <th
                                    style="border: 0px"
                                    class="pa-3 pr-0"
                                  ></th>
                                  <th
                                    style="border: 0px"
                                    class="pa-3 pr-4"
                                  ></th>
                                </tr>
                                <tr
                                  v-for="(dailyReport, i) in 15"
                                  :key="i"
                                  id="resuid"
                                >
                                  <td
                                    class="pa-3 pr-10"
                                    style="
                                      border-right: 1px solid rgb(221, 219, 219);
                                    "
                                  >
                                    <span v-if="i % 2 == 0">
                                      {{ "Keno" }}
                                    </span>
                                    <span v-else>
                                      {{ "Spin" }}
                                    </span>
                                  </td>
                                  <td
                                    class="pa-3 pr-5"
                                    style="
                                      border-right: 1px solid rgb(221, 219, 219);
                                    "
                                  >
                                    <span v-if="i % 2 == 0">
                                      {{ 425366 + i }}
                                    </span>
                                    <span v-else>
                                      {{ 32325 + i }}
                                    </span>
                                  </td>
                                  <td
                                    class="pa-3 pr-5"
                                    style="
                                      border-right: 1px solid rgb(221, 219, 219);
                                    "
                                  >
                                    <span v-if="i % 2 == 0">
                                      {{
                                        parseInt(currentGame[0].game_number) -
                                        i -
                                        1
                                      }}
                                    </span>
                                    <span v-else>
                                      {{
                                        parseInt(
                                          spinCurrentGame[0].game_number
                                        ) -
                                        i -
                                        1
                                      }}
                                    </span>
                                  </td>
                                  <td
                                    class="pa-3 pr-7"
                                    style="
                                      border-right: 1px solid rgb(221, 219, 219);
                                    "
                                  >
                                    <span v-if="i % 2 == 0">
                                      {{
                                        new Date(
                                          Number(currentGame[0].game_date)
                                        ) | formatDate
                                      }}
                                    </span>
                                    <span v-else>
                                      {{
                                        new Date(
                                          Number(spinCurrentGame[0].game_date)
                                        ) | formatDate
                                      }}
                                    </span>
                                  </td>

                                  <td
                                    class="pa-3 pr-0 pl-1"
                                    style="
                                      border-right: 1px solid rgb(221, 219, 219);
                                    "
                                  >
                                    <a
                                      class="ml-1 pt-2 pb-2 pr-4 pl-4"
                                      style="
                                        border-radius: 3px;
                                        border: 1px solid green;
                                        background-color: white;
                                      "
                                    >
                                      <v-icon class="green--text"
                                        >visibility</v-icon
                                      >
                                    </a>
                                  </td>
                                  <td class="pa-3 pr-5 pl-0">
                                    <a
                                      class="ml-1 pt-2 pb-2 pr-4 pl-4"
                                      style="
                                        border-radius: 3px;
                                        border: 1px solid green;
                                        background-color: white;
                                      "
                                    >
                                      <v-icon class="green--text">print</v-icon>
                                    </a>
                                  </td>

                                  <hr />
                                </tr>
                              </table>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <!-- <v-flex xs4 v-if="cancelLoading">
                        <div class="text-center">
                          <v-progress-circular
                            :size="70"
                            :width="2"
                            color="grey"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      -->
                    </v-layout>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="spinPrintDialog"
              persistent
              max-width="600"
              v-if="spinCurrentGame.length > 0 && brachData != ''"
            >
              <v-card class="">
                <v-card-text
                  class=""
                  ref="spinPrintable"
                  v-if="brachData.ticket_layout == 'Layout 2'"
                  id="layoutWaterMark"
                  style="font-family: Arial"
                >
                  <div style="margin-left: 0px; color: black">
                    <!-- {{ commonFeatures[0].brand_name }} K E N O -->

                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 11px;
                          text-align: right;
                        "
                      >
                        <span>
                          {{ spinUserNumber }}
                        </span>
                        <br />
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                        <br />

                        <span>
                          {{
                            copyMessage == "copy"
                              ? new Date(Number(gameDateToCopy))
                              : new Date(Number(spinCurrentGame[0].game_date))
                                | formatDate
                          }}

                          (UTC +3)/(#{{
                            copyMessage == "copy"
                              ? "Copy"
                              : $store.state.spinTicketNumber
                          }})
                        </span>
                      </div>
                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="30"
                          width="60"
                          v-if="brachData.logo != 'Normal' && brachData != ''"
                          :src="require(`../assets/${brachData.logo}.png`)"
                        />
                      </span>
                    </div>
                    <p
                      style="
                        clear: left;
                        float: left;
                        font-size: 11px;
                        text-align: left;
                      "
                    >
                      Spin And Win
                      {{ new Date(spinGameDate) | formatDate }} #{{
                        copyMessage == "copy"
                          ? gameNumberToCopy
                          : spinCurrentGame[0].game_number
                      }}
                    </p>
                    <!-- <hr style="border-top: 1px dashed" /> -->

                    <table
                      style="
                        width: 100%;
                        text-align: left;
                        padding-top: 0;
                        margin-top: 0;
                        font-size: 11px;
                      "
                    >
                      <tr>
                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          Choosen
                        </th>
                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          | &nbsp;&nbsp;Odds
                        </th>
                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          | &nbsp;&nbsp;Stake
                        </th>

                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          | &nbsp;&nbsp;Win
                        </th>
                      </tr>

                      <span
                        v-for="(selectedNumber, i) in selectedSpinNumbers"
                        :key="i + 1000"
                      >
                        <span>
                          <tr>
                            <td style="padding-top: 0px">
                              {{ selectedNumber }}
                            </td>
                            <td style="padding-top: 0px">
                              <!-- ====== -->
                              <span v-if="selectedNumber.includes('Neighbors')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].neighbors
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('&')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].high_low_color
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Donzen')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].dozen
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Sector')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].sector
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Final')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].final
                                }}
                              </span>
                              <span
                                v-if="
                                  selectedNumber == 'Red' ||
                                  selectedNumber == 'Black' ||
                                  selectedNumber == 'High' ||
                                  selectedNumber == 'Low' ||
                                  selectedNumber.includes('Even') ||
                                  selectedNumber.includes('Odd')
                                "
                              >
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].high_low
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Green')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].number
                                }}
                              </span>
                              <span
                                v-if="
                                  selectedNumber.includes(',') &&
                                  selectedNumber.split(',').length == 2
                                "
                              >
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].number / 2
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Twins')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].twins
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('|')">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].mirror
                                }}
                              </span>
                              <span v-if="!isNaN(selectedNumber)">
                                | &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].number
                                }}
                              </span>
                            </td>
                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{ spinInputMoney[i] }}
                            </td>

                            <!-- ===== -->
                            <td style="padding-top: 0px">
                              <span v-if="selectedNumber.includes('Neighbors')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].neighbors
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('&')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].high_low_color
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Donzen')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].dozen
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Sector')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].sector
                                }}
                              </span>
                              <span v-if="selectedNumber.includes('Final')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].final
                                }}
                              </span>

                              <span
                                v-if="
                                  selectedNumber == 'Red' ||
                                  selectedNumber == 'Black' ||
                                  selectedNumber == 'High' ||
                                  selectedNumber == 'Low' ||
                                  selectedNumber.includes('Even') ||
                                  selectedNumber.includes('Odd')
                                "
                              >
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].high_low
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Green')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].number
                                }}
                              </span>
                              <span
                                v-if="
                                  selectedNumber.includes(',') &&
                                  selectedNumber.split(',').length == 2
                                "
                              >
                                | &nbsp;&nbsp;{{
                                  (parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].number) /
                                  2
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('Twins')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].twins
                                }}
                              </span>

                              <span v-if="selectedNumber.includes('|')">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].mirror
                                }}
                              </span>

                              <span v-if="!isNaN(selectedNumber)">
                                | &nbsp;&nbsp;{{
                                  parseFloat(spinInputMoney[i]) *
                                  $store.state.spinOdds[0].number
                                }}
                              </span>
                            </td>
                          </tr>
                        </span>
                      </span>
                    </table>
                    <!-- <div style="border-style: ridge">
                      <table style="width: 100%">
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: center;
                            "
                          >
                            {{ spinTotalInputMoney }}
                            <span style="font-weight: normal; font-size: 14px"
                              >Birr</span
                            >
                          </td>
                        </tr>

                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Possible Win
                          </td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="
                              font-weight: bold;                              padding-top: 3px;
                              text-align: center;
                            "
                          >
                            {{ spinTotalPossibleWin }}
                            <span style="font-weight: normal; font-size: 14px"
                              >Birr</span
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div
                    style="
                      text-align: center;
                      font-size: 11px;
                      margin-top: 2px;
                    "
                  >
                    Valid Until:
                    {{
                      new Date(spinGameDate + 48 * 60 * 60 * 1000)
                        | formatDate
                    }}
                  </div>
                  <div style="text-align: center">
                    <barcode :value="spinUserNumber" height="70px">
                      Some thing went wrong.
                    </barcode>
                  </div> -->

                    <table
                      style="width: 100%; font-size: 11px; margin-top: 5px"
                    >
                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Total Stake
                        </td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: right;
                          "
                        >
                          &nbsp;&nbsp;&nbsp;
                          <span style="font-size: 11px">Br </span
                          >{{ spinTotalInputMoney.toFixed(2) }}
                        </td>
                      </tr>
                    </table>
                    <div
                      style="
                        border: 1px solid !important;
                        border-collapse: collapse !important;
                        border-radius: 2px !important;

                        overflow: hidden;
                      "
                    >
                      <table style="width: 100%; font-size: 11px; width: 100%">
                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Min Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ spinTotalInputMoney.toFixed(2) }}
                          </td>
                        </tr>

                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Max Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ spinTotalPossibleWin.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 10px;
                      padding-top: 3px;
                    "
                  >
                    Valid Until:
                    {{
                      new Date(spinGameDate + 48 * 60 * 60 * 1000) | formatDate
                    }}
                  </div>
                  <table
                    style="font-size: 11px; text-align: center; width: 100%"
                  >
                    <tr>
                      <td
                        style="
                          padding-left: 3px;
                          padding-right: 3px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            padding-left: 25px;

                            text-align: center;
                          "
                        >
                          <barcode
                            :display-value="false"
                            :value="spinUserNumber"
                            height="50px"
                            margin="0px"
                          >
                            Some thing went wrong.
                          </barcode>
                        </div>
                        <div style="text-align: center; padding-right: 20px">
                          <span
                            >*
                            {{
                              spinUserNumber
                                .toString()
                                .split("")
                                .toString()
                                .replaceAll(",", " &nbsp;&nbsp; ")
                            }}
                            *</span
                          >
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div
                    v-if="commonFeatures.length > 0"
                    style="text-align: center"
                  >
                    <span
                      style="text-align: center; font-weight: bold"
                      v-if="commonFeatures.length > 0"
                    >
                      {{ commonFeatures[0].phone }}

                      {{ commonFeatures[0].others }}

                      {{ commonFeatures[0].brand_name }}
                    </span>
                  </div>
                </v-card-text>
                <v-card-text
                  class=""
                  ref="spinPrintable"
                  v-if="brachData.ticket_layout == 'Layout 1'"
                  id="layoutWaterMark"
                  style="font-family: Arial"
                >
                  <div style="margin-left: 0px; color: black">
                    <!-- {{ commonFeatures[0].brand_name }} K E N O -->

                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 11px;
                          text-align: right;
                        "
                      >
                        <span>
                          {{ spinUserNumber }}
                        </span>
                        <br />
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                        <br />

                        <span>
                          {{
                            copyMessage == "copy"
                              ? new Date(Number(gameDateToCopy))
                              : new Date(Number(spinCurrentGame[0].game_date))
                                | formatDate
                          }}

                          (UTC +3)/(#{{
                            copyMessage == "copy"
                              ? "Copy"
                              : $store.state.spinTicketNumber
                          }})
                        </span>
                      </div>
                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="30"
                          width="60"
                          v-if="brachData.logo != 'Normal' && brachData != ''"
                          :src="require(`../assets/${brachData.logo}.png`)"
                        />
                      </span>
                    </div>

                    <!-- <hr style="border-top: 1px dashed" /> -->

                    <table
                      style="
                        width: 100%;
                        text-align: left;
                        padding-top: 0;
                        margin-top: 0;
                        font-size: 11px;
                      "
                    >
                      <span
                        v-for="(selectedNumber, i) in selectedSpinNumbers"
                        :key="i + 1000"
                      >
                        <span>
                          <tr>
                            <td style="padding-top: 4px; font-size: 11px">
                              <span v-if="selectedNumber.includes('Neighbors')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Neighbors</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp; &nbsp;
                                {{ selectedNumber.split("(")[1].split(")")[0] }}
                                &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].neighbors
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('&')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >High/Low And Color</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].high_low_color
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('Donzen')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Dozens</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].dozen
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('Sector')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Sector (Colour)</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;
                                <span v-if="selectedNumber == 'Sector A'"
                                  >32/15/19/4/21/2</span
                                >
                                <span v-if="selectedNumber == 'Sector B'"
                                  >25/17/34/6/27/13</span
                                >
                                <span v-if="selectedNumber == 'Sector C'"
                                  >36/11/30/8/23/10</span
                                >
                                <span v-if="selectedNumber == 'Sector D'"
                                  >5/24/16/33/1/20</span
                                >
                                <span v-if="selectedNumber == 'Sector E'"
                                  >14/31/9/22/18/29</span
                                >
                                <span v-if="selectedNumber == 'Sector F'"
                                  >7/28/12/35/3/26</span
                                >
                                &nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].sector
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('Final')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Final</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].final
                                }}.00
                              </span>
                              <span
                                v-if="
                                  selectedNumber == 'Red' ||
                                  selectedNumber == 'Black'
                                "
                              >
                                <span style="font-weight: bold; font-size: 11px"
                                  >Color</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].high_low
                                }}.00
                              </span>
                              <span
                                v-if="
                                  selectedNumber.includes('Even') ||
                                  selectedNumber.includes('Odd')
                                "
                              >
                                <span style="font-weight: bold; font-size: 11px"
                                  >Even/Odd</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].high_low
                                }}.00
                              </span>

                              <span
                                v-if="
                                  selectedNumber == 'High' ||
                                  selectedNumber == 'Low'
                                "
                              >
                                <span style="font-weight: bold; font-size: 11px"
                                  >High/Low</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].high_low
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('Green')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >0 Green</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].number
                                }}.00
                              </span>
                              <span
                                v-if="
                                  selectedNumber.includes(',') &&
                                  selectedNumber.split(',').length == 2
                                "
                              >
                                <span style="font-weight: bold; font-size: 11px"
                                  >Win</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].number / 2
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('Twins')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Twins</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].twins
                                }}.00
                              </span>

                              <span v-if="selectedNumber.includes('|')">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Mirror</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].mirror
                                }}.00
                              </span>
                              <span v-if="!isNaN(selectedNumber)">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Win</span
                                >
                                <br />
                                Spin And Win
                                {{ new Date(spinGameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : spinCurrentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                  $store.state.spinOdds[0].number
                                }}.00
                              </span>
                            </td>

                            <td
                              style="
                                text-align: right;
                                font-weight: bold;
                                padding-top: 0px;
                              "
                            >
                              <span style="font-size: 11px">Br </span
                              >{{ spinInputMoney[i] }}.00 <span><br /></span
                              ><span
                                ><br />
                                <br
                              /></span>
                            </td>

                            <!-- ===== -->
                          </tr>
                        </span>
                      </span>
                    </table>
                    <!-- <div style="border-style: ridge">
                    <table style="width: 100%">
                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Total Stake
                        </td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: center;
                          "
                        >
                          {{ spinTotalInputMoney }}
                          <span style="font-weight: normal; font-size: 14px"
                            >Birr</span
                          >
                        </td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Possible Win
                        </td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: center;
                          "
                        >
                          {{ spinTotalPossibleWin }}
                          <span style="font-weight: normal; font-size: 14px"
                            >Birr</span
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div
                  style="
                    text-align: center;
                    font-size: 11px;
                    margin-top: 2px;
                  "
                >
                  Valid Until:
                  {{
                    new Date(spinGameDate + 48 * 60 * 60 * 1000)
                      | formatDate
                  }}
                </div>
                <div style="text-align: center">
                  <barcode :value="spinUserNumber" height="70px">
                    Some thing went wrong.
                  </barcode>
                </div> -->

                    <table
                      style="width: 100%; font-size: 11px; margin-top: 5px"
                    >
                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Total Stake
                        </td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: right;
                          "
                        >
                          &nbsp;&nbsp;&nbsp;
                          <span style="font-size: 11px">Br </span
                          >{{ spinTotalInputMoney.toFixed(2) }}
                        </td>
                      </tr>
                    </table>
                    <div
                      style="
                        border: 1px solid !important;
                        border-collapse: collapse !important;
                        border-radius: 2px !important;

                        overflow: hidden;
                      "
                    >
                      <table style="width: 100%; font-size: 11px; width: 100%">
                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Min Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ spinTotalInputMoney.toFixed(2) }}
                          </td>
                        </tr>

                        <tr style="padding-top: 0px">
                          <td style="font-weight: bold; padding-top: 0px">
                            Max Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ spinTotalPossibleWin.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 10px;
                      padding-top: 3px;
                    "
                  >
                    Valid Until:
                    {{
                      new Date(spinGameDate + 48 * 60 * 60 * 1000) | formatDate
                    }}
                  </div>
                  <table
                    style="font-size: 11px; text-align: center; width: 100%"
                  >
                    <tr>
                      <td
                        style="
                          padding-left: 3px;
                          padding-right: 3px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            padding-left: 25px;

                            text-align: center;
                          "
                        >
                          <barcode
                            :display-value="false"
                            :value="spinUserNumber"
                            height="50px"
                            margin="0px"
                          >
                            Some thing went wrong.
                          </barcode>
                        </div>
                        <div style="text-align: center; padding-right: 20px">
                          <span
                            >*
                            {{
                              spinUserNumber
                                .toString()
                                .split("")
                                .toString()
                                .replaceAll(",", " &nbsp;&nbsp; ")
                            }}
                            *</span
                          >
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div
                    v-if="commonFeatures.length > 0"
                    style="text-align: center"
                  >
                    <span
                      style="text-align: center; font-weight: bold"
                      v-if="commonFeatures.length > 0"
                    >
                      {{ commonFeatures[0].phone }}

                      {{ commonFeatures[0].others }}

                      {{ commonFeatures[0].brand_name }}
                    </span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" class="text-capitalize" text>
                    Waiting...
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="spinPrintDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="printDialog"
              persistent
              max-width="450"
              v-if="currentGame.length > 0 && brachData != ''"
            >
              <v-card>
                <v-card-text
                  v-if="brachData.ticket_layout == 'Layout 2'"
                  ref="printable"
                  style="font-family: Arial"
                >
                  <!-- <img
                    height="50%"
                      contain
                      width="100%"
                      src="../assets/Keno-01.png"
                      alt=""
                    /> -->
                  <div
                    style="margin-left: 0px; color: black"
                    class="layoutWaterMark"
                  >
                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 11px;
                          text-align: right;
                        "
                      >
                        <span>
                          {{ userNumber }}
                        </span>
                        <br />
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                        <br />

                        <span>
                          {{
                            copyMessage == "copy"
                              ? new Date(Number(gameDateToCopy))
                              : new Date(Number(currentGame[0].game_date))
                                | formatDate
                          }}

                          (UTC +3)/(#{{
                            copyMessage == "copy"
                              ? "Copy"
                              : $store.state.kenoTicketNumber
                          }})
                        </span>
                      </div>

                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="30"
                          width="60"
                          v-if="brachData.logo != 'Normal' && brachData != ''"
                          :src="require(`../assets/${brachData.logo}.png`)"
                        />
                      </span>
                    </div>
                    <p
                      style="
                        clear: left;
                        float: left;
                        font-size: 11px;
                        text-align: left;
                      "
                    >
                      Keno {{ new Date(gameDate) | formatDate }} #{{
                        copyMessage == "copy"
                          ? gameNumberToCopy
                          : currentGame[0].game_number
                      }}
                    </p>

                    <table
                      style="
                        width: 100%;
                        text-align: left;
                        margin-top: 0;
                        font-size: 11px;
                      "
                    >
                      <tr>
                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          Choosen
                        </th>
                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          | &nbsp;&nbsp;Odds
                        </th>
                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          | &nbsp;&nbsp;Stake
                        </th>

                        <th
                          style="
                            padding-bottom: 4px;
                            border-bottom: 1px solid grey;
                            font-weight: normal;
                          "
                        >
                          | &nbsp;&nbsp;Win
                        </th>
                      </tr>

                      <span
                        v-for="(selectedNumber, i) in selectedNumbers"
                        :key="i + 1000"
                      >
                        <span
                          v-for="odds in $store.state.odds"
                          :key="odds.choosen_length"
                        >
                          <span
                            v-if="odds.choosen_length == selectedNumber.length"
                          >
                            <tr>
                              <td style="padding-top: 0px">
                                {{ selectedNumber.toString() }}
                              </td>
                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{
                                  parseFloat(odds.odd_value.split(",")[0]) / 10
                                }}
                              </td>
                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{ numberInputMoney[i] }}
                              </td>

                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{
                                  (parseFloat(numberInputMoney[i]) *
                                    parseFloat(odds.odd_value.split(",")[0])) /
                                  10
                                }}
                              </td>
                            </tr>
                          </span>
                        </span>
                      </span>

                      <span
                        style="padding-top: 0px"
                        v-for="(other, i) in selectedEvenOddAndHighLow"
                        :key="i + 10001"
                      >
                        <span
                          v-for="odds in $store.state.odds"
                          :key="odds.choosen_length"
                        >
                          <span
                            v-if="
                              odds.choosen_length.toLowerCase() ==
                              other.toLowerCase()
                            "
                          >
                            <tr>
                              <td style="padding-top: 0px">
                                {{ other }}
                              </td>
                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{ odds.odd_value }}
                              </td>
                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{ evenOddInputMoney[i] }}
                              </td>

                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{
                                  parseFloat(evenOddInputMoney[i]) *
                                  parseFloat(odds.odd_value)
                                }}
                              </td>
                            </tr>
                          </span>
                        </span>
                      </span>
                    </table>
                    <table
                      style="width: 100%; font-size: 11px; margin-top: 5px"
                    >
                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Total Stake
                        </td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: right;
                          "
                        >
                          &nbsp;&nbsp;&nbsp;
                          <span style="font-size: 11px">Br </span
                          >{{ totalInputMoney.toFixed(2) }}
                        </td>
                      </tr>
                    </table>
                    <div
                      style="
                        border: 1px solid !important;
                        border-collapse: collapse !important;
                        border-radius: 2px !important;

                        overflow: hidden;
                      "
                    >
                      <table style="width: 100%; font-size: 11px">
                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Min Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ totalInputMoney.toFixed(2) }}
                          </td>
                        </tr>

                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Max Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ totalPossibleWin.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 10px;
                      padding-top: 3px;
                    "
                  >
                    Valid Until:
                    {{ new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate }}
                  </div>
                  <table
                    style="font-size: 11px; text-align: center; width: 100%"
                  >
                    <tr>
                      <td
                        style="
                          padding-left: 3px;
                          padding-right: 3px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            padding-left: 25px;

                            text-align: center;
                          "
                        >
                          <barcode
                            :display-value="false"
                            :value="userNumber"
                            height="50px"
                            margin="0px"
                          >
                            Some thing went wrong.
                          </barcode>
                        </div>
                        <div style="text-align: center; padding-right: 20px">
                          <span
                            >*
                            {{
                              userNumber
                                .toString()
                                .split("")
                                .toString()
                                .replaceAll(",", " &nbsp;&nbsp; ")
                            }}
                            *</span
                          >
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div
                    v-if="commonFeatures.length > 0"
                    style="text-align: center"
                  >
                    <span
                      style="text-align: center; font-weight: bold"
                      v-if="commonFeatures.length > 0"
                    >
                      {{ commonFeatures[0].phone }}

                      {{ commonFeatures[0].others }}

                      {{ commonFeatures[0].brand_name }}
                    </span>
                  </div>
                </v-card-text>

                <v-card-text
                  v-if="brachData.ticket_layout == 'Layout 1'"
                  ref="printable"
                  style="font-family: Arial"
                >
                  <!-- <img
                  height="50%"
                    contain
                    width="100%"
                    src="../assets/Keno-01.png"
                    alt=""
                  /> -->
                  <div
                    style="margin-left: 0px; color: black"
                    class="layoutWaterMark"
                  >
                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 11px;
                          text-align: right;
                        "
                      >
                        <span>
                          {{ userNumber }}
                        </span>
                        <br />
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                        <br />

                        <span>
                          {{
                            copyMessage == "copy"
                              ? new Date(Number(gameDateToCopy))
                              : new Date(Number(currentGame[0].game_date))
                                | formatDate
                          }}

                          (UTC +3)/(#{{
                            copyMessage == "copy"
                              ? "Copy"
                              : $store.state.kenoTicketNumber
                          }})
                        </span>
                      </div>
                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="30"
                          width="60"
                          v-if="brachData.logo != 'Normal' && brachData != ''"
                          :src="require(`../assets/${brachData.logo}.png`)"
                        />
                      </span>
                    </div>

                    <table style="width: 100%; font-size: 11px">
                      <span
                        v-for="(selectedNumber, i) in selectedNumbers"
                        :key="i + 1000"
                      >
                        <span
                          v-for="odds in $store.state.odds"
                          :key="odds.choosen_length"
                        >
                          <span
                            v-if="
                              odds.choosen_length == selectedNumber.length &&
                              numberInputMoney.length == selectedNumbers.length
                            "
                          >
                            <tr>
                              <td style="font-size: 11px; text-align: left">
                                <span style="font-weight: bold; font-size: 11px"
                                  >Win</span
                                >
                                <br />
                                Keno {{ new Date(gameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : currentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{
                                  selectedNumber.toString()
                                }}
                                &nbsp;&nbsp;{{
                                  (
                                    parseFloat(odds.odd_value.split(",")[0]) /
                                    10
                                  ).toFixed(2)
                                }}
                              </td>

                              <td
                                style="
                                  text-align: right;
                                  font-weight: bold;
                                  padding-top: 0px;
                                "
                              >
                                <span style="font-size: 11px">Br </span
                                >{{ numberInputMoney[i] }}.00 <span><br /></span
                                ><span
                                  ><br />
                                  <br
                                /></span>
                              </td>
                            </tr>
                          </span>
                        </span>
                      </span>

                      <span
                        style="padding-top: 2px"
                        v-for="(other, i) in selectedEvenOddAndHighLow"
                        :key="i + 10001"
                      >
                        <span
                          v-for="odds in $store.state.odds"
                          :key="odds.choosen_length"
                        >
                          <span
                            v-if="
                              odds.choosen_length.toLowerCase() ==
                                other.toLowerCase() &&
                              selectedEvenOddAndHighLow.length ==
                                evenOddInputMoney.length
                            "
                          >
                            <tr>
                              <td style="text-align: left; font-size: 11px">
                                <span
                                  v-if="
                                    other == 'TAILS' ||
                                    other == 'HEADS' ||
                                    other == 'EVENS'
                                  "
                                  style="font-weight: bold; font-size: 11px"
                                  >Heads and Tails</span
                                >

                                <br />
                                Keno {{ new Date(gameDate) | formatDate }} #{{
                                  copyMessage == "copy"
                                    ? gameNumberToCopy
                                    : currentGame[0].game_number
                                }}
                                <br />
                                &nbsp;&nbsp;{{ other }}&nbsp;&nbsp;{{
                                  odds.odd_value
                                }}.00
                              </td>

                              <td
                                style="
                                  text-align: right;
                                  font-weight: bold;
                                  padding-top: 0px;
                                "
                              >
                                <span style="font-size: 11px">Br </span
                                >{{ evenOddInputMoney[i] }}.00
                                <span><br /></span><span><br /><br /></span>
                              </td>
                            </tr>
                          </span>
                        </span>
                      </span>
                    </table>
                    <table
                      style="width: 100%; font-size: 11px; margin-top: 5px"
                    >
                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Total Stake
                        </td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td style="font-weight: bold; padding-top: 3px"></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: right;
                          "
                        >
                          &nbsp;&nbsp;&nbsp;
                          <span style="font-size: 11px">Br </span
                          >{{ totalInputMoney.toFixed(2) }}
                        </td>
                      </tr>
                    </table>
                    <div
                      style="
                        border: 1px solid !important;
                        border-collapse: collapse !important;
                        border-radius: 2px !important;

                        overflow: hidden;
                      "
                    >
                      <table style="width: 100%; font-size: 11px">
                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Min Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ totalInputMoney.toFixed(2) }}
                          </td>
                        </tr>

                        <tr>
                          <td style="font-weight: bold; padding-top: 0px">
                            Max Payout (Incl Stake)
                          </td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td style="font-weight: bold; padding-top: 0px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 0px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                              >Br </span
                            >{{ totalPossibleWin.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style="
                      text-align: center;
                      font-size: 10px;
                      padding-top: 3px;
                    "
                  >
                    Valid Until:
                    {{ new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate }}
                  </div>

                  <table
                    style="font-size: 11px; text-align: center; width: 100%"
                  >
                    <tr>
                      <td
                        style="
                          padding-left: 3px;
                          padding-right: 3px;
                          text-align: center;
                        "
                      >
                        <div
                          style="
                            padding-left: 25px;

                            text-align: center;
                          "
                        >
                          <barcode
                            :display-value="false"
                            :value="userNumber"
                            height="50px"
                            margin="0px"
                          >
                            Some thing went wrong.
                          </barcode>
                        </div>
                        <div style="text-align: center; padding-right: 20px">
                          <span
                            >*
                            {{
                              userNumber
                                .toString()
                                .split("")
                                .toString()
                                .replaceAll(",", " &nbsp;&nbsp; ")
                            }}
                            *</span
                          >
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div
                    v-if="commonFeatures.length > 0"
                    style="text-align: center"
                  >
                    <span
                      style="text-align: center; font-weight: bold"
                      v-if="commonFeatures.length > 0"
                    >
                      {{ commonFeatures[0].phone }}

                      {{ commonFeatures[0].others }}

                      {{ commonFeatures[0].brand_name }}
                    </span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" class="text-capitalize" text>
                    Waiting...
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="printDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="logoutDialog" persistent max-width="420">
              <v-card class="pt-5">
                <v-form @submit.prevent="">
                  <v-card-text
                    ref="printReport"
                    style="font-size: 12px; font-family: Arial"
                  >
                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 12px;
                          text-align: right;
                        "
                      >
                        <br />
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                      </div>

                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="40"
                          width="60"
                          v-if="brachData.logo != 'Normal' && brachData != ''"
                          :src="require(`../assets/${brachData.logo}.png`)"
                        />
                      </span>
                    </div>
                    <br />

                    <span>
                      <strong> Log in time:</strong> <br />
                      {{
                        new Date(Number($store.state.cashier.loggin_time))
                          | formatDate
                      }}
                    </span>
                    <br />
                    <span>
                      <strong>Log out time:</strong><br />
                      {{ new Date(Number(Date.now())) | formatDate }} </span
                    ><br />
                    <span>
                      <strong>Location:</strong> <br />
                      {{ currentLocation }}</span
                    ><br />
                    <span>
                      <strong>IP Address:</strong> <br />
                      {{ ipAddress }}</span
                    >
                    <br />
                    <hr style="margin-top: 2px; margin-bottom: 2px" />

                    <table style="width: 100%; font-size: 12px">
                      <tr>
                        <td>
                          <strong>Total Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noTickets + spinNoTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Paid Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noPaidTickets + spinNoPaidTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Cancelled Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp;
                          {{ noCancelledTickets + spinNoCancelledTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Number of Bets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noBets + spinNoBets }}
                        </td>
                      </tr>
                      <tr>
                        <td style="margin-top: 2px; margin-bottom: 2px">
                          <hr />
                        </td>
                        <td style="margin-top: 2px; margin-bottom: 2px">
                          <hr />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{ totalMoneyOnHand + spinTotalMoneyOnHand }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total paid money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            totalMoneyPaid +
                            spinTotalMoneyPaid +
                            paidKenoMoneyFromYesterday +
                            paidSpinMoneyFromYesterday +
                            paidJackpotMoney
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total Jackpot paid money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{ paidJackpotMoney }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total paid from yesterday:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            paidKenoMoneyFromYesterday +
                            paidSpinMoneyFromYesterday
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total money on hand:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            totalMoneyOnHand -
                            totalMoneyPaid -
                            paidKenoMoneyFromYesterday +
                            (spinTotalMoneyOnHand -
                              spinTotalMoneyPaid -
                              paidSpinMoneyFromYesterday) -
                            paidJackpotMoney
                          }}
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      :loading="logoutLoading"
                      class="text-capitalize pink--text"
                      text
                      @click="logout"
                    >
                      print report
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="tmhPopUp" persistent max-width="420">
              <v-card class="pt-5">
                <v-card-title> Total Money on Hand </v-card-title>
                <v-form @submit.prevent="">
                  <v-card-text>
                    <P style="font-size: 18px"
                      >Total money:
                      {{ totalMoneyOnHand + spinTotalMoneyOnHand }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total paid money:
                      {{
                        totalMoneyPaid +
                        spinTotalMoneyPaid +
                        paidKenoMoneyFromYesterday +
                        paidSpinMoneyFromYesterday +
                        paidJackpotMoney
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total Jackpot paid money:
                      {{ paidJackpotMoney }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total money on hand:
                      {{
                        totalMoneyOnHand -
                        totalMoneyPaid -
                        paidKenoMoneyFromYesterday +
                        (spinTotalMoneyOnHand -
                          spinTotalMoneyPaid -
                          paidSpinMoneyFromYesterday) -
                        paidJackpotMoney
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="tmhPopUp = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <v-dialog v-model="unpaidDialog" persistent max-width="600">
              <v-card class="">
                <div
                  class="pa-2 text-left white--text"
                  style="background-color: #37b34a; font-size: 18px"
                >
                  Tickets

                  <a
                    class="text-capitalize grey--text text--lighten-1"
                    style="clear: right; float: right"
                    text
                    @click="unpaidDialog = false"
                  >
                    X
                  </a>
                </div>

                <v-card-text>
                  <v-layout row wrap class="text-center">
                    <v-flex
                      xs6
                      class="pa-2"
                      style="border-right: 1px solid rgb(8, 224, 55)"
                    >
                      <h3 class="black--text text-center">Keno ticket#</h3>
                      <br />

                      <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Paid = {{ kenoPaidList.length }}</u> </span
                          ><br />
                          <span v-for="keno in kenoPaidList" :key="keno"
                            >#{{ keno }} <br />
                          </span>
                        </v-flex>

                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Unpaid = {{ kenoUnpaidList.length }}</u> </span
                          ><br />
                          <span v-for="keno in kenoUnpaidList" :key="keno"
                            >#{{ keno }} <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 class="pa-2">
                      <h3 class="black--text text-center">Spin ticket#</h3>
                      <br />

                      <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Paid = {{ spinPaidList.length }}</u> </span
                          ><br />
                          <span v-for="spin in spinPaidList" :key="spin"
                            >#{{ spin }} <br />
                          </span>
                        </v-flex>

                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Unpaid = {{ spinUnpaidList.length }}</u> </span
                          ><br />
                          <span v-for="spin in spinUnpaidList" :key="spin"
                            >#{{ spin }} <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="last20GameDialog"
              persistent
              max-width="700"
              v-if="lastTwentyGames.length > 0"
            >
              <v-card class="">
                <v-card-text
                  class=""
                  ref="lastGamePrintable"
                  style="font-family: Arial"
                >
                  <div>
                    <div
                      style="
                        clear: right;
                        float: right;
                        font-size: 12px;
                        text-align: right;
                      "
                    >
                      <br />
                      <span>
                        {{ this.brachData.shop_number }}
                      </span>
                      <br />
                      <span>
                        {{ this.$store.state.cashier.cashier_full_name }}
                      </span>
                      <br />

                      <span>
                        {{ new Date(Date.now()) | formatDate }}

                        (UTC +3)
                      </span>
                    </div>

                    <span style="font-weight: bold; margin-left: 0px">
                      <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                      <img
                        height="40"
                        width="60"
                        v-if="brachData.logo != 'Normal' && brachData != ''"
                        :src="require(`../assets/${brachData.logo}.png`)"
                      />
                    </span>
                  </div>

                  <div
                    style="
                      text-align: center;
                      margin-bottom: 2px;
                      font-size: 14px;
                    "
                  >
                    <br /><br />
                    <span style="font-weight: bold; font-size: 16px">
                      Last 20 Draw
                    </span>

                    <hr />
                    <span
                      v-if="
                        lastTwentyGames[0].drawn_numbers.split(',').length > 2
                      "
                    >
                      Game: Keno
                    </span>
                    <span v-else> Game: Spin And Win </span>
                    <br />
                    Draw: ({{ lastTwentyGames[0].game_number }} -
                    {{
                      lastTwentyGames[lastTwentyGames.length - 1].game_number
                    }})
                  </div>

                  <div style="text-align: left; font-size: 12px">
                    <br />

                    <span v-for="(game, i) in lastTwentyGames" :key="i">
                      #{{ game.game_number }}: {{ game.drawn_numbers }}
                      <span v-if="game.drawn_numbers.split(',').length > 2">
                        <br
                      /></span>
                      <span v-else>
                        <span v-if="(i + 1) % 4 != 0">&nbsp;&nbsp;</span>
                      </span>
                    </span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="printLastgame"
                    color="green darken-1"
                    class="text-capitalize"
                    text
                  >
                    Print
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="last20GameDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="helpDialog" persistent max-width="1200px">
              <v-card class="">
                <div
                  class="pa-2 text-left white--text"
                  style="background-color: #37b34a; font-size: 18px"
                >
                  Help

                  <a
                    class="text-capitalize grey--text text--lighten-1"
                    style="clear: right; float: right"
                    text
                    @click="
                      helpDialog = false;
                      wichVideoToPlay = '';
                    "
                  >
                    X
                  </a>
                </div>

                <v-card-text>
                  <v-layout
                    row
                    wrap
                    class="mb-15 pa-1 pt-0"
                    style="font-size: 14px"
                    justify-space-around
                  >
                    <v-flex xs12 class="pt-3">
                      <v-textarea
                        v-model="helpText"
                        :rules="[(v) => !!v || 'Required']"
                        auto-grow
                        outlined
                        rows="4"
                        row-height="15"
                        disabled
                      ></v-textarea>
                      <!-- <v-text-field
                  style="font-size: 11px"
                  v-model="helpText"
                  :rules="[(v) => !!v || 'Required']"
                  label="Write Help"
                  required
                  dense
                  outlined
                  append-icon="search"
                ></v-text-field> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="text-center"
                      v-for="(allVideo, i) in video"
                      :key="i"
                    >
                      <video
                        v-if="allVideo != '' && wichVideoToPlay == allVideo"
                        playsinline
                        style="pointer-events: none"
                        id="video"
                        width="100%"
                        autoplay
                        :src="allVideo"
                      ></video>
                      <a
                        @click="wichVideoToPlay = allVideo"
                        class="mr-3"
                        v-if="allVideo != ''"
                      >
                        Play Video {{ i }}</a
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="spinReportTicketDialog"
              persistent
              max-width="420"
            >
              <v-card class="">
                <div
                  class="pa-2 pr-1 text-left white--text"
                  style="background-color: #37b34a; font-size: 18px"
                >
                  Report ticket

                  <a
                    class="text-capitalize grey--text text--lighten-1"
                    style="clear: right; float: right"
                    text
                    @click="spinReportTicketDialog = false"
                  >
                    X
                  </a>
                </div>
                <v-form @submit.prevent="">
                  <v-card-text class="pl-5 pr-5 pt-5">
                    <P>Scan the Bar code or Enter user Id under the Bar code</P>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinUserIdForReport"
                      :rules="[(v) => !!v || 'Required']"
                      label="User ID or Bar code"
                      required
                      dense
                      outlined
                      hide-details="true"
                      prepend-icon="view_column"
                    ></v-text-field>

                    <v-text-field
                      class="mt-3"
                      hide-details="true"
                      style="font-size: 11px"
                      v-model="spinReportReason"
                      :rules="[(v) => !!v || 'Required']"
                      label="Write a reason"
                      required
                      dense
                      outlined
                      prepend-icon="note"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="spinReportLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      @click="spinReportTicket"
                    >
                      report
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="
                        spinReportTicketDialog = false;
                        spinUserIdForReport = '';
                      "
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="reportTicketDialog" persistent max-width="420">
              <v-card>
                <div
                  class="pa-2 pr-1 text-left white--text"
                  style="background-color: #37b34a; font-size: 18px"
                >
                  Report ticket

                  <a
                    class="text-capitalize grey--text text--lighten-1"
                    style="clear: right; float: right"
                    text
                    @click="reportTicketDialog = false"
                  >
                    X
                  </a>
                </div>

                <v-form @submit.prevent="">
                  <v-card-text class="pl-5 pr-5 pt-5">
                    <P>Scan the Bar code or Enter user Id under the Bar code</P>
                    <v-text-field
                      autofocus
                      style="font-size: 11px"
                      v-model="userIdForReport"
                      :rules="[(v) => !!v || 'Required']"
                      label="User ID or Bar code"
                      required
                      dense
                      outlined
                      hide-details="true"
                      prepend-icon="view_column"
                    ></v-text-field>

                    <v-text-field
                      class="mt-3"
                      hide-details="true"
                      style="font-size: 11px"
                      v-model="reportReason"
                      :rules="[(v) => !!v || 'Required']"
                      label="Write a reason"
                      required
                      dense
                      outlined
                      prepend-icon="note"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="reportLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      @click="reportTicket"
                    >
                      report
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="
                        reportTicketDialog = false;
                        userIdForReport = '';
                      "
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </div>
          <div class="hidden-md-and-up text-center grey--text">
            <p>Not supported in small screens, please open in pc</p>
          </div>
        </div>
      </div>
      <div v-else class="mt-16">
        <p v-if="partnerBalance != ''" class="headline red--text text-center">
          Reachs maximum day, Please contact the admin
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import axios from "axios";

export default {
  components: {
    barcode: VueBarcode,
    HorizontalScroll,
  },

  data() {
    return {
      neighborsList: [],
      cashierOption: false,
      isReport: "re",
      isSummary: "sum",
      date1: "",
      date2: "",
      date1res: "",
      date2res: "",
      evno: "",
      successfullyPlaced: "",
      numPosition: 1009,
      snumPosition: 1009,
      spnumPosition: 1009,
      spinAllNums: [
        { num: "3", cl: "red" },
        { num: "6", cl: "black" },
        { num: "9", cl: "red" },
        { num: "12", cl: "red" },
        { num: "15", cl: "black" },
        { num: "18", cl: "red" },
        { num: "21", cl: "red" },
        { num: "24", cl: "black" },
        { num: "27", cl: "red" },
        { num: "30", cl: "red" },
        { num: "33", cl: "black" },
        { num: "36", cl: "red" },
        { num: "2", cl: "black" },
        { num: "5", cl: "red" },
        { num: "8", cl: "black" },
        { num: "11", cl: "black" },
        { num: "14", cl: "red" },
        { num: "17", cl: "black" },
        { num: "20", cl: "black" },
        { num: "23", cl: "red" },
        { num: "26", cl: "black" },
        { num: "29", cl: "black" },
        { num: "32", cl: "red" },
        { num: "35", cl: "black" },
        { num: "1", cl: "red" },
        { num: "4", cl: "black" },
        { num: "7", cl: "red" },
        { num: "10", cl: "black" },
        { num: "13", cl: "black" },
        { num: "16", cl: "red" },
        { num: "19", cl: "red" },
        { num: "22", cl: "black" },
        { num: "25", cl: "red" },
        { num: "28", cl: "black" },
        { num: "31", cl: "black" },
        { num: "34", cl: "red" },
      ],
      intest: 0,
      spinIntest: 0,
      redeemErrorMessage: "",
      isLoad: false,
      helpDialog: false,
      helpText: "",
      help: [],
      wichVideoToPlay: "",
      whichIcon: "",
      menu: false,
      selectedrepeat: "1",
      selectedquick: " ",
      gameItems: [
        {
          name: "horse",
          img: "DashingDerbyIcon",
          title: "HORSE RACING",
          cap: "DASHING DERBY",
        },
        {
          name: "dog",
          img: "PlatinumHoundsIcon",
          title: "GREYHOUND RACING",
          cap: "PLATINUM HOUNDS",
        },
        {
          name: "car",
          img: "MotorRacingIcon",
          title: "MOTOR RACING",
          cap: "MAXCAR",
        },
        {
          name: "lucky",
          img: "LuckyLoot624Icon",
          title: "LUCKY LOOT",
          cap: "",
        },
        { name: "spin", img: "SpinAndWinIcon", title: "SPIN AND WIN", cap: "" },
        { name: "keno", img: "SmartPlayKenoIcon", title: "KENO", cap: "" },
      ],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      group4: null,
      video: "",
      timePercent: 0,
      timeInSeconds: 0,
      minutes: 2,
      seconds: 40,
      selectedNumbers: [],
      selectedNumberOptionCounter: 0,
      userIDToVerify: "",
      userIDToCancel: "",
      isDuplicated: false,
      printDialog: false,
      verifyDialog: false,
      verifyLoading: false,
      winnersDialog: false,
      cancelDialog: false,
      payLoading: false,
      cancelLoading: false,
      reportTicketDialog: false,
      userIdForReport: "",
      reportReason: "",
      gameDate: "",
      userNumber: "",
      reportLoading: false,
      currentGame: [],
      noTickets: 0,
      noPaidTickets: 0,
      noCancelledTickets: 0,
      noBets: 0,
      verifyUserData: [],
      selectedEvenOddAndHighLow: [],
      isPlaying: false,
      totalWinMoneyForOneUser: 0,
      evenOddInputMoney: [],
      numberInputMoney: [],
      totalMoneyOnHand: 0,
      totalMoneyPaid: 0,
      selectedCustomAmount: 0,
      totalInputMoney: 0,
      totalPossibleWin: 0,
      lastTwoGames: [],
      currentTickets: [],
      paidJackpotMoney: 0,

      //spin
      neighboursPopUp: false,
      twoNumbersPopUp: false,
      spinTimePercent: 0,
      spinTimeInSeconds: 0,
      spinMinutes:3,
      spinSeconds: 20,
      selectedSpinNumbers: [],
      spinUserIDToVerify: "",
      spinUserIDToCancel: "",
      spinIsDuplicated: false,
      spinPrintDialog: false,
      SpinVerifyDialog: false,
      spinVerifyLoading: false,
      spinWinnersDialog: false,
      spinCancelDialog: false,
      spinPayLoading: false,
      spinCancelLoading: false,
      spinReportTicketDialog: false,
      spinUserIdForReport: "",
      spinReportReason: "",
      spinGameDate: "",
      spinUserNumber: "",
      spinReportLoading: false,
      spinCurrentGame: [],
      spinNoTickets: 0,
      spinNoPaidTickets: 0,
      spinNoCancelledTickets: 0,
      spinNoBets: 0,
      spinVerifyUserData: [],
      spinIsPlaying: false,
      spinTotalWinMoneyForOneUser: 0,
      spinInputMoney: [],
      spinTotalMoneyOnHand: 0,
      spinTotalMoneyPaid: 0,
      spinLastTwoGames: [],
      spinNumbers: [],
      brachData: "",
      randomGame: [
        "Red",
        "Black",
        "Even",
        "Odd",
        "High",
        "Low",
        "Twins",
        "0 Green",
        "12|21",
        "13|31",
        "23|32",
        "High & Red",
        "Low & Red",
        "High & Black",
        "Low & Black",
        "Numbers",
        "1st Donzen",
        "2nd Donzen",
        "3rd Donzen",
        "Neighbours",
        "Sector A",
        "Sector B",
        "Sector C",
        "Sector D",
        "Sector E",
        "Sector F",
        "Final 0",
        "Final 1",
        "Final 2",
        "Final 3",
        "Final 4",
        "Final 5",
        "Final 6",
      ],
      spinSelectedCustomAmount: 0,

      spinTotalInputMoney: 0,
      spinTotalPossibleWin: 0,
      twoNumbers: [],
      //common
      partnerBalance: "",
      logoutLoading: false,
      logoutDialog: false,
      commonFeatures: [],
      tmhPopUp: false,
      customAmount: [250, 200, 150, 100, 50, 40, 30, 25, 20, 15, 10],
      currentLocation: "",
      ipAddress: "",
      rules: [(value) => !!value || "Required."],
      whichToShow: "keno",
      payableList: [],
      paidSpinMoneyFromYesterday: 0,
      paidKenoMoneyFromYesterday: 0,
      unpaidLoading: false,
      kenoUnpaidList: [],
      spinUnpaidList: [],

      kenoPaidList: [],
      spinPaidList: [],
      todaysWork: [],
      spinTodaysWork: [],
      unpaidDialog: false,
      kenoCurrentMousePosition: "",
      spinCurrentMousePosition: "",
      kenoevenoddCurrentMousePosition: "",
      inWhichToAddAmount: "",
      day_block: "",
      gameLoading: false,
      last20GameDialog: false,
      lastTwentyGames: [],
      gameNumberToCopy: "",
      gameDateToCopy: "",
      copyMessage: "",
      isAllowedToCopy: "",
      recietDialog: false,
      recietData: [],
      randomSring: "",
      selectedPrint: [],
      selectedWinMoney: 0,

      spinSelectedPrint: [],
      spinSelectedWinMoney: 0,
      srecietDialog: false,
      ticketToDelete: [],
      stakeToCancel: 0,
      crecietDialog: false,
      ccancelDialog: false,

      csrecietDialog: false,
      sticketToDelete: [],
      sstakeToCancel: 0,
      screcietDialog: false,
      sccancelDialog: false,
      isRedeemPrint: false,
      isCancelPrint: false,
      repoDate: "",
      choosenDate: "Last 7 days",
      dailyReport: [],
      dailyWork: [],
      allDailyWork: [],
      spinDailyReport: [],
      spinDailyWork: [],
      spinChoosenDate: "Last 7 days",
      spinChoosedDateRange: "",
      choosedDateRange: "",
      successfullyPlaced: "",
      whichRecall: "keno",
    };
  },

  methods: {
    getIcrease() {
      for (let i = 0; i < this.numberInputMoney.length; i++) {
        this.numberInputMoney[i] = this.intest;
      }

      for (let i = 0; i < this.evenOddInputMoney.length; i++) {
        this.evenOddInputMoney[i] = this.intest;
      }
    },

    spinGetIcrease() {
      for (let i = 0; i < this.spinInputMoney.length; i++) {
        this.spinInputMoney[i] = this.spinIntest;
      }
    },

    watchData() {
      var totalMoney = 0;
      this.totalPossibleWin = 0;
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        if (this.numberInputMoney[i] != undefined)
          totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);
        for (let j = 0; j < this.$store.state.odds.length; j++) {
          if (
            this.$store.state.odds[j].choosen_length ==
            this.selectedNumbers[i].length
          ) {
            var inp =
              this.numberInputMoney[i] != undefined
                ? parseFloat(this.numberInputMoney[i])
                : 0;
            this.totalPossibleWin =
              this.totalPossibleWin +
              (parseFloat(inp) *
                parseFloat(this.$store.state.odds[j].odd_value.split(",")[0])) /
                10;
          }
        }
      }

      for (let j = 0; j < this.selectedEvenOddAndHighLow.length; j++) {
        if (this.evenOddInputMoney[j] != undefined)
          totalMoney = totalMoney + parseFloat(this.evenOddInputMoney[j]);
        for (let k = 0; k < this.$store.state.odds.length; k++) {
          if (
            this.$store.state.odds[k].choosen_length.toLowerCase() ==
            this.selectedEvenOddAndHighLow[j].toLowerCase()
          ) {
            var inpo =
              this.evenOddInputMoney[j] != undefined
                ? parseFloat(this.evenOddInputMoney[j])
                : 0;
            this.totalPossibleWin =
              this.totalPossibleWin +
              parseFloat(inpo) *
                parseFloat(this.$store.state.odds[k].odd_value);
          }
        }
      }
      this.totalInputMoney = totalMoney;
    },

    async getDailyWorks(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "Last week") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.choosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.choosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
              getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      is_cashout
                      branch_id
                      cashier_id
                      partner_id
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });

        this.dailyWork = dailyResult.data.data.getMoneyOnHands;

        var allDays = [];
        this.dailyReport = [];
        var rowOut2 = this.$store.state.odds;

        if (this.choosenDate == "Last 7 days") {
          for (let t = 0; t < this.dailyWork.length; t++) {
            // var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();
            var day = this.formatDate(
              new Date(Number(this.dailyWork[t].ticket_date))
            );

            if (allDays.indexOf(day) === -1) allDays.push(day);
          }
          for (let d = 0; d < allDays.length; d++) {
            var totalMoneyForEach = 0;

            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var date = "";
            var winnersNoBets = 0;
            var winnersMoney = 0;
            var winnersNoTickets = 0;

            for (let t = 0; t < this.dailyWork.length; t++) {
              var winnersTicketCounter = 0;
              // var day = new Date(
              //   Number(this.dailyWork[t].ticket_date)
              // ).getDay();

              var day = this.formatDate(
                new Date(Number(this.dailyWork[t].ticket_date))
              );

              if (day == allDays[d]) {
                var month =
                  this.monthNames[
                    new Date(Number(this.dailyWork[t].ticket_date)).getMonth()
                  ];

                var eachSplitedDate = this.formatDate(
                  new Date(Number(this.dailyWork[t].ticket_date))
                ).split("-");

                date =
                  month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                var tickets = this.dailyWork[t].choosen_numbers.split(":");
                var others = this.dailyWork[t].others.split(":");

                var drownNumbers =
                  this.dailyWork[t].getGameForShifts[0].drawn_numbers != ""
                    ? this.dailyWork[t].getGameForShifts[0].drawn_numbers.split(
                        ","
                      )
                    : [];
                var evenOdd = this.dailyWork[t].getGameForShifts[0].even_odd;
                var highLow = this.dailyWork[t].getGameForShifts[0].high_low;

                if (this.dailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;
                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[1]);

                      var eachNumber = tickets[j].split("*")[0].split(",");
                      var eachAmount = parseFloat(tickets[j].split("*")[1]);

                      var matchCount = 0;
                      for (let k = 0; k < eachNumber.length; k++) {
                        for (let m = 0; m < drownNumbers.length; m++) {
                          if (drownNumbers[m] == eachNumber[k].trim()) {
                            matchCount++;
                          }
                        }
                      }

                      for (let x = 0; x < rowOut2.length; x++) {
                        if (
                          parseInt(rowOut2[x].choosen_length) ==
                          eachNumber.length
                        ) {
                          var hits = rowOut2[x].hits.split(",");
                          var possibleWinMoney =
                            rowOut2[x].odd_value.split(",");

                          for (let y = 0; y < hits.length; y++) {
                            if (matchCount == parseInt(hits[y].trim())) {
                              winnersNoBets = winnersNoBets + 1;
                              winnersTicketCounter++;
                              winnersMoney =
                                winnersMoney +
                                (parseFloat(possibleWinMoney[y].trim()) / 10) *
                                  eachAmount;
                            }
                          }
                        }
                      }
                    }
                  }

                  if (others[0] != "") {
                    nobet = nobet + others.length;
                    for (let r = 0; r < others.length; r++) {
                      totalMoneyForEach =
                        totalMoneyForEach + parseFloat(others[r].split("*")[1]);

                      if (
                        evenOdd.toLowerCase() ==
                        others[r].split("*")[0].trim().toLowerCase()
                      ) {
                        for (let x = 0; x < rowOut2.length; x++) {
                          if (
                            rowOut2[x].choosen_length.toLowerCase() ==
                            evenOdd.toLowerCase()
                          ) {
                            winnersTicketCounter++;
                            winnersNoBets = winnersNoBets + 1;
                            winnersMoney =
                              winnersMoney +
                              parseFloat(rowOut2[x].odd_value) *
                                parseFloat(others[r].split("*")[1].trim());
                          }
                        }
                      }

                      if (
                        highLow.toLowerCase() ==
                        others[r].split("*")[0].trim().toLowerCase()
                      ) {
                        for (let x = 0; x < rowOut2.length; x++) {
                          if (
                            rowOut2[x].choosen_length.toLowerCase() ==
                            highLow.toLowerCase()
                          ) {
                            winnersTicketCounter++;
                            winnersNoBets = winnersNoBets + 1;
                            winnersMoney =
                              winnersMoney +
                              parseFloat(rowOut2[x].odd_value) *
                                parseFloat(others[r].split("*")[1].trim());
                          }
                        }
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;
                if (winnersTicketCounter != 0) winnersNoTickets++;
                if (this.dailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.dailyWork[t].winner_award;
              }
            }

            this.dailyReport.push({
              day: allDays[d],
              date: date,
              time: day,
              noTicket: noTicket,
              winnersNoBets: winnersNoBets,
              winnersNoTickets: winnersNoTickets,

              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              winnersMoney: winnersMoney,
              paidMoney: totalPaidForEach,
              onhand: totalMoneyForEach - totalPaidForEach,
              totalProfit: totalMoneyForEach - winnersMoney,
            });
          }
        } else {
          var totalMoneyForEach = 0;

          var totalPaidForEach = 0;
          var noTicket = 0;
          var nobet = 0;
          var noPaidTicket = 0;
          var nocancelledTicket = 0;
          var date = "";
          var winnersNoBets = 0;
          var winnersMoney = 0;
          var winnersNoTickets = 0;
          this.dailyReport = [];

          for (let t = 0; t < this.dailyWork.length; t++) {
            var winnersTicketCounter = 0;
            var tickets = this.dailyWork[t].choosen_numbers.split(":");
            var others = this.dailyWork[t].others.split(":");
            var drownNumbers =
              this.dailyWork[t].getGameForShifts[0].drawn_numbers != ""
                ? this.dailyWork[t].getGameForShifts[0].drawn_numbers.split(",")
                : [];
            var evenOdd = this.dailyWork[t].getGameForShifts[0].even_odd;
            var highLow = this.dailyWork[t].getGameForShifts[0].high_low;
            if (this.dailyWork[t].is_cancelled == 0) {
              noTicket = noTicket + 1;
              if (tickets[0] != "") {
                nobet = nobet + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyForEach =
                    totalMoneyForEach + parseFloat(tickets[j].split("*")[1]);

                  var eachNumber = tickets[j].split("*")[0].split(",");
                  var eachAmount = parseFloat(tickets[j].split("*")[1]);

                  var matchCount = 0;
                  for (let k = 0; k < eachNumber.length; k++) {
                    for (let m = 0; m < drownNumbers.length; m++) {
                      if (drownNumbers[m] == eachNumber[k].trim()) {
                        matchCount++;
                      }
                    }
                  }

                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      parseInt(rowOut2[x].choosen_length) == eachNumber.length
                    ) {
                      var hits = rowOut2[x].hits.split(",");
                      var possibleWinMoney = rowOut2[x].odd_value.split(",");

                      for (let y = 0; y < hits.length; y++) {
                        if (matchCount == parseInt(hits[y].trim())) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney +
                            (parseFloat(possibleWinMoney[y].trim()) / 10) *
                              eachAmount;
                        }
                      }
                    }
                  }
                }
              }

              if (others[0] != "") {
                nobet = nobet + others.length;
                for (let r = 0; r < others.length; r++) {
                  totalMoneyForEach =
                    totalMoneyForEach + parseFloat(others[r].split("*")[1]);

                  if (
                    evenOdd.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                  ) {
                    for (let x = 0; x < rowOut2.length; x++) {
                      if (
                        rowOut2[x].choosen_length.toLowerCase() ==
                        evenOdd.toLowerCase()
                      ) {
                        winnersTicketCounter++;
                        winnersNoBets = winnersNoBets + 1;
                        winnersMoney =
                          winnersMoney +
                          parseFloat(rowOut2[x].odd_value) *
                            parseFloat(others[r].split("*")[1].trim());
                      }
                    }
                  }

                  if (
                    highLow.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                  ) {
                    for (let x = 0; x < rowOut2.length; x++) {
                      if (
                        rowOut2[x].choosen_length.toLowerCase() ==
                        highLow.toLowerCase()
                      ) {
                        winnersTicketCounter++;
                        winnersNoBets = winnersNoBets + 1;
                        winnersMoney =
                          winnersMoney +
                          parseFloat(rowOut2[x].odd_value) *
                            parseFloat(others[r].split("*")[1].trim());
                      }
                    }
                  }
                }
              }
            } else nocancelledTicket = nocancelledTicket + 1;
            if (winnersTicketCounter != 0) winnersNoTickets++;
            if (this.dailyWork[t].winner_award != 0)
              noPaidTicket = noPaidTicket + 1;
            totalPaidForEach =
              totalPaidForEach + this.dailyWork[t].winner_award;
          }

          this.dailyReport.push({
            day: "",
            date: "",
            time: "",
            noTicket: noTicket,
            winnersNoTickets: winnersNoTickets,
            noBet: nobet,
            winnersNoBets: winnersNoBets,
            noPaidTicket: noPaidTicket,
            nocancelledTicket: nocancelledTicket,

            totalMoney: totalMoneyForEach,
            winnersMoney: winnersMoney,
            paidMoney: totalPaidForEach,
            onhand: totalMoneyForEach - totalPaidForEach,
            totalProfit: totalMoneyForEach - winnersMoney,
          });
        }

        this.allDailyWork = [];

        for (let a = 0; a < this.dailyReport.length; a++) {
          let existCount = 0;
          for (let s = 0; s < this.spinDailyReport.length; s++) {
            if (
              this.dailyReport[a].day == this.spinDailyReport[s].day &&
              this.dailyReport[a].date == this.spinDailyReport[s].date
            ) {
              existCount++;

              this.allDailyWork.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                time: this.dailyReport[a].time,
                noTicket:
                  this.dailyReport[a].noTicket +
                  this.spinDailyReport[s].noTicket,
                winnersNoTickets:
                  this.dailyReport[a].winnersNoTickets +
                  this.spinDailyReport[s].winnersNoTickets,
                noBet:
                  this.dailyReport[a].noBet + this.spinDailyReport[s].noBet,
                winnersNoBets:
                  this.dailyReport[a].winnersNoBets +
                  this.spinDailyReport[s].winnersNoBets,
                noPaidTicket:
                  this.dailyReport[a].noPaidTicket +
                  this.spinDailyReport[s].noPaidTicket,
                nocancelledTicket:
                  this.dailyReport[a].nocancelledTicket +
                  this.spinDailyReport[s].nocancelledTicket,

                totalMoney:
                  this.dailyReport[a].totalMoney +
                  this.spinDailyReport[s].totalMoney,
                winnersMoney:
                  this.dailyReport[a].winnersMoney +
                  this.spinDailyReport[s].winnersMoney,
                paidMoney:
                  this.dailyReport[a].paidMoney +
                  this.spinDailyReport[s].paidMoney,
                onhand:
                  this.dailyReport[a].onhand + this.spinDailyReport[s].onhand,

                totalProfit:
                  this.dailyReport[a].totalProfit +
                  this.spinDailyReport[s].totalProfit,
              });
            }
          }

          if (existCount == 0) {
            this.allDailyWork.push({
              day: this.dailyReport[a].day,
              date: this.dailyReport[a].date,
              time: this.dailyReport[a].time,
              noTicket: this.dailyReport[a].noTicket,
              winnersNoTickets: this.dailyReport[a].winnersNoTickets,
              noBet: this.dailyReport[a].noBet,
              winnersNoBets: this.dailyReport[a].winnersNoBets,
              noPaidTicket: this.dailyReport[a].noPaidTicket,
              nocancelledTicket: this.dailyReport[a].nocancelledTicket,

              totalMoney: this.dailyReport[a].totalMoney,
              winnersMoney: this.dailyReport[a].winnersMoney,
              paidMoney: this.dailyReport[a].paidMoney,
              onhand: this.dailyReport[a].onhand,
              totalProfit: this.dailyReport[a].totalProfit,
            });
          }
        }

        for (let a = 0; a < this.spinDailyReport.length; a++) {
          let existCount = 0;
          for (let s = 0; s < this.dailyReport.length; s++) {
            if (
              this.dailyReport[s].day == this.spinDailyReport[a].day &&
              this.dailyReport[s].date == this.spinDailyReport[a].date
            ) {
              existCount++;
            }
          }

          if (existCount == 0) {
            this.allDailyWork.push({
              day: this.spinDailyReport[a].day,
              date: this.spinDailyReport[a].date,
              time: this.spinDailyReport[a].time,
              noTicket: this.spinDailyReport[a].noTicket,
              winnersNoTickets: this.spinDailyReport[a].winnersNoTickets,
              noBet: this.spinDailyReport[a].noBet,
              winnersNoBets: this.spinDailyReport[a].winnersNoBets,
              noPaidTicket: this.spinDailyReport[a].noPaidTicket,
              nocancelledTicket: this.spinDailyReport[a].nocancelledTicket,

              totalMoney: this.spinDailyReport[a].totalMoney,
              winnersMoney: this.spinDailyReport[a].winnersMoney,
              paidMoney: this.spinDailyReport[a].paidMoney,
              onhand: this.spinDailyReport[a].onhand,
              totalProfit: this.spinDailyReport[a].totalProfit,
            });
          }
        }

        this.allDailyWork.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      } catch (err) {
        alert(err);
      }
    },

    async spinGetDailyWorks(passedDate) {
      var today = new Date();
      this.spinChoosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.spinChoosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.spinChoosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.spinChoosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.spinChoosenDate == "Last week") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.spinChoosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.spinChoosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.spinChoosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.spinChoosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.spinChoosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.spinChoosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.spinChoosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.spinChoosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
              getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                 
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      is_cashout
                      branch_id
                      cashier_id
                      partner_id
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                        getSpinNumber{
                            spin_number_id
                            number
                            color
                            mirror
                            twins
                            final
                            dozen
                            sector
                            combination
                            even_odd
                            high_low
                            neighbors
                          }
                     }

                   }
              }
              `,
          },
        });
        this.spinDailyWork = dailyResult.data.data.getMoneyOnHands;

        var allDays = [];
        this.spinDailyReport = [];
        var rowOut2 = this.$store.state.spinOdds;

        if (this.spinChoosenDate == "Last 7 days") {
          for (let t = 0; t < this.spinDailyWork.length; t++) {
            // var day = new Date(
            //   Number(this.spinDailyWork[t].ticket_date)
            // ).getDay();

            var day = this.formatDate(
              new Date(Number(this.spinDailyWork[t].ticket_date))
            );

            if (allDays.indexOf(day) === -1) allDays.push(day);
          }
          for (let d = 0; d < allDays.length; d++) {
            var totalMoneyForEach = 0;

            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var date = "";
            var winnersNoBets = 0;
            var winnersMoney = 0;
            var winnersNoTickets = 0;

            for (let t = 0; t < this.spinDailyWork.length; t++) {
              var winnersTicketCounter = 0;
              // var day = new Date(
              //   Number(this.spinDailyWork[t].ticket_date)
              // ).getDay();

              var day = this.formatDate(
                new Date(Number(this.spinDailyWork[t].ticket_date))
              );

              if (day == allDays[d]) {
                var month =
                  this.monthNames[
                    new Date(
                      Number(this.spinDailyWork[t].ticket_date)
                    ).getMonth()
                  ];

                var eachSplitedDate = this.formatDate(
                  new Date(Number(this.spinDailyWork[t].ticket_date))
                ).split("-");
                date =
                  month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                var tickets = this.spinDailyWork[t].choosen_numbers.split(":");
                var spinNum =
                  this.spinDailyWork[t].getGameForShifts[0].getSpinNumber;

                if (this.spinDailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;
                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[1]);

                      var eachNumber = tickets[j].split("*")[0].trim();
                      var eachAmount = parseFloat(tickets[j].split("*")[1]);

                      if (spinNum.length > 0) {
                        if (spinNum[0].number.toString() == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].number * eachAmount;
                        } else if (eachNumber.includes(",")) {
                          if (
                            eachNumber.split(",")[0].trim() ==
                              spinNum[0].number.toString() ||
                            eachNumber.split(",")[1].trim() ==
                              spinNum[0].number.toString()
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney =
                              winnersMoney +
                              (rowOut2[0].number / 2) * eachAmount;
                          }
                        } else if (spinNum[0].color == eachNumber) {
                          if (eachNumber == "0 Green") {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney =
                              winnersMoney + rowOut2[0].number * eachAmount;
                          } else {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney =
                              winnersMoney + rowOut2[0].high_low * eachAmount;
                          }
                        } else if (spinNum[0].sector == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].sector * eachAmount;
                        } else if (spinNum[0].even_odd == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].even_odd * eachAmount;
                        } else if (spinNum[0].high_low == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].high_low * eachAmount;
                        } else if (
                          spinNum[0].neighbors.split(",")[0].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[1].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[2].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[3].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[4].trim() ==
                            eachNumber
                        ) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].neighbors * eachAmount;
                        } else if (spinNum[0].twins == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].twins * eachAmount;
                        } else if (spinNum[0].dozen == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].dozen * eachAmount;
                        } else if (eachNumber.includes("Final")) {
                          if (spinNum[0].final.includes(eachNumber)) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney =
                              winnersMoney + rowOut2[0].final * eachAmount;
                          }
                        } else if (spinNum[0].mirror == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney + rowOut2[0].mirror * eachAmount;
                        } else if (spinNum[0].combination == eachNumber) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney +
                            rowOut2[0].high_low_color * eachAmount;
                        }
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;
                if (winnersTicketCounter != 0) winnersNoTickets++;
                if (this.spinDailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.spinDailyWork[t].winner_award;
              }
            }

            this.spinDailyReport.push({
              day: allDays[d],
              date: date,
              time: day,
              noTicket: noTicket,
              winnersNoBets: winnersNoBets,
              winnersNoTickets: winnersNoTickets,

              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              winnersMoney: winnersMoney,
              paidMoney: totalPaidForEach,
              onhand: totalMoneyForEach - totalPaidForEach,
              totalProfit: totalMoneyForEach - winnersMoney,
            });
          }
        } else {
          var totalMoneyForEach = 0;

          var totalPaidForEach = 0;
          var noTicket = 0;
          var nobet = 0;
          var noPaidTicket = 0;
          var nocancelledTicket = 0;
          var date = "";
          var winnersNoBets = 0;
          var winnersMoney = 0;
          var winnersNoTickets = 0;
          this.spinDailyReport = [];

          for (let t = 0; t < this.spinDailyWork.length; t++) {
            var winnersTicketCounter = 0;
            var tickets = this.spinDailyWork[t].choosen_numbers.split(":");
            var spinNum =
              this.spinDailyWork[t].getGameForShifts[0].getSpinNumber;
            if (this.spinDailyWork[t].is_cancelled == 0) {
              noTicket = noTicket + 1;
              if (tickets[0] != "") {
                nobet = nobet + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyForEach =
                    totalMoneyForEach + parseFloat(tickets[j].split("*")[1]);

                  var eachNumber = tickets[j].split("*")[0].trim();
                  var eachAmount = parseFloat(tickets[j].split("*")[1]);

                  if (spinNum.length > 0) {
                    if (spinNum[0].number.toString() == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].number * eachAmount;
                    } else if (eachNumber.includes(",")) {
                      if (
                        eachNumber.split(",")[0].trim() ==
                          spinNum[0].number.toString() ||
                        eachNumber.split(",")[1].trim() ==
                          spinNum[0].number.toString()
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney =
                          winnersMoney + (rowOut2[0].number / 2) * eachAmount;
                      }
                    } else if (spinNum[0].color == eachNumber) {
                      if (eachNumber == "0 Green") {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney =
                          winnersMoney + rowOut2[0].number * eachAmount;
                      } else {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney =
                          winnersMoney + rowOut2[0].high_low * eachAmount;
                      }
                    } else if (spinNum[0].sector == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].sector * eachAmount;
                    } else if (spinNum[0].even_odd == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].even_odd * eachAmount;
                    } else if (spinNum[0].high_low == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].high_low * eachAmount;
                    } else if (
                      spinNum[0].neighbors.split(",")[0].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[1].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[2].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[3].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[4].trim() == eachNumber
                    ) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].neighbors * eachAmount;
                    } else if (spinNum[0].twins == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].twins * eachAmount;
                    } else if (spinNum[0].dozen == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].dozen * eachAmount;
                    } else if (eachNumber.includes("Final")) {
                      if (spinNum[0].final.includes(eachNumber)) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney =
                          winnersMoney + rowOut2[0].final * eachAmount;
                      }
                    } else if (spinNum[0].mirror == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].mirror * eachAmount;
                    } else if (spinNum[0].combination == eachNumber) {
                      winnersNoBets = winnersNoBets + 1;
                      winnersTicketCounter++;
                      winnersMoney =
                        winnersMoney + rowOut2[0].high_low_color * eachAmount;
                    }
                  }
                }
              }
            } else nocancelledTicket = nocancelledTicket + 1;
            if (winnersTicketCounter != 0) winnersNoTickets++;
            if (this.spinDailyWork[t].winner_award != 0)
              noPaidTicket = noPaidTicket + 1;
            totalPaidForEach =
              totalPaidForEach + this.spinDailyWork[t].winner_award;
          }

          this.spinDailyReport.push({
            day: "",
            date: "",
            time: "",
            noTicket: noTicket,
            winnersNoTickets: winnersNoTickets,
            noBet: nobet,
            winnersNoBets: winnersNoBets,
            noPaidTicket: noPaidTicket,
            nocancelledTicket: nocancelledTicket,

            totalMoney: totalMoneyForEach,
            winnersMoney: winnersMoney,
            paidMoney: totalPaidForEach,
            onhand: totalMoneyForEach - totalPaidForEach,
            totalProfit: totalMoneyForEach - winnersMoney,
          });
        }

        this.allDailyWork = [];

        for (let a = 0; a < this.dailyReport.length; a++) {
          let existCount = 0;
          for (let s = 0; s < this.spinDailyReport.length; s++) {
            if (
              this.dailyReport[a].day == this.spinDailyReport[s].day &&
              this.dailyReport[a].date == this.spinDailyReport[s].date
            ) {
              existCount++;

              this.allDailyWork.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                time: this.dailyReport[a].time,
                noTicket:
                  this.dailyReport[a].noTicket +
                  this.spinDailyReport[s].noTicket,
                winnersNoTickets:
                  this.dailyReport[a].winnersNoTickets +
                  this.spinDailyReport[s].winnersNoTickets,
                noBet:
                  this.dailyReport[a].noBet + this.spinDailyReport[s].noBet,
                winnersNoBets:
                  this.dailyReport[a].winnersNoBets +
                  this.spinDailyReport[s].winnersNoBets,
                noPaidTicket:
                  this.dailyReport[a].noPaidTicket +
                  this.spinDailyReport[s].noPaidTicket,
                nocancelledTicket:
                  this.dailyReport[a].nocancelledTicket +
                  this.spinDailyReport[s].nocancelledTicket,

                totalMoney:
                  this.dailyReport[a].totalMoney +
                  this.spinDailyReport[s].totalMoney,
                winnersMoney:
                  this.dailyReport[a].winnersMoney +
                  this.spinDailyReport[s].winnersMoney,
                paidMoney:
                  this.dailyReport[a].paidMoney +
                  this.spinDailyReport[s].paidMoney,
                onhand:
                  this.dailyReport[a].onhand + this.spinDailyReport[s].onhand,

                totalProfit:
                  this.dailyReport[a].totalProfit +
                  this.spinDailyReport[s].totalProfit,
              });
            }
          }

          if (existCount == 0) {
            this.allDailyWork.push({
              day: this.dailyReport[a].day,
              date: this.dailyReport[a].date,
              time: this.dailyReport[a].time,
              noTicket: this.dailyReport[a].noTicket,
              winnersNoTickets: this.dailyReport[a].winnersNoTickets,
              noBet: this.dailyReport[a].noBet,
              winnersNoBets: this.dailyReport[a].winnersNoBets,
              noPaidTicket: this.dailyReport[a].noPaidTicket,
              nocancelledTicket: this.dailyReport[a].nocancelledTicket,

              totalMoney: this.dailyReport[a].totalMoney,
              winnersMoney: this.dailyReport[a].winnersMoney,
              paidMoney: this.dailyReport[a].paidMoney,
              onhand: this.dailyReport[a].onhand,
              totalProfit: this.dailyReport[a].totalProfit,
            });
          }
        }

        for (let a = 0; a < this.spinDailyReport.length; a++) {
          let existCount = 0;
          for (let s = 0; s < this.dailyReport.length; s++) {
            if (
              this.dailyReport[s].day == this.spinDailyReport[a].day &&
              this.dailyReport[s].date == this.spinDailyReport[a].date
            ) {
              existCount++;
            }
          }

          if (existCount == 0) {
            this.allDailyWork.push({
              day: this.spinDailyReport[a].day,
              date: this.spinDailyReport[a].date,
              time: this.spinDailyReport[a].time,
              noTicket: this.spinDailyReport[a].noTicket,
              winnersNoTickets: this.spinDailyReport[a].winnersNoTickets,
              noBet: this.spinDailyReport[a].noBet,
              winnersNoBets: this.spinDailyReport[a].winnersNoBets,
              noPaidTicket: this.spinDailyReport[a].noPaidTicket,
              nocancelledTicket: this.spinDailyReport[a].nocancelledTicket,

              totalMoney: this.spinDailyReport[a].totalMoney,
              winnersMoney: this.spinDailyReport[a].winnersMoney,
              paidMoney: this.spinDailyReport[a].paidMoney,
              onhand: this.spinDailyReport[a].onhand,
              totalProfit: this.spinDailyReport[a].totalProfit,
            });
          }
        }

        this.allDailyWork.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async getAllSummary() {
      await this.getDailyWorks("Last 7 days");
      await this.spinGetDailyWorks("Last 7 days");
    },
    spinWatchData() {
      var totalMoney = 0;
      this.spinTotalPossibleWin = 0;

      for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
        if (this.spinInputMoney[i] != undefined && this.spinInputMoney[i] != "")
          totalMoney = totalMoney + parseFloat(this.spinInputMoney[i]);
        // for (let j = 0; j < this.$store.state.odds.length; j++) {
        var inp =
          this.spinInputMoney[i] != undefined
            ? parseFloat(this.spinInputMoney[i])
            : 0;
        if (this.selectedSpinNumbers[i].includes("Neighbors")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].neighbors;
        } else if (this.selectedSpinNumbers[i].includes("&")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].high_low_color;
        } else if (this.selectedSpinNumbers[i].includes("Donzen")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].dozen;
        } else if (this.selectedSpinNumbers[i].includes("Sector")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].sector;
        } else if (this.selectedSpinNumbers[i].includes("Final")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].final;
        } else if (
          this.selectedSpinNumbers[i] == "Red" ||
          this.selectedSpinNumbers[i] == "Black" ||
          this.selectedSpinNumbers[i] == "High" ||
          this.selectedSpinNumbers[i] == "Low" ||
          this.selectedSpinNumbers[i] == "Even" ||
          this.selectedSpinNumbers[i] == "Odd"
        ) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].high_low;
        } else if (this.selectedSpinNumbers[i].includes("Green")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].number;
        } else if (this.selectedSpinNumbers[i].includes("Twins")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].twins;
        } else if (this.selectedSpinNumbers[i].includes("|")) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].mirror;
        } else if (
          this.selectedSpinNumbers[i].includes(",") &&
          this.selectedSpinNumbers[i].split(",").length == 2
        ) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            (parseFloat(inp) * this.$store.state.spinOdds[0].number) / 2;
        } else if (!isNaN(this.selectedSpinNumbers[i])) {
          this.spinTotalPossibleWin =
            this.spinTotalPossibleWin +
            parseFloat(inp) * this.$store.state.spinOdds[0].number;
        }
        var finalNumbers = this.selectedSpinNumbers[i];
        if (finalNumbers.includes("Neighbors")) {
          finalNumbers = finalNumbers.split("(")[0].trim();
        }
      }

      this.spinTotalInputMoney = totalMoney;
    },
    assignSelectedAmount(amount) {
      this.selectedCustomAmount = amount;
      if (
        this.kenoCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "number"
      )
        this.numberInputMoney[this.kenoCurrentMousePosition] =
          this.selectedCustomAmount;
      if (
        this.kenoevenoddCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "evenodd"
      )
        this.evenOddInputMoney[this.kenoevenoddCurrentMousePosition] =
          this.selectedCustomAmount;
    },

    applyToAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        this.numberInputMoney.push(this.selectedCustomAmount);
      }

      for (let i = 0; i < this.selectedEvenOddAndHighLow.length; i++) {
        this.evenOddInputMoney.push(this.selectedCustomAmount);
      }
    },
    clearAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      this.selectedCustomAmount = 0;
    },
    selectNumber(number) {
      this.successfullyPlaced = "";
      if (this.selectedNumbers.length == 0) {
        this.selectedNumbers.push([]);
        this.numberInputMoney[0] = 10;
        this.intest = 0;
      }
      if (
        this.selectedNumbers[this.selectedNumberOptionCounter].indexOf(
          number
        ) != -1
      ) {
        this.selectedNumbers[this.selectedNumberOptionCounter].splice(
          this.selectedNumbers[this.selectedNumberOptionCounter].indexOf(
            number
          ),
          1
        );
      } else if (
        this.selectedNumbers[this.selectedNumberOptionCounter].length < 10
      )
        this.selectedNumbers[this.selectedNumberOptionCounter].push(number);
      this.kenoCurrentMousePosition = this.selectedNumbers.length - 1;

      this.inWhichToAddAmount = "number";
    },

    selectEvenOddAndHighLow(evenOddHighLow) {
      this.successfullyPlaced = "";
      this.snumPosition = 1009;
      this.numPosition = 1009;
      if (this.selectedEvenOddAndHighLow.indexOf(evenOddHighLow) != -1) {
        this.selectedEvenOddAndHighLow.splice(
          this.selectedEvenOddAndHighLow.indexOf(evenOddHighLow),
          1
        );
      } else {
        this.evenOddInputMoney.push(10);
        this.selectedEvenOddAndHighLow.push(evenOddHighLow);
      }

      this.kenoevenoddCurrentMousePosition =
        this.selectedEvenOddAndHighLow.length - 1;
      this.inWhichToAddAmount = "evenodd";
    },

    removeEvenOddKenoList(item) {
      let index = this.selectedEvenOddAndHighLow.indexOf(item);
      this.selectedEvenOddAndHighLow.splice(index, 1);
      this.evenOddInputMoney.splice(index, 1);
      this.kenoevenoddCurrentMousePosition =
        this.selectedEvenOddAndHighLow.length - 1;
      this.snumPosition = 1009;
      this.numPosition = 1009;
    },
    removeSelectedNumbers(index) {
      this.snumPosition = 1009;
      this.numPosition = 1009;
      this.selectedNumbers.splice(index, 1);
      this.numberInputMoney.splice(index, 1);
      if (this.selectedNumberOptionCounter > 0)
        this.selectedNumberOptionCounter--;
      this.kenoCurrentMousePosition = this.selectedNumbers.length - 1;
    },

    rand_str_generator() {
      const list = "AB01CD2EFJKLSTUVWXYZ345MNPQR67GHI89";
      var res = "";
      for (var i = 0; i < 12; i++) {
        var rnd = Math.floor(Math.random() * list.length);
        res = res + list.charAt(rnd);
      }
      this.randomSring = res;
    },

    saveTwoNumbers() {
      this.successfullyPlaced = "";
      this.spnumPosition = 1009;
      if (this.twoNumbers.length > 0)
        this.selectedSpinNumbers.push(this.twoNumbers.toString());
      this.twoNumbers = [];
      this.twoNumbersPopUp = false;
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },
    async getHelp() {
      try {
        var helpResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
              getCashierHelp {
                      help_tbl_id
                      help_text
                      video
                    
                    }
              
              }
              `,
          },
        });

        this.help = helpResult.data.data.getCashierHelp;
        this.helpText = this.help[0].help_text;
        this.video = this.help[0].video.split(",");
      } catch (err) {
        alert(err);
      }
    },

    async getUnpaid() {
      this.unpaidLoading = true;

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getDailyWorksByCashierId(date1:"${date1}", date2:"${date2}",   cashier_id:${this.$store.state.cashier.cashier_id}){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });
        this.todaysWork = todayResult.data.data.getDailyWorksByCashierId;

        var rowOut2 = this.$store.state.odds;
        this.kenoUnpaidList = [];
        this.kenoPaidList = [];

        for (let t = 0; t < this.todaysWork.length; t++) {
          var winnersTicketCounter = 0;
          var unpaidWinnersMoney = 0;
          if (this.todaysWork[t].is_cancelled == 0) {
            var tickets = this.todaysWork[t].choosen_numbers.split(":");
            var others = this.todaysWork[t].others.split(":");
            var drownNumbers =
              this.todaysWork[t].getGameForShifts[0].drawn_numbers != ""
                ? this.todaysWork[t].getGameForShifts[0].drawn_numbers.split(
                    ","
                  )
                : [];
            var evenOdd = this.todaysWork[t].getGameForShifts[0].even_odd;
            var highLow = this.todaysWork[t].getGameForShifts[0].high_low;

            if (tickets[0] != "") {
              for (let j = 0; j < tickets.length; j++) {
                var eachNumber = tickets[j].split("*")[0].split(",");
                var eachAmount = parseFloat(tickets[j].split("*")[1]);

                var matchCount = 0;
                for (let k = 0; k < eachNumber.length; k++) {
                  for (let m = 0; m < drownNumbers.length; m++) {
                    if (drownNumbers[m] == eachNumber[k].trim()) {
                      matchCount++;
                    }
                  }
                }

                for (let x = 0; x < rowOut2.length; x++) {
                  if (
                    parseInt(rowOut2[x].choosen_length) == eachNumber.length
                  ) {
                    var hits = rowOut2[x].hits.split(",");
                    var possibleWinMoney = rowOut2[x].odd_value.split(",");

                    for (let y = 0; y < hits.length; y++) {
                      if (matchCount == parseInt(hits[y].trim())) {
                        winnersTicketCounter++;
                        unpaidWinnersMoney =
                          unpaidWinnersMoney +
                          (parseFloat(possibleWinMoney[y].trim()) / 10) *
                            eachAmount;
                      }
                    }
                  }
                }
              }
            }

            if (others[0] != "") {
              for (let r = 0; r < others.length; r++) {
                if (
                  evenOdd.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      evenOdd.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }

                if (
                  highLow.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      highLow.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }
              }
            }
          }
          if (
            winnersTicketCounter != 0 &&
            this.todaysWork[t].winner_award == 0
          ) {
            this.kenoUnpaidList.push(
              this.todaysWork[t].ticket_number +
                " = " +
                unpaidWinnersMoney +
                " Birr"
            );
          }

          if (
            winnersTicketCounter != 0 &&
            this.todaysWork[t].winner_award != 0
          ) {
            this.kenoPaidList.push(this.todaysWork[t].ticket_number);
          }
        }
      } catch (err) {
        alert(err);
      }

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
              getDailyWorksByCashierId(date1:"${date1}", date2:"${date2}",   cashier_id:${this.$store.state.cashier.cashier_id}){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                        getSpinNumber{
                            spin_number_id
                            number
                            color
                            mirror
                            twins
                            final
                            dozen
                            sector
                            combination
                            even_odd
                            high_low
                            neighbors
                          }
                     }

                   }
              }
              `,
          },
        });
        this.spinTodaysWork = todayResult.data.data.getDailyWorksByCashierId;

        var rowOut2 = this.$store.state.spinOdds;

        this.spinUnpaidList = [];
        this.spinPaidList = [];

        for (let t = 0; t < this.spinTodaysWork.length; t++) {
          var winnersTicketCounter = 0;
          var unpaidWinnersMoney = 0;
          if (this.spinTodaysWork[t].is_cancelled == 0) {
            var tickets = this.spinTodaysWork[t].choosen_numbers.split(":");
            var spinNum =
              this.spinTodaysWork[t].getGameForShifts[0].getSpinNumber;

            if (tickets[0] != "") {
              this.spinNoBets = this.spinNoBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                var eachNumber = tickets[j].split("*")[0].trim();
                var eachAmount = parseFloat(tickets[j].split("*")[1]);

                if (spinNum.length > 0) {
                  if (spinNum[0].number.toString() == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].number * eachAmount;
                  } else if (eachNumber.includes(",")) {
                    if (
                      eachNumber.split(",")[0].trim() ==
                        spinNum[0].number.toString() ||
                      eachNumber.split(",")[1].trim() ==
                        spinNum[0].number.toString()
                    ) {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        (rowOut2[0].number / 2) * eachAmount;
                    }
                  } else if (spinNum[0].color == eachNumber) {
                    if (eachNumber == "0 Green") {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney + rowOut2[0].number * eachAmount;
                    } else {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney + rowOut2[0].high_low * eachAmount;
                    }
                  } else if (spinNum[0].sector == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].sector * eachAmount;
                  } else if (spinNum[0].even_odd == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].even_odd * eachAmount;
                  } else if (spinNum[0].high_low == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].high_low * eachAmount;
                  } else if (
                    spinNum[0].neighbors.split(",")[0].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[1].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[2].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[3].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[4].trim() == eachNumber
                  ) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].neighbors * eachAmount;
                  } else if (spinNum[0].twins == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].twins * eachAmount;
                  } else if (spinNum[0].dozen == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].dozen * eachAmount;
                  } else if (eachNumber.includes("Final")) {
                    if (spinNum[0].final.includes(eachNumber)) {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney + rowOut2[0].final * eachAmount;
                    }
                  } else if (spinNum[0].mirror == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].mirror * eachAmount;
                  } else if (spinNum[0].combination == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney +
                      rowOut2[0].high_low_color * eachAmount;
                  }
                }
              }
            }
          }
          if (
            winnersTicketCounter != 0 &&
            this.spinTodaysWork[t].winner_award == 0
          )
            this.spinUnpaidList.push(
              this.spinTodaysWork[t].ticket_number +
                " = " +
                unpaidWinnersMoney +
                " Birr"
            );

          if (
            winnersTicketCounter != 0 &&
            this.spinTodaysWork[t].winner_award != 0
          )
            this.spinPaidList.push(this.spinTodaysWork[t].ticket_number);
        }
      } catch (err) {}
      this.unpaidDialog = true;
      this.unpaidLoading = false;
    },

    async getLastTwentyGames(game) {
      this.lastTwentyGames = [];
      this.gameLoading = true;

      if (game == "keno") {
        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
                getLastTwentyGames{
                  game_number
                  game_id
                  drawn_numbers
                }
  
                }
                `,
            },
          });

          this.lastTwentyGames = gameResult.data.data.getLastTwentyGames;
        } catch (err) {}
      } else {
        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `{
                getLastTwentyGames{
                  game_number
                  game_id
                  drawn_numbers
                }
  
                }
                `,
            },
          });

          this.lastTwentyGames = gameResult.data.data.getLastTwentyGames;
        } catch (err) {}
      }
      if (this.lastTwentyGames.length > 0) this.last20GameDialog = true;

      this.gameLoading = false;
    },

    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },

    async printLastgame() {
      var content = this.$refs.lastGamePrintable;
      this.last20GameDialog = false;

      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 100);
    },

    async recietOverview(id) {
      this.snumPosition = 1009;
      this.numPosition = 1009;
      if (Number(Date.now() - this.$store.state.sessionTime) > 20 * 60 * 1000) {
        this.$store.dispatch("loginCashier", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      this.copyMessage = "";
      let count = 0;
      let totalMoney = 0;
      this.totalPossibleWin = 0;
      if (
        this.selectedNumbers.length == this.numberInputMoney.length &&
        this.selectedEvenOddAndHighLow.length == this.evenOddInputMoney.length
      ) {
        for (let v = 0; v < this.numberInputMoney.length; v++) {
          if (this.numberInputMoney[v] < 10 || this.numberInputMoney[v] > 2000)
            count++;
        }
        for (let v = 0; v < this.evenOddInputMoney.length; v++) {
          if (
            this.evenOddInputMoney[v] < 10 ||
            this.evenOddInputMoney[v] > 2000
          )
            count++;
        }
        for (let i = 0; i < this.selectedNumbers.length; i++) {
          if (this.selectedNumbers[i].length == 0) count++;
          if (this.numberInputMoney[i] == "") count++;
        }
        if (count == 0) {
          this.userNumber = Date.now();
          this.gameDate = Date.now();
          var choosenNumbers = "";
          var othersSelected = "";
          if (
            this.formatDate(Date.now()) !=
            this.$store.state.kenoTicketNumberDate
          ) {
            this.$store.dispatch(
              "kenoTicketNumberDate",
              this.formatDate(Date.now())
            );

            this.$store.dispatch("kenoTicketNumber", 0);
          }
          this.$store.dispatch(
            "kenoTicketNumber",
            this.$store.state.kenoTicketNumber + 1
          );
          for (let i = 0; i < this.selectedNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);
            for (let j = 0; j < this.$store.state.odds.length; j++) {
              if (
                this.$store.state.odds[j].choosen_length ==
                this.selectedNumbers[i].length
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  (parseFloat(this.numberInputMoney[i]) *
                    parseFloat(
                      this.$store.state.odds[j].odd_value.split(",")[0]
                    )) /
                    10;
              }
            }

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
          }

          for (let j = 0; j < this.selectedEvenOddAndHighLow.length; j++) {
            totalMoney = totalMoney + parseFloat(this.evenOddInputMoney[j]);
            for (let k = 0; k < this.$store.state.odds.length; k++) {
              if (
                this.$store.state.odds[k].choosen_length.toLowerCase() ==
                this.selectedEvenOddAndHighLow[j].toLowerCase()
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  parseFloat(this.evenOddInputMoney[j]) *
                    parseFloat(this.$store.state.odds[k].odd_value);
              }
            }

            if (othersSelected == "") {
              othersSelected =
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
            } else
              othersSelected =
                othersSelected +
                ":" +
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
          }

          if (this.partnerBalance.tempo_package >= totalMoney) {
            this.totalInputMoney = totalMoney;
            this.printDialog = true;

            try {
              var ticketResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               saveTicket(
                game_id:${this.currentGame[0].game_id},
                ticket_number:${this.$store.state.kenoTicketNumber},
               user_id:"${this.userNumber}",
               cashier_id:${this.$store.state.cashier.cashier_id},
               partner_id:${this.$store.state.cashier.partner_id},
               branch_id:${this.$store.state.cashier.branch_id},
               choosen_numbers: "${choosenNumbers}",
               others: "${othersSelected}",
               ticket_date: "${this.gameDate}",
               replaced_user_id: "${id}",
               tempo_package:${this.partnerBalance.tempo_package - totalMoney}

               )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printable;

                var newWindow = window.open("");

                newWindow.document.write(content.outerHTML);
                setTimeout(function () {
                  newWindow.print();
                  newWindow.close();
                }, 140);
                this.printDialog = false;

                await this.getBalance();
                await this.getLastTwoGames();
                this.selectedNumbers = [];
                this.selectedEvenOddAndHighLow = [];
                this.evenOddInputMoney = [];
                this.selectedNumberOptionCounter = 0;
                this.numberInputMoney = [];
                this.successfullyPlaced = "Bet successfully placed!";
              } else alert("Ticket not printed, try again");
            } catch (err) {
              alert(err);
            }
          } else alert("Insufficient funds, please deposit to continue");
        } else
          alert(
            "Please fill all fields, amounts, And make sure the amount is between 10 to 2000 Birr"
          );
      } else
        alert(
          "Please fill all fields, amounts, And make sure the amount is between 10 to 1000"
        );
    },

    async copyOverview() {
      this.copyMessage = "copy";
      let count = 0;
      let totalMoney = 0;
      this.totalPossibleWin = 0;
      if (
        this.selectedNumbers.length == this.numberInputMoney.length &&
        this.selectedEvenOddAndHighLow.length == this.evenOddInputMoney.length
      ) {
        for (let v = 0; v < this.numberInputMoney.length; v++) {
          if (this.numberInputMoney[v] < 10 || this.numberInputMoney[v] > 2000)
            count++;
        }
        for (let v = 0; v < this.evenOddInputMoney.length; v++) {
          if (
            this.evenOddInputMoney[v] < 10 ||
            this.evenOddInputMoney[v] > 2000
          )
            count++;
        }
        for (let i = 0; i < this.selectedNumbers.length; i++) {
          if (this.selectedNumbers[i].length == 0) count++;
          if (this.numberInputMoney[i] == "") count++;
        }
        if (count == 0) {
          var choosenNumbers = "";
          var othersSelected = "";

          for (let i = 0; i < this.selectedNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);
            for (let j = 0; j < this.$store.state.odds.length; j++) {
              if (
                this.$store.state.odds[j].choosen_length ==
                this.selectedNumbers[i].length
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  (parseFloat(this.numberInputMoney[i]) *
                    parseFloat(
                      this.$store.state.odds[j].odd_value.split(",")[0]
                    )) /
                    10;
              }
            }

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
          }

          for (let j = 0; j < this.selectedEvenOddAndHighLow.length; j++) {
            totalMoney = totalMoney + parseFloat(this.evenOddInputMoney[j]);
            for (let k = 0; k < this.$store.state.odds.length; k++) {
              if (
                this.$store.state.odds[k].choosen_length.toLowerCase() ==
                this.selectedEvenOddAndHighLow[j].toLowerCase()
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  parseFloat(this.evenOddInputMoney[j]) *
                    parseFloat(this.$store.state.odds[k].odd_value);
              }
            }

            if (othersSelected == "") {
              othersSelected =
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
            } else
              othersSelected =
                othersSelected +
                ":" +
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
          }

          this.totalInputMoney = totalMoney;

          this.printDialog = true;
          setTimeout(
            function () {
              this.printCopy();
            }.bind(this),
            240
          );
        } else
          alert(
            "Please fill all fields, amounts, And make sure the amount is between 10 to 2000 Birr"
          );
      } else
        alert(
          "Please fill all fields, amounts, And make sure the amount is between 10 to 1000"
        );
    },

    printCopy() {
      try {
        var content = this.$refs.printable;

        var newWindow = window.open("");

        newWindow.document.write(content.outerHTML);
        setTimeout(function () {
          newWindow.print();
          newWindow.close();
        }, 140);
        this.printDialog = false;

        this.selectedNumbers = [];
        this.selectedEvenOddAndHighLow = [];
        this.evenOddInputMoney = [];
        this.selectedNumberOptionCounter = 0;
        this.numberInputMoney = [];
        this.copyMessage = "";
      } catch (err) {
        alert(err);
      }
    },
    async getCurrentGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status

                   }
              }
              `,
          },
        });
        this.currentGame = currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.currentGame.length > 0) {
          this.timeInSeconds = Math.floor(
            186 - (Date.now() - Number(this.currentGame[0].game_date)) / 1000
          );

          this.timePercent =
            0.53764 *
            Math.floor(
              (Date.now() - Number(this.currentGame[0].game_date)) / 1000
            );
        } else {
          this.timePercent = 0;
          this.minutes = "00";
          this.seconds = "00";
        }
      } catch (err) {}
    },

    async getLastTwoGames() {
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }

                       getLastTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                         getGame{
                          game_id
                          game_number
                          game_date
                          game_status
                           }
                       }

                       getLastTicketsNew{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                         getGameForShifts{
                          game_id
                          game_number
                          game_date
                          game_status
                           }
                       }
                     }


              }
              `,
          },
        });

        this.lastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.lastTwoGames.length > 0) {
          var lastGame = this.lastTwoGames[0].getTickets;
          this.currentTickets = this.lastTwoGames[0].getLastTicketsNew;

          this.isDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.isDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            }
          }
        }
      } catch (err) {}
    },

    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.$store.dispatch("odds", oddsResult.data.data.getOdds);
      } catch (err) {}
    },

    async getCommonFeatures() {
      try {
        var commonResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getCommonFeatures{
                 common_features_id
                 brand_name
                 phone
                 others
                }


              }
              `,
          },
        });

        this.commonFeatures = commonResult.data.data.getCommonFeatures;
      } catch (err) {
        alert(err);
      }
    },

    async reportTicket() {
      if (this.userIdForReport != "" && this.reportReason != "") {
        this.reportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.userIdForReport}",
              reason: "${this.reportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.reportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.reportLoading = false;
      } else alert("Please fill the fields");
    },

    async getBlockedCashier() {
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getBlockedCashier(cashier_id:${this.$store.state.cashier.cashier_id}){
                  is_active
                  is_allowed_anywhere
                }

              }
              `,
          },
        });

        this.isAllowedToCopy =
          cashierResult.data.data.getBlockedCashier[0].is_allowed_anywhere;

        if (!cashierResult.data.data.getBlockedCashier[0].is_active) {
          await this.logout();
          alert("Cashier is blocked");
        }
      } catch (err) {}
    },

    localReciept() {
      var content = this.$refs.printReceipt;
      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 140);

      this.recietDialog = false;
    },

    localCReciept() {
      var content = this.$refs.cprintReceipt;
      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 140);

      this.crecietDialog = false;
      this.cancelDialog = false;
      this.userIDToCancel = "";

      this.ticketToDelete = [];
      this.stakeToCancel = 0;
    },

    localSCReciept() {
      var content = this.$refs.scprintReceipt;
      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 140);

      this.screcietDialog = false;
      this.spinCancelDialog = false;
      this.spinUserIDToCancel = "";

      this.sticketToDelete = [];
      this.sstakeToCancel = 0;
    },

    localsReciept() {
      var content = this.$refs.sprintReceipt;
      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 140);

      this.srecietDialog = false;
    },

    async payWinnerAward(list, data) {
      this.selectedPrint = [];
      if (data == "single") {
        this.selectedPrint.push(list);

        if (list.includes(" Loss ")) this.selectedWinMoney = 0;
        else
          this.selectedWinMoney = parseFloat(
            list.split(" = ")[1].split(" Birr")[0]
          );
      } else {
        this.selectedWinMoney = this.totalWinMoneyForOneUser;
        this.selectedPrint = list;
      }

      if (data == "pay") {
        this.payLoading = true;
        try {
          var payResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               payWinnerAward(ticket_id:${this.verifyUserData[0].ticket_id},
               payer_cashier_id: ${this.$store.state.cashier.cashier_id},
               partner_id: ${this.$store.state.cashier.partner_id},
               user_id:"${this.verifyUserData[0].user_id}",
                winner_award: ${this.totalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
            },
          });

          if (
            payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
            "true"
          ) {
            this.redeemErrorMessage = "Redeemed Successfully";

            if (this.verifyUserData[0].getJackpotHistory.length > 0) {
              await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
              editJackpotHistory(jackpot_history_id:${this.verifyUserData[0].getJackpotHistory[0].jackpot_history_id},
              cashier_id: ${this.$store.state.cashier.cashier_id},
              partner_id: ${this.$store.state.cashier.partner_id},
           ){
                      statusMessage

                   }
              }
              `,
                },
              });
            }

            if (
              Number(this.verifyUserData[0].ticket_date) <
              new Date(this.formatDate(new Date(Date.now()))).getTime() -
                9688995
            ) {
              await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
                   addPaidFromYesterday(cashier_id:${this.$store.state.cashier.cashier_id},
                   user_id:"${this.verifyUserData[0].user_id}",
                    winner_award: ${this.totalWinMoneyForOneUser}){
                          statusMessage
    
                       }
                  }
                  `,
                },
              });
            }

            if (this.isRedeemPrint) {
              this.recietDialog = true;

              this.rand_str_generator();
              setTimeout(
                function () {
                  this.localReciept();
                }.bind(this),
                140
              );
            }

            this.winnersDialog = false;
            this.verifyDialog = true;
            await this.getBalance();
            this.userIDToVerify = "";
            this.totalWinMoneyForOneUser = 0;
            this.payableList = [];
            this.selectedPrint = [];
            this.selectedWinMoney = 0;
            this.verifyUserData = [];
          }
        } catch (err) {
          alert(err);
        }
        this.payLoading = false;
      } else {
        if (this.isRedeemPrint) {
          this.recietDialog = true;

          this.rand_str_generator();
          setTimeout(
            function () {
              this.localReciept();
            }.bind(this),
            140
          );
        }
      }
    },

    async printAgain(ticket) {
      this.copyMessage = "";
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedNumbers.push(numbers[i].split("*")[0].split(","));
          this.numberInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }
      this.userIDToCancel = ticket.user_id;

      await this.recietOverview(ticket.user_id);
      await this.cancelTicket();
    },

    async getCopy(ticket, gameNumber) {
      this.copyMessage = "copy";
      var numbers = ticket.choosen_numbers.split(":");

      var others = ticket.others.split(":");
      this.selectedNumbers = [];
      this.numberInputMoney = [];
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedNumbers.push(numbers[i].split("*")[0].split(","));
          this.numberInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }

      this.userNumber = ticket.user_id;
      this.gameDate = Number(ticket.ticket_date);
      this.gameNumberToCopy = gameNumber.game_number;
      this.gameDateToCopy = gameNumber.game_date;

      await this.copyOverview();
    },

    async spinPrintAgain(ticket) {
      this.copyMessage = "";
      var numbers = ticket.choosen_numbers.split(":");

      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedSpinNumbers.push(numbers[i].split("*")[0].trim());
          this.spinInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      this.spinUserIDToCancel = ticket.user_id;

      await this.spinRecietOverview(ticket.user_id);
      await this.spinCancelTicket();
    },
    async getSpinCopy(ticket, gameNumber) {
      this.copyMessage = "copy";
      var numbers = ticket.choosen_numbers.split(":");

      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedSpinNumbers.push(numbers[i].split("*")[0].trim());
          this.spinInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      this.spinUserNumber = ticket.user_id;
      this.spinGameDate = Number(ticket.ticket_date);
      this.gameNumberToCopy = gameNumber.game_number;
      this.gameDateToCopy = gameNumber.game_date;

      await this.spinCopyOverview();
    },
    async cancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.userIDToCancel = ticket.user_id;

        await this.cancelTicket();
        await this.getMoneyOnHands();
        this.userIDToCancel = "";
      } else alert("You cannot delete");
    },
    async cancelTicket() {
      if (this.userIDToCancel != "") {
        this.ccancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               cancelTicket(
               user_id:"${this.userIDToCancel}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.cancelDialog = false;
            this.userIDToCancel = "";

            this.ticketToDelete = [];
            this.cashierOption = false;
            this.stakeToCancel = 0;

            await this.getBalance();
            await this.getLastTwoGames();
          } else {
            this.ticketToDelete = [];
            this.stakeToCancel = 0;
            alert("You can not cancel");
            this.redeemErrorMessage =
              "You can not cancel, or already cancelled";
          }
        } catch (err) {
          alert(err);
        }

        this.ccancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async normalCancelTicket() {
      if (this.userIDToCancel != "") {
        this.redeemErrorMessage = "";
        this.ccancelLoading = true;

        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
                normalCancelTicket(
               user_id:"${this.userIDToCancel}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.normalCancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            if (this.isCancelPrint) {
              this.crecietDialog = true;

              this.rand_str_generator();
              setTimeout(
                function () {
                  this.localCReciept();
                }.bind(this),
                140
              );
            } else {
              this.crecietDialog = false;
              this.cancelDialog = false;
              this.userIDToCancel = "";

              this.ticketToDelete = [];
              this.stakeToCancel = 0;
            }

            await this.getBalance();
            await this.getLastTwoGames();
          } else {
            {
              this.ticketToDelete = [];
              this.stakeToCancel = 0;
              this.redeemErrorMessage =
                "You can not cancel, or already cancelled";
            }
          }
        } catch (err) {
          alert(err);
        }

        this.ccancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async getBalance() {
      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getPartnerById(partner_id:${this.$store.state.cashier.partner_id}){
                     tempo_package
                     permanent_package
                     partner_balance
                     credit_chance
                 
                     day_block_date
                     day_block
                    

                   }

              }
              `,
          },
        });
        this.partnerBalance = partnerBalanceResult.data.data.getPartnerById[0];
        this.day_block = 0;
        var sec = 24 * 60 * 60 * 1000;

        this.day_block = Math.round(
          this.partnerBalance.day_block -
            (Date.now() -
              Number(
                this.partnerBalance.day_block_date != ""
                  ? this.partnerBalance.day_block_date
                  : 0
              )) /
              sec
        );
      } catch (err) {}

      try {
        var branchResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getBranchById(branch_id:${this.$store.state.cashier.branch_id}){
                    is_active
                     branch_id
                     branch_name
                     shop_number
                     ticket_layout
                     logo
                    
                    

                   }

              }
              `,
          },
        });
        this.brachData = branchResult.data.data.getBranchById[0];
      } catch (err) {}
    },

    async verify() {
      if (this.userIDToVerify != "") {
        this.redeemErrorMessage = "";
        this.verifyUserData = [];
        if (
          Number(Date.now() - this.$store.state.sessionTime) >
          20 * 60 * 1000
        ) {
          this.$store.dispatch("loginCashier", "");
          this.$router.push({ name: "login" });
        } else {
          this.$store.dispatch("sessionTime", Date.now());
        }
        this.verifyLoading = true;
        await this.getOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
                
               verifyUser(
                branch_id: ${this.$store.state.cashier.branch_id},
                user_id:"${this.userIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled   

    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status

    }

    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }

                   }
              }
              `,
            },
          });
          this.verifyUserData = verifyResult.data.data.verifyUser;
          var totalWinOtherMoney = 0;
          var totalWinMoney = 0;
          this.payableList = [];

          if (this.verifyUserData.length > 0) {
            if (
              Number(this.verifyUserData[0].ticket_date) >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              if (this.verifyUserData[0].is_cancelled == 0) {
                if (this.verifyUserData[0].is_game_over == 1) {
                  if (this.verifyUserData[0].winner_award == 0) {
                    var tickets =
                      this.verifyUserData[0].choosen_numbers.split(":");
                    var others = this.verifyUserData[0].others.split(":");
                    if (
                      others[0] != "" &&
                      this.verifyUserData[0].getGame.length > 0
                    ) {
                      for (let r = 0; r < others.length; r++) {
                        if (
                          this.verifyUserData[0].getGame[0].high_low.toLowerCase() ==
                            others[r].split("*")[0].trim().toLowerCase() ||
                          this.verifyUserData[0].getGame[0].even_odd.toLowerCase() ==
                            others[r].split("*")[0].trim().toLowerCase()
                        ) {
                          for (
                            let x = 0;
                            x < this.$store.state.odds.length;
                            x++
                          ) {
                            if (
                              this.$store.state.odds[
                                x
                              ].choosen_length.toLowerCase() ==
                              others[r].split("*")[0].trim().toLowerCase()
                            ) {
                              this.payableList.push(
                                others[r] +
                                  " = " +
                                  (
                                    parseFloat(
                                      this.$store.state.odds[x].odd_value
                                    ) *
                                    parseFloat(others[r].split("*")[1].trim())
                                  ).toFixed(2) +
                                  " Birr Won "
                              );
                              totalWinOtherMoney =
                                totalWinOtherMoney +
                                parseFloat(
                                  this.$store.state.odds[x].odd_value
                                ) *
                                  parseFloat(others[r].split("*")[1].trim());
                            }
                          }
                        } else this.payableList.push(others[r] + " = Loss ");
                      }
                    }

                    if (
                      tickets[0] != "" &&
                      this.verifyUserData[0].getGame.length > 0
                    ) {
                      for (let i = 0; i < tickets.length; i++) {
                        let countWin = 0;
                        var eachNumber = tickets[i].split("*")[0].split(",");
                        var eachAmount = parseFloat(tickets[i].split("*")[1]);

                        var matchCount = 0;
                        var drownNumbers =
                          this.verifyUserData[0].getGame[0].drawn_numbers.split(
                            ","
                          );
                        for (let k = 0; k < eachNumber.length; k++) {
                          for (let j = 0; j < drownNumbers.length; j++) {
                            if (drownNumbers[j] == eachNumber[k].trim()) {
                              matchCount++;
                            }
                          }
                        }

                        for (
                          let x = 0;
                          x < this.$store.state.odds.length;
                          x++
                        ) {
                          if (
                            parseInt(
                              this.$store.state.odds[x].choosen_length
                            ) == eachNumber.length
                          ) {
                            var hits =
                              this.$store.state.odds[x].hits.split(",");
                            var possibleWinMoney =
                              this.$store.state.odds[x].odd_value.split(",");

                            for (let y = 0; y < hits.length; y++) {
                              if (matchCount == parseInt(hits[y].trim())) {
                                countWin++;
                                this.payableList.push(
                                  tickets[i] +
                                    " = " +
                                    (
                                      (parseFloat(possibleWinMoney[y].trim()) *
                                        eachAmount) /
                                      10
                                    ).toFixed(2) +
                                    " Birr Won"
                                );
                                totalWinMoney =
                                  totalWinMoney +
                                  parseFloat(possibleWinMoney[y].trim()) *
                                    eachAmount;
                              }
                            }
                          }
                        }

                        if (countWin == 0)
                          this.payableList.push(tickets[i] + " = Loss ");
                      }
                    }

                    this.totalWinMoneyForOneUser =
                      totalWinMoney / 10 + totalWinOtherMoney;
                  } else
                    this.redeemErrorMessage = "Betslip previously redeemed";
                } else this.redeemErrorMessage = "Game is not over";
              } else this.redeemErrorMessage = "Betslip is cancelled";
            } else this.redeemErrorMessage = "Betslip expired";
          } else this.redeemErrorMessage = "Betslip not found.";
        } catch (err) {
          alert(err);
        }
        this.verifyLoading = false;
      } else this.redeemErrorMessage = "Please scan Bar code or enter user id";
    },

    async getTicketToCancel() {
      this.cancelLoading = true;
      this.redeemErrorMessage = "";
      try {
        var verifyResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                
                getTicket(
                branch_id: ${this.$store.state.cashier.branch_id},
                user_id:"${this.userIDToCancel}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled   

    getGameForShifts{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status

    }

    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }

                   }
              }
              `,
          },
        });
        this.ticketToDelete = verifyResult.data.data.getTicket;

        if (this.ticketToDelete.length > 0) {
          this.stakeToCancel = 0;
          if (this.ticketToDelete[0].choosen_numbers.split(":")[0] != "") {
            for (
              let i = 0;
              i < this.ticketToDelete[0].choosen_numbers.split(":").length;
              i++
            ) {
              this.stakeToCancel =
                this.stakeToCancel +
                parseFloat(
                  this.ticketToDelete[0].choosen_numbers
                    .split(":")
                    [i].split("*")[1]
                );
            }
          }

          if (this.ticketToDelete[0].others.split(":")[0] != "") {
            for (
              let i = 0;
              i < this.ticketToDelete[0].others.split(":").length;
              i++
            ) {
              this.stakeToCancel =
                this.stakeToCancel +
                parseFloat(
                  this.ticketToDelete[0].others.split(":")[i].split("*")[1]
                );
            }
          }
        } else this.redeemErrorMessage = "Betslip not found.";
      } catch (err) {
        alert(err);
      }

      this.cancelLoading = false;
    },

    async sgetTicketToCancel() {
      this.cancelLoading = true;
      this.redeemErrorMessage = "";
      try {
        var verifyResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                
                getTicket(
                branch_id: ${this.$store.state.cashier.branch_id},
                user_id:"${this.spinUserIDToCancel}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled   

    getGameForShifts{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status

    }

    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }

                   }
              }
              `,
          },
        });
        this.sticketToDelete = verifyResult.data.data.getTicket;

        if (this.sticketToDelete.length > 0) {
          this.sstakeToCancel = 0;
          if (this.sticketToDelete[0].choosen_numbers.split(":")[0] != "") {
            for (
              let i = 0;
              i < this.sticketToDelete[0].choosen_numbers.split(":").length;
              i++
            ) {
              this.sstakeToCancel =
                this.sstakeToCancel +
                parseFloat(
                  this.sticketToDelete[0].choosen_numbers
                    .split(":")
                    [i].split("*")[1]
                );
            }
          }
        } else this.redeemErrorMessage = "Betslip not found.";
      } catch (err) {
        alert(err);
      }

      this.cancelLoading = false;
    },

    async getMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      var paidFromCashier = 0;
      var paidFromCashierTicket = 0;

      try {
        var paidForOtherCashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPaidFromOtherCashier(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand =
          paidForOtherCashierResult.data.data.getPaidFromOtherCashier;

        for (let t = 0; t < moneyOnHand.length; t++) {
          paidFromCashier = paidFromCashier + moneyOnHand[t].winner_award;

          paidFromCashierTicket = paidFromCashierTicket + 1;
        }
      } catch (err) {}
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.totalMoneyOnHand = 0;
        this.totalMoneyPaid = 0;
        this.noTickets = 0;
        this.noPaidTickets = 0;
        this.noCancelledTickets = 0;
        this.noBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");
            var others = moneyOnHand[t].others.split(":");
            if (tickets[0] != "") {
              this.noBets = this.noBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.noBets = this.noBets + others.length;
              for (let k = 0; k < others.length; k++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(others[k].split("*")[1]);
              }
            }
          }

          if (
            moneyOnHand[t].winner_award != 0 &&
            moneyOnHand[t].cashier_id == moneyOnHand[t].payer_cashier_id
          ) {
            this.totalMoneyPaid =
              this.totalMoneyPaid + moneyOnHand[t].winner_award;

            this.noPaidTickets = this.noPaidTickets + 1;
          }

          if (moneyOnHand[t].is_cancelled == 1) {
            this.noCancelledTickets = this.noCancelledTickets + 1;
          } else this.noTickets = this.noTickets + 1;
        }

        this.totalMoneyPaid = this.totalMoneyPaid + paidFromCashier;

        this.noPaidTickets = this.noPaidTickets + paidFromCashierTicket;
      } catch (err) {}

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyFromYesterday(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterday;
        this.paidKenoMoneyFromYesterday = 0;
        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          this.paidKenoMoneyFromYesterday =
            this.paidKenoMoneyFromYesterday +
            paidMoneyFromYesterday[k].winner_award;
        }
      } catch (err) {}
      var jack = 0;

      try {
        var paidMoneyJackpotdayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
              getJackpotPaidMoney(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                jackpot_history_id     
                amount
                   }
              }
              `,
          },
        });

        var paidMoneyFromJackpot =
          paidMoneyJackpotdayResult.data.data.getJackpotPaidMoney;

        for (let k = 0; k < paidMoneyFromJackpot.length; k++) {
          jack = jack + paidMoneyFromJackpot[k].amount;
        }
      } catch (err) {}

      try {
        var paidMoneyJackpotResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
              getJackpotPaidMoney(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                jackpot_history_id     
                amount
                   }
              }
              `,
          },
        });

        var paidMoneyFromJackpot =
          paidMoneyJackpotResult.data.data.getJackpotPaidMoney;

        for (let k = 0; k < paidMoneyFromJackpot.length; k++) {
          jack = jack + paidMoneyFromJackpot[k].amount;
        }
      } catch (err) {}

      this.paidJackpotMoney = jack;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    formatDateS(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("/");
    },
    async logout() {
      this.logoutLoading = true;
      try {
        var loggedinResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               loggedInCashier(
                cashier_id:${this.$store.state.cashier.cashier_id},
                is_loggedin:0
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        if (
          loggedinResult.data.data.loggedInCashier[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.logoutDialog) {
            try {
              var cashierReportResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               addCashierReport(
                cashier_name:"${
                  this.$store.state.cashier.cashier_full_name
                }",              
                loggin_time: "${this.$store.state.cashier.loggin_time}",
          
                logout_time: "${Date.now()}",
                locationCoordinat: "${this.currentLocation}",
                ip_address: "${this.ipAddress}",
                total_tickets: ${this.noTickets + this.spinNoTickets},
                paid_tickets: ${this.noPaidTickets + this.spinNoPaidTickets},
                cancelled_tickets: ${
                  this.noCancelledTickets + this.spinNoCancelledTickets
                },
                number_bets: ${this.noBets + this.spinNoBets},
          
                total_money: ${
                  this.totalMoneyOnHand + this.spinTotalMoneyOnHand
                },
                paid_money: ${
                  this.totalMoneyPaid +
                  this.spinTotalMoneyPaid +
                  this.paidKenoMoneyFromYesterday +
                  this.paidSpinMoneyFromYesterday
                },
                onhand_money: ${
                  this.totalMoneyOnHand -
                  this.totalMoneyPaid -
                  this.paidKenoMoneyFromYesterday +
                  (this.spinTotalMoneyOnHand -
                    this.spinTotalMoneyPaid -
                    this.paidSpinMoneyFromYesterday)
                },
                   )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                cashierReportResult.data.data.addCashierReport[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printReport;

                var newWindow = window.open("");
                this.logoutDialog = false;

                newWindow.document.write(content.outerHTML);

                newWindow.print();
                newWindow.close();

                this.$router.push({ name: "login" });
                this.$store.dispatch("loginCashier", "");

                this.$router.go();
              } else alert("Some thing went wrong, contact the admin");
            } catch (err) {
              alert(err);
            }
          }

          this.$router.push({ name: "login" });
          this.$store.dispatch("loginCashier", "");

          this.$router.go();
        } else alert("Some thing went wrong, contact the admin");
      } catch (err) {
        alert(err);
      }
      this.logoutLoading = false;
    },

    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords.latitude + "," + position.coords.longitude);
          },
          (error) => {
            console.log(error.message);
          }
        );
      });
      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async logoutClicked() {
      this.ipAddress = await this.getIPAddress();
      this.logoutDialog = true;
    },

    //spin this.spinCurrentMousePosition= this.selectedSpinNumbers.length-1

    spinAssignSelectedAmount(amount) {
      this.spinSelectedCustomAmount = amount;
      if (this.selectedSpinNumbers.length > 0)
        this.spinInputMoney[this.spinCurrentMousePosition] =
          this.spinSelectedCustomAmount;
    },

    spinApplyToAll() {
      this.spinInputMoney = [];

      for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
        this.spinInputMoney.push(this.spinSelectedCustomAmount);
      }
    },

    spinclearAll() {
      this.spinInputMoney = [];
      this.spinSelectedCustomAmount = 0;
    },
    selectSpinNumbers(number) {
      this.spnumPosition = 1009;
      if (this.selectedSpinNumbers.length == 0) {
        this.spinIntest = 0;
      }
      if (this.selectedSpinNumbers.indexOf(number) != -1) {
        var ind = this.selectedSpinNumbers.indexOf(number);
        this.selectedSpinNumbers.splice(ind, 1);

        this.spinInputMoney.splice(ind, 1);
      } else {
        this.spinInputMoney.push(10);
        this.selectedSpinNumbers.push(number.toString());
      }

      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    spinRemoveSelectedNumbers(index) {
      this.spnumPosition = 1009;
      this.selectedSpinNumbers.splice(index, 1);
      this.spinInputMoney.splice(index, 1);
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    chooseNeighbor(number) {
      this.spnumPosition = 1009;
      if (this.selectedSpinNumbers.length == 0) {
        this.spinIntest = 0;
      }
      var splitedNumbers = "";
      for (let i = 0; i < this.spinNumbers.length; i++) {
        if (number == this.spinNumbers[i].number) {
          splitedNumbers =
            this.spinNumbers[i].neighbors.split(",")[0].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[1].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[2].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[3].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[4].split(" ")[0].trim();
        }
      }

      if (
        this.selectedSpinNumbers.indexOf(
          number.toString() + " Neighbors (" + splitedNumbers + ")"
        ) != -1
      ) {
        var ind = this.selectedSpinNumbers.indexOf(
          number.toString() + " Neighbors (" + splitedNumbers + ")"
        );
        this.selectedSpinNumbers.splice(ind, 1);

        this.spinInputMoney.splice(ind, 1);
      } else {
        this.spinInputMoney.push(10);
        this.selectedSpinNumbers.push(
          number.toString() + " Neighbors (" + splitedNumbers + ")"
        );
      }

      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    chooseTwoNumbers(number) {
      this.spnumPosition = 1009;
      if (this.twoNumbers.length < 2) {
        if (this.twoNumbers.indexOf(number) == -1) {
          this.twoNumbers.push(number);
        }
      }
    },

    generateRandomBet() {
      this.spnumPosition = 1009;
      var index = Math.floor(Math.random() * this.randomGame.length);

      if (this.randomGame[index] == "Numbers") {
        var drownNumbers = Math.floor(Math.random() * 36) + 1;
        this.selectedSpinNumbers.push(drownNumbers.toString());
      } else if (this.randomGame[index] == "Neighbours") {
        var drownNumbers = Math.floor(Math.random() * (36 + 1));
        var splitedNumbers = "";
        for (let i = 0; i < this.spinNumbers.length; i++) {
          if (drownNumbers == this.spinNumbers[i].number) {
            splitedNumbers =
              this.spinNumbers[i].neighbors.split(",")[0].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[1].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[2].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[3].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[4].split(" ")[0].trim();
          }
        }
        this.selectedSpinNumbers.push(
          drownNumbers.toString() + " Neighbors (" + splitedNumbers + ")"
        );
      } else {
        this.selectedSpinNumbers.push(this.randomGame[index]);
      }
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    async spinRecietOverview(id) {
      this.spnumPosition = 1009;
      if (Number(Date.now() - this.$store.state.sessionTime) > 20 * 60 * 1000) {
        this.$store.dispatch("loginCashier", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      this.copyMessage = "";
      let totalMoney = 0;
      this.spinTotalPossibleWin = 0;
      var emptyCounter = 0;
      for (let v = 0; v < this.spinInputMoney.length; v++) {
        if (this.spinInputMoney[v] < 10 || this.spinInputMoney[v] > 2000)
          emptyCounter++;
      }

      for (let m = 0; m < this.spinInputMoney.length; m++) {
        if (this.spinInputMoney[m] == "" || this.spinInputMoney[m] == undefined)
          emptyCounter++;
      }
      if (
        this.selectedSpinNumbers.length == this.spinInputMoney.length &&
        emptyCounter == 0
      ) {
        this.spinUserNumber = Date.now();
        this.spinGameDate = Date.now();
        var choosenNumbers = "";

        if (
          this.formatDate(Date.now()) != this.$store.state.spinTicketNumberDate
        ) {
          this.$store.dispatch(
            "spinTicketNumberDate",
            this.formatDate(Date.now())
          );

          this.$store.dispatch("spinTicketNumber", 0);
        }
        this.$store.dispatch(
          "spinTicketNumber",
          this.$store.state.spinTicketNumber + 1
        );

        for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
          totalMoney = totalMoney + parseFloat(this.spinInputMoney[i]);
          // for (let j = 0; j < this.$store.state.odds.length; j++) {
          if (this.selectedSpinNumbers[i].includes("Neighbors")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].neighbors;
          } else if (this.selectedSpinNumbers[i].includes("&")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low_color;
          } else if (this.selectedSpinNumbers[i].includes("Donzen")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].dozen;
          } else if (this.selectedSpinNumbers[i].includes("Sector")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].sector;
          } else if (this.selectedSpinNumbers[i].includes("Final")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].final;
          } else if (
            this.selectedSpinNumbers[i] == "Red" ||
            this.selectedSpinNumbers[i] == "Black" ||
            this.selectedSpinNumbers[i] == "High" ||
            this.selectedSpinNumbers[i] == "Low" ||
            this.selectedSpinNumbers[i] == "Even" ||
            this.selectedSpinNumbers[i] == "Odd"
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low;
          } else if (this.selectedSpinNumbers[i].includes("Green")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          } else if (this.selectedSpinNumbers[i].includes("Twins")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].twins;
          } else if (this.selectedSpinNumbers[i].includes("|")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].mirror;
          } else if (
            this.selectedSpinNumbers[i].includes(",") &&
            this.selectedSpinNumbers[i].split(",").length == 2
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              (parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number) /
                2;
          } else if (!isNaN(this.selectedSpinNumbers[i])) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          }
          var finalNumbers = this.selectedSpinNumbers[i];
          if (finalNumbers.includes("Neighbors")) {
            finalNumbers = finalNumbers.split("(")[0].trim();
          }
          if (choosenNumbers == "") {
            choosenNumbers = finalNumbers + "*" + this.spinInputMoney[i];
          } else
            choosenNumbers =
              choosenNumbers +
              ":" +
              finalNumbers +
              "*" +
              this.spinInputMoney[i];
        }

        if (this.partnerBalance.tempo_package >= totalMoney) {
          this.spinTotalInputMoney = totalMoney;
          this.spinPrintDialog = true;

          try {
            var ticketResult = await axios({
              method: "POST",
              url: this.$store.state.spinCashierURL,

              data: {
                query: `mutation{
             saveTicket(
              game_id:${this.spinCurrentGame[0].game_id},
             user_id:"${this.spinUserNumber}",
             ticket_number:${this.$store.state.spinTicketNumber},
             cashier_id:${this.$store.state.cashier.cashier_id},
             partner_id:${this.$store.state.cashier.partner_id},
             branch_id:${this.$store.state.cashier.branch_id},
             choosen_numbers: "${choosenNumbers}",
             others: "${""}",
             replaced_user_id: "${id}",
             ticket_date: "${this.spinGameDate}",
             tempo_package:${this.partnerBalance.tempo_package - totalMoney}

             )
              {
                    statusMessage

                 }
            }
            `,
              },
            });

            if (
              ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
              "true"
            ) {
              await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
             updatePackageAfterTicket(  
              partner_id:${this.$store.state.cashier.partner_id},           
             tempo_package:${this.partnerBalance.tempo_package - totalMoney}

             )
              {
                    statusMessage

                 }
            }
            `,
                },
              });

              var content = this.$refs.spinPrintable;

              var newWindow = window.open("");

              newWindow.document.write(content.outerHTML);
              setTimeout(function () {
                newWindow.print();
                newWindow.close();
              }, 140);
              this.spinPrintDialog = false;

              await this.getBalance();
              await this.getLastTwoSpinGames();
              this.selectedSpinNumbers = [];
              this.successfullyPlaced = "Bet successfully placed!";
              this.spinInputMoney = [];
            } else alert("Ticket not printed, try again");
          } catch (err) {
            alert(err);
          }
        } else alert("Insufficient funds, please deposit to continue");
      } else
        alert(
          "Please fill all fields, amounts And make sure the amount is between 10 to 2000 birr"
        );
    },

    async spinCopyOverview() {
      this.copyMessage = "copy";
      let totalMoney = 0;
      this.spinTotalPossibleWin = 0;
      var emptyCounter = 0;
      for (let v = 0; v < this.spinInputMoney.length; v++) {
        if (this.spinInputMoney[v] < 10 || this.spinInputMoney[v] > 2000)
          emptyCounter++;
      }

      for (let m = 0; m < this.spinInputMoney.length; m++) {
        if (this.spinInputMoney[m] == "" || this.spinInputMoney[m] == undefined)
          emptyCounter++;
      }
      if (
        this.selectedSpinNumbers.length == this.spinInputMoney.length &&
        emptyCounter == 0
      ) {
        var choosenNumbers = "";

        for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
          totalMoney = totalMoney + parseFloat(this.spinInputMoney[i]);
          // for (let j = 0; j < this.$store.state.odds.length; j++) {
          if (this.selectedSpinNumbers[i].includes("Neighbors")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].neighbors;
          } else if (this.selectedSpinNumbers[i].includes("&")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low_color;
          } else if (this.selectedSpinNumbers[i].includes("Donzen")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].dozen;
          } else if (this.selectedSpinNumbers[i].includes("Sector")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].sector;
          } else if (this.selectedSpinNumbers[i].includes("Final")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].final;
          } else if (
            this.selectedSpinNumbers[i] == "Red" ||
            this.selectedSpinNumbers[i] == "Black" ||
            this.selectedSpinNumbers[i] == "High" ||
            this.selectedSpinNumbers[i] == "Low" ||
            this.selectedSpinNumbers[i] == "Even" ||
            this.selectedSpinNumbers[i] == "Odd" ||
            this.selectedSpinNumbers == "TAILS" ||
            this.selectedSpinNumbers == "HEADS" ||
            this.selectedSpinNumbers == "EVENS"
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low;
          } else if (this.selectedSpinNumbers[i].includes("Green")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          } else if (this.selectedSpinNumbers[i].includes("Twins")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].twins;
          } else if (this.selectedSpinNumbers[i].includes("|")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].mirror;
          } else if (
            this.selectedSpinNumbers[i].includes(",") &&
            this.selectedSpinNumbers[i].split(",").length == 2
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              (parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number) /
                2;
          } else if (!isNaN(this.selectedSpinNumbers[i])) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          }
          var finalNumbers = this.selectedSpinNumbers[i];
          if (finalNumbers.includes("Neighbors")) {
            finalNumbers = finalNumbers.split("(")[0].trim();
          }
          if (choosenNumbers == "") {
            choosenNumbers = finalNumbers + "*" + this.spinInputMoney[i];
          } else
            choosenNumbers =
              choosenNumbers +
              ":" +
              finalNumbers +
              "*" +
              this.spinInputMoney[i];
        }

        this.spinTotalInputMoney = totalMoney;
        this.spinPrintDialog = true;

        setTimeout(
          function () {
            this.printSpinCopy();
          }.bind(this),
          140
        );
      } else
        alert(
          "Please fill all fields, amounts And make sure the amount is between 10 to 2000 birr"
        );
    },

    printSpinCopy() {
      try {
        var content = this.$refs.spinPrintable;

        var newWindow = window.open("");

        newWindow.document.write(content.outerHTML);
        setTimeout(function () {
          newWindow.print();
          newWindow.close();
        }, 140);
        this.spinPrintDialog = false;

        this.selectedSpinNumbers = [];

        this.spinInputMoney = [];
        this.copyMessage = "";
      } catch (err) {
        alert(err);
      }
    },
    async getCurrentSpinGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status

                   }
              }
              `,
          },
        });
        this.spinCurrentGame =
          currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.spinCurrentGame.length > 0) {
          this.spinTimeInSeconds = Math.floor(
            212 -
              (Date.now() - Number(this.spinCurrentGame[0].game_date)) / 1000
          );

          this.spinTimePercent =
            0.4717 *
            Math.floor(
              (Date.now() - Number(this.spinCurrentGame[0].game_date)) / 1000
            );
        } else {
          this.spinTimePercent = 0;
          this.spinMinutes = "00";
          this.spinSeconds = "00";
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },

    async getLastTwoSpinGames() {
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }

                       getLastTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                         getGame{
                          game_id
                          game_number
                          game_date
                           }
                       }
                     }


              }
              `,
          },
        });

        this.spinLastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.spinLastTwoGames.length > 0) {
          var lastGame = this.spinLastTwoGames[0].getTickets;
          this.spinIsDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.spinIsDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.spinIsDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.spinIsDuplicated = true;
                }
              }
            }
          }
        }

        // eslint-disable-next-line no-empty
      } catch (err) {}
    },

    async spinGetOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                getOdds{
                  	odds_id
		                number
		                color
		                mirror
		                twins
		                final
		                dozen

		                sector
		                high_low_color
		                even_odd
		                high_low
		                neighbors

                }

              }
              `,
          },
        });

        this.$store.dispatch("spinOdds", oddsResult.data.data.getOdds);
        // eslint-disable-next-line no-empty
      } catch (err) {}

      try {
        var spinnumbersResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                getSpinNumbers{
                   spin_number_id
                   number                 
                   neighbors
                    }
              }
              `,
          },
        });

        this.spinNumbers = spinnumbersResult.data.data.getSpinNumbers;
      } catch (err) {}
    },

    async spinReportTicket() {
      if (this.spinUserIdForReport != "" && this.spinReportReason != "") {
        this.spinReportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.spinUserIdForReport}",
              reason: "${this.spinReportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.spinReportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.spinReportLoading = false;
      } else alert("Please fill the fields");
    },

    async spinPayWinnerAward(list, data) {
      this.spinSelectedPrint = [];
      if (data == "single") {
        this.spinSelectedPrint.push(list);

        if (list.includes(" Loss ")) this.spinSelectedWinMoney = 0;
        else
          this.spinSelectedWinMoney = parseFloat(
            list.split(" = ")[1].split(" Birr")[0]
          );
      } else {
        this.spinSelectedWinMoney = this.spinTotalWinMoneyForOneUser;
        this.spinSelectedPrint = list;
      }
      if (data == "pay") {
        this.spinPayLoading = true;
        try {
          var payResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
               payWinnerAward(ticket_id:${this.spinVerifyUserData[0].ticket_id},
               payer_cashier_id: ${this.$store.state.cashier.cashier_id},
               user_id:"${this.spinVerifyUserData[0].user_id}",
                winner_award: ${this.spinTotalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
            "true"
          ) {
            this.redeemErrorMessage = "Redeemed Successfully";
            if (this.spinVerifyUserData[0].getJackpotHistory.length > 0) {
              await axios({
                method: "POST",
                url: this.$store.state.spinCashierURL,

                data: {
                  query: `mutation{
              editJackpotHistory(jackpot_history_id:${this.spinVerifyUserData[0].getJackpotHistory[0].jackpot_history_id},
              cashier_id: ${this.$store.state.cashier.cashier_id},
              partner_id: ${this.$store.state.cashier.partner_id},
           ){
                      statusMessage

                   }
              }
              `,
                },
              });
            }
            await axios({
              method: "POST",
              url: this.$store.state.cashierURL,

              data: {
                query: `mutation{
             updatePackageAfterTicket(
              partner_id:${this.$store.state.cashier.partner_id},              
             tempo_package:${
               this.partnerBalance.tempo_package +
               this.spinTotalWinMoneyForOneUser
             }

             )
              {
                    statusMessage

                 }
            }
            `,
              },
            });

            if (
              Number(this.spinVerifyUserData[0].ticket_date) <
              new Date(this.formatDate(new Date(Date.now()))).getTime() -
                9688995
            ) {
              await axios({
                method: "POST",
                url: this.$store.state.spinCashierURL,

                data: {
                  query: `mutation{
                   addPaidFromYesterday(cashier_id:${this.$store.state.cashier.cashier_id},
                   user_id:"${this.spinVerifyUserData[0].user_id}",
                    winner_award: ${this.spinTotalWinMoneyForOneUser}){
                          statusMessage
    
                       }
                  }
                  `,
                },
              });
            }

            if (this.isRedeemPrint) {
              this.srecietDialog = true;

              this.rand_str_generator();
              setTimeout(
                function () {
                  this.localsReciept();
                }.bind(this),
                140
              );
            }

            this.spinWinnersDialog = false;
            this.SpinVerifyDialog = true;
            await this.getBalance();
            this.spinUserIDToVerify = "";

            this.spinTotalWinMoneyForOneUser = 0;
            this.payableList = [];
            this.spinSelectedPrint = [];
            this.spinSelectedWinMoney = 0;
            this.spinVerifyUserData = [];
          }
        } catch (err) {
          alert(err);
        }

        this.spinPayLoading = false;
      } else {
        if (this.isRedeemPrint) {
          this.srecietDialog = true;

          this.rand_str_generator();
          setTimeout(
            function () {
              this.localsReciept();
            }.bind(this),
            140
          );
        }
      }
    },
    async spinCancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.spinUserIDToCancel = ticket.user_id;

        await this.spinCancelTicket();
        await this.getSpinMoneyOnHands();
        this.spinUserIDToCancel = "";
      } else alert("You cannot delete");
    },
    async spinCancelTicket() {
      if (this.spinUserIDToCancel != "") {
        this.spinCancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
               cancelTicket(
               user_id:"${this.spinUserIDToCancel}",
               tempo_package:${this.partnerBalance.tempo_package},
               cashierURL: "${this.$store.state.cashierURL}",  
               ){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.spinCancelDialog = false;
            this.spinUserIDToCancel = "";

            await this.getBalance();
            await this.getLastTwoSpinGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.spinCancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async normalSpinCancelTicket() {
      if (this.spinUserIDToCancel != "") {
        this.spinCancelLoading = true;
        this.redeemErrorMessage = "";
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
                normalSpinCancelTicket(
               user_id:"${this.spinUserIDToCancel}",
               tempo_package:${this.partnerBalance.tempo_package},
               cashierURL: "${this.$store.state.cashierURL}",  
               ){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.normalSpinCancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            if (this.isCancelPrint) {
              this.screcietDialog = true;

              this.rand_str_generator();
              setTimeout(
                function () {
                  this.localSCReciept();
                }.bind(this),
                140
              );
            } else {
              this.screcietDialog = false;
              this.spinCancelDialog = false;
              this.spinUserIDToCancel = "";

              this.sticketToDelete = [];
              this.sstakeToCancel = 0;
            }

            await this.getBalance();
            await this.getLastTwoSpinGames();
          } else {
            this.sticketToDelete = [];
            this.sstakeToCancel = 0;
            this.redeemErrorMessage =
              "You can not cancel, or already cancelled";
          }
        } catch (err) {
          alert(err);
        }

        this.spinCancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async spinVerify() {
      this.redeemErrorMessage = "";
      if (this.spinUserIDToVerify != "") {
        if (
          Number(Date.now() - this.$store.state.sessionTime) >
          20 * 60 * 1000
        ) {
          this.$store.dispatch("loginCashier", "");
          this.$router.push({ name: "login" });
        } else {
          this.$store.dispatch("sessionTime", Date.now());
        }

        this.spinVerifyLoading = true;
        await this.spinGetOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `{
               verifyUser(
                branch_id: ${this.$store.state.cashier.branch_id},
                user_id:"${this.spinUserIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled  
    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }
    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status
      getSpinNumber{
        spin_number_id
        number
        color
        mirror
        twins
        final
        dozen
        sector
        combination
        even_odd
        high_low
        neighbors
      }

    }

                   }
              }
              `,
            },
          });
          this.spinVerifyUserData = verifyResult.data.data.verifyUser;

          var totalPossibleWinMoney = 0;
          this.payableList = [];

          if (this.spinVerifyUserData.length > 0) {
            if (
              Number(this.spinVerifyUserData[0].ticket_date) >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              if (this.spinVerifyUserData[0].is_cancelled == 0) {
                if (this.spinVerifyUserData[0].is_game_over == 1) {
                  if (this.spinVerifyUserData[0].winner_award == 0) {
                    var tickets =
                      this.spinVerifyUserData[0].choosen_numbers.split(":");
                    var spinNum =
                      this.spinVerifyUserData[0].getGame[0].getSpinNumber;
                    var rowOut2 = this.$store.state.spinOdds;
                    if (
                      tickets[0] != "" &&
                      this.spinVerifyUserData[0].getGame.length > 0
                    ) {
                      for (let i = 0; i < tickets.length; i++) {
                        let countWin = 0;
                        var eachNumber = tickets[i].split("*")[0].trim();

                        var eachAmount = parseFloat(tickets[i].split("*")[1]);

                        if (spinNum[0].number.toString() == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].number * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].number * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (eachNumber.includes(",")) {
                          if (
                            eachNumber.split(",")[0].trim() ==
                              spinNum[0].number.toString() ||
                            eachNumber.split(",")[1].trim() ==
                              spinNum[0].number.toString()
                          ) {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              (rowOut2[0].number / 2) * eachAmount;

                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (
                                  (rowOut2[0].number / 2) *
                                  eachAmount
                                ).toString() +
                                " Birr Won"
                            );
                          }
                        } else if (spinNum[0].color == eachNumber) {
                          if (eachNumber == "0 Green") {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              rowOut2[0].number * eachAmount;

                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (rowOut2[0].number * eachAmount).toString() +
                                " Birr Won"
                            );
                          } else {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              rowOut2[0].high_low * eachAmount;
                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (rowOut2[0].high_low * eachAmount).toString() +
                                " Birr Won"
                            );
                          }
                        } else if (spinNum[0].sector == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].sector * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].sector * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].even_odd == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].even_odd * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].even_odd * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].high_low == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].high_low * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].high_low * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (
                          spinNum[0].neighbors.split(",")[0].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[1].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[2].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[3].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[4].trim() ==
                            eachNumber
                        ) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].neighbors * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].neighbors * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].twins == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].twins * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].twins * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].dozen == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].dozen * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].dozen * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (eachNumber.includes("Final")) {
                          if (spinNum[0].final.includes(eachNumber)) {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              rowOut2[0].final * eachAmount;

                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (rowOut2[0].final * eachAmount).toString() +
                                " Birr Won"
                            );
                          }
                        } else if (spinNum[0].mirror == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].mirror * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].mirror * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].combination == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].high_low_color * eachAmount;

                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].high_low_color * eachAmount).toFixed(
                                2
                              ) +
                              " Birr Won"
                          );
                        }

                        if (countWin == 0)
                          this.payableList.push(tickets[i] + " = Loss ");
                      }
                    }

                    this.spinTotalWinMoneyForOneUser = totalPossibleWinMoney;
                  } else
                    this.redeemErrorMessage = "Betslip previously redeemed";
                } else this.redeemErrorMessage = "Game is not over";
              } else this.redeemErrorMessage = "Betslip is cancelled";
            } else this.redeemErrorMessage = "Betslip expired";
          } else this.redeemErrorMessage = "Betslip not found.";
        } catch (err) {
          alert(err);
        }
        this.spinVerifyLoading = false;
      } else this.redeemErrorMessage = "Please scan Bar code or enter user id";
    },

    async getSpinMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      var paidFromCashier = 0;
      var paidFromCashierTicket = 0;
      try {
        var paidForOtherCashierResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getPaidFromOtherCashier(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand =
          paidForOtherCashierResult.data.data.getPaidFromOtherCashier;

        for (let t = 0; t < moneyOnHand.length; t++) {
          paidFromCashier = paidFromCashier + moneyOnHand[t].winner_award;
          paidFromCashierTicket = paidFromCashierTicket + 1;
        }
      } catch (err) {}
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.spinTotalMoneyOnHand = 0;
        this.spinTotalMoneyPaid = 0;
        this.spinNoTickets = 0;
        this.spinNoPaidTickets = 0;
        this.spinNoCancelledTickets = 0;
        this.spinNoBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");
            var others = moneyOnHand[t].others.split(":");
            if (tickets[0] != "") {
              this.spinNoBets = this.spinNoBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.spinTotalMoneyOnHand =
                  this.spinTotalMoneyOnHand +
                  parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.spinNoBets = this.spinNoBets + others.length;
              for (let k = 0; k < others.length; k++) {
                this.spinTotalMoneyOnHand =
                  this.spinTotalMoneyOnHand +
                  parseFloat(others[k].split("*")[1]);
              }
            }
          }

          if (
            moneyOnHand[t].winner_award != 0 &&
            moneyOnHand[t].payer_cashier_id == moneyOnHand[t].cashier_id
          ) {
            this.spinTotalMoneyPaid =
              this.spinTotalMoneyPaid + moneyOnHand[t].winner_award;
            this.spinNoPaidTickets = this.spinNoPaidTickets + 1;
          }

          if (moneyOnHand[t].is_cancelled == 1) {
            this.spinNoCancelledTickets = this.spinNoCancelledTickets + 1;
          } else this.spinNoTickets = this.spinNoTickets + 1;
        }
        this.spinTotalMoneyPaid = this.spinTotalMoneyPaid + paidFromCashier;

        this.spinNoPaidTickets = this.spinNoPaidTickets + paidFromCashierTicket;

        // eslint-disable-next-line no-empty
      } catch (err) {}

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getMoneyFromYesterday(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterday;
        this.paidSpinMoneyFromYesterday = 0;
        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          this.paidSpinMoneyFromYesterday =
            this.paidSpinMoneyFromYesterday +
            paidMoneyFromYesterday[k].winner_award;
        }
      } catch (err) {}
    },
  },

  async created() {
    if (this.$store.state.cashier != "") {
      this.date1 =
        this.formatDateS(new Date(Date.now() - 24 * 60 * 60 * 1000)) +
        " 00:00:00";
      this.date2 =
        this.formatDateS(new Date(Date.now() + 24 * 60 * 60 * 1000)) +
        " 00:00:00";

      this.date1res = this.formatDateS(new Date(Date.now())) + " 00:00:00";
      this.date2res =
        this.formatDateS(new Date(Date.now() + 24 * 60 * 60 * 1000)) +
        " 00:00:00";
      this.neighborsList = this.spinAllNums;

      this.neighborsList.sort((a, b) => a.num - b.num);
      this.whichIcon = this.gameItems[5];
      if (Number(Date.now() - this.$store.state.sessionTime) > 20 * 60 * 1000) {
        this.$store.dispatch("loginCashier", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }

      this.getHelp();
      this.getCommonFeatures();
      this.getBalance();

      await this.getOdds();
      await this.spinGetOdds();
      this.getMoneyOnHands();
      this.getSpinMoneyOnHands();

      setInterval(async () => {
        if (this.copyMessage != "copy") this.watchData();
        await this.getCurrentGame();

        setTimeout(async () => {
          axios.get(
            this.$store.state.cashierURL.split("graphql")[0] + "getApp"
          );
        }, 20000);

        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          this.getBalance();
          this.getBlockedCashier();
          this.getLastTwoGames();
          // if (this.whichToShow == "keno") {

          if (this.currentGame.length == 0) {
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.currentGame[0].game_status == "pending") {
              if (this.timeInSeconds > 0) {
                this.isPlaying = false;
                this.timeInSeconds--;
                let hours = Math.floor(this.timeInSeconds / 3600);
                this.minutes = Math.floor(
                  (this.timeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.seconds =
                  this.timeInSeconds - hours * 3600 - this.minutes * 60;

                if (this.minutes < 10) {
                  this.minutes = "0" + this.minutes;
                }
                if (this.seconds < 10) {
                  this.seconds = "0" + this.seconds;
                }

                this.timePercent = this.timePercent + 0.53764;
              } else {
                this.selectedNumbers = [];
                this.selectedEvenOddAndHighLow = [];
                this.evenOddInputMoney = [];
                this.selectedNumberOptionCounter = 0;
                this.numberInputMoney = [];
                this.isPlaying = true;
              }
            } else {
              this.selectedNumbers = [];
              this.selectedEvenOddAndHighLow = [];
              this.evenOddInputMoney = [];
              this.selectedNumberOptionCounter = 0;
              this.numberInputMoney = [];
              this.isPlaying = true;
            }
          }
        } else {
          this.getBalance();
        }
      }, 1000);
      setInterval(async () => {
        if (this.copyMessage != "copy") this.spinWatchData();
        await this.getCurrentSpinGame();
        if (Number(this.minutes) == 1 && Number(this.seconds) <= 52)
          await axios.get(
            this.$store.state.spinCashierURL.split("graphql")[0] + "getApp"
          );
        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          // if (this.whichToShow == "spin") {

          this.getLastTwoSpinGames();

          if (this.spinCurrentGame.length == 0) {
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.spinCurrentGame[0].game_status == "pending") {
              if (this.spinTimeInSeconds > 0) {
                this.spinIsPlaying = false;
                this.spinTimeInSeconds--;
                let hours = Math.floor(this.spinTimeInSeconds / 3600);
                this.spinMinutes = Math.floor(
                  (this.spinTimeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.spinSeconds =
                  this.spinTimeInSeconds - hours * 3600 - this.spinMinutes * 60;

                if (this.spinMinutes < 10) {
                  this.spinMinutes = "0" + this.spinMinutes;
                }
                if (this.spinSeconds < 10) {
                  this.spinSeconds = "0" + this.spinSeconds;
                }

                this.spinTimePercent = this.spinTimePercent + 0.4717;
              } else {
                this.spinIsPlaying = true;
                this.selectedSpinNumbers = [];

                this.spinInputMoney = [];
              }
            } else {
              this.spinIsPlaying = true;
              this.selectedSpinNumbers = [];

              this.spinInputMoney = [];
            }
          }
        }
      }, 1000);
    } else this.$router.push({ name: "login" });

    this.isLoad = false;
  },
};
</script>
<style scoped>
#loginBtn {
  background-color: #37b34a;
}
#navBar {
  background-color: white;
}
#numbers,
#selectedNumbers {
  border-radius: 4px;
  background-color: rgba(41, 57, 73, 255);
  color: white;
}
#numbers:hover {
  background-color: rgba(41, 20, 30, 255);
}

#ticketListVertically {
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: scaleX(-1);
}

#clearBtn:hover,
#applyToAll:hover {
  background-color: rgba(41, 20, 30, 255);
  color: white;
}
#clearBtn {
  border-radius: 4px;
  background-color: rgb(233, 68, 95);
  color: white;
}
#printBtn {
  background-color: rgba(41, 57, 73, 255);
}

#applyToAll {
  border-radius: 4px;
  background-color: white;
  color: grey;
}
.circle {
  width: 43px;
  height: 43px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 22%;
  padding-top: 9px;
  font-size: 16px;
  background-color: #c04102;
}

#selectedSpinNumcircle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: green;
  border: 2px dashed white;
}

.circle2 {
  width: 43px;
  height: 43px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 22%;
  padding-top: 9px;
  font-size: 16px;
  background-color: #d35e1f;
}

.circle1 {
  width: 43px;
  height: 43px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 22%;
  padding-top: 9px;
  font-size: 16px;
  background-color: #007000;
}

.circle21 {
  width: 43px;
  height: 43px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 22%;
  padding-top: 9px;
  font-size: 16px;
  background-color: #007000;
}

.circle2:hover,
.circle:hover,
.circle21:hover,
.circle1:hover {
  background-color: #9dc39c;
}

#selectedNum {
  border-radius: 4px;
}
.icon {
  display: inline-block;
  height: 28px;
  width: 37px;
  background-size: cover;
}

.dicon {
  display: inline-block;
  height: 32px;
  width: 55px;
  background-size: cover;
}

.gicon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(282deg)
    brightness(106%) contrast(102%);
}

.kid {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(282deg)
    brightness(106%) contrast(102%);
}

.seicon {
  filter: invert(22%) sepia(78%) saturate(5539%) hue-rotate(109deg)
    brightness(100%) contrast(104%);
  opacity: 0.6;
}

.imgicons {
  opacity: 0.6;
}

.clickedicons {
  filter: invert(22%) sepia(78%) saturate(5539%) hue-rotate(109deg)
    brightness(100%) contrast(104%);
  opacity: 0.7;
}

.imgicons:hover,
.imgicons:focus {
  filter: invert(22%) sepia(78%) saturate(5539%) hue-rotate(109deg)
    brightness(100%) contrast(104%);
  opacity: 0.7;
}

#btns {
  background-color: #bb4307;
}
#ebtns {
  background-color: #fa837f;
}
#tbtns {
  background-color: #d75d1a;
}
#inbtns {
  background-color: #fffd83;
}
#btns:hover #inbtns {
  background-color: #0b6919;
}

#ebtns:hover #inbtns {
  background-color: #0b6919;
}

#tbtns:hover #inbtns {
  background-color: #0b6919;
}

#addBet {
  border: 1px solid white;
  border-radius: 1px;
  background-color: #0bae16;
  font-size: 16px;
  font-weight: normal;
}

#addBet:hover {
  border: 0px;
}

#removeT:hover {
  cursor: default;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out 0s;
}

#removeT {
  transition: all 0.3s ease-in-out 0s;
}

#rbtn {
  background-color: #37b34a;

  border: 1px solid #37b34a;
  color: white;
}

#rbtn:hover {
  background-color: white;
  border: 1px solid #37b34a;
  color: green;
}

#snum {
  background-color: #ff0000;
}
#snum:hover {
  background-color: #a05136;
}
#snumb {
  background-color: black;
}

#snumb:hover {
  background-color: rgb(81, 121, 81);
}

#report {
  max-width: 1000px;
  margin: auto;
}

.horizontal-scroll {
  scrollbar-width: initial;
  height: auto;
}

.outer {
  display: flex;
  flex: 1;
  height: 100%;

  flex-flow: row nowrap;
  align-items: center;

  width: fit-content;
  max-width: 985px;
  display: flex;
  overflow-x: auto;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.inner-content {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
}

.inner-content:not(:first-of-type) {
  margin-left: 0px;
}
#reportsum table tr th {
  /* Important */
  position: -webkit-sticky;
  position: sticky;

  z-index: 1;
}

#sumd:nth-child(odd) {
  background-color: #f1f1f1;
}

#reportsum table tr:hover {
  background-color: #c0bfbf;
}

#reportsum table tr th {
  border: 0px;
}
#hover tr:hover {
  background-color: #c0bfbf;
}

#resuid:nth-child(even) {
  background-color: #f1f1f1;
}

#reportsum table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#reportsum table tr td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px;
  padding-right: 5px;
}

.cashoption::-webkit-scrollbar {
  display: none;
}

#dropm {
  width: 500px;
}
</style>
