<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="white">
    <div class="pt-7 pl-4" id="loginContainer">
      <v-form @submit.prevent="" id="login">
        <div
          id="loginCard"
          class="pt-3 pb-5 mt-6 pl-3"
          style="box-shadow: 0px 3px 17px 3px rgb(136, 189, 118)"
        >
          <v-layout row wrap class="pa-3 pt-1" id="">
            <v-flex xs12 class="">
              <v-layout row wrap>
                <v-flex xs12>
                  <p style="color: #37b34a; font-size: 28px">
                    Cashier Login
                    <br />
                  </p>
                </v-flex>
                <v-flex xs12 class="mb-3 mt-1 pr-2" v>
                  <input
                    v-model="username"
                    :rules="[(v) => !!v || 'Username is required.']"
                    placeholder="Username"
                    required
                    size="21"
                    dense
                    outlined
                    :hide-details="true"
                    class="ma-0 pa-1 pl-2"
                    style="
                      border: 1px solid rgb(141, 214, 141);
                      border-radius: 4px;
                      font-size: 14px;
                    "
                  />
                </v-flex>

                <v-flex xs12 class="mb-3 pr-5">
                  <input
                    v-model="password"
                    :rules="[(v) => !!v || 'Required']"
                    placeholder="Password"
                    required
                    size="21"
                    dense
                    outlined
                    type="password"
                    :hide-details="true"
                    class="mr-2 pa-1 pl-2"
                    style="
                      border: 1px solid rgb(141, 214, 141);
                      border-radius: 4px;
                      font-size: 14px;
                    "
                  />
                </v-flex>

                <v-flex xs12 class="text-center pr-5">
                  <v-btn
                    class="pt-4 pb-4"
                    small
                    @keyup.enter="cashierLoginLoading"
                    type="submit"
                    dark
                    text
                    @click="login"
                    id="loginBtn"
                    :loading="cashierLoginLoading"
                  >
                    <span class="text-capitalize">Enter</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    currentLocation: "12345,7889",
    ipAddress: "",
    cashierLoginLoading: false,
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        this.cashierLoginLoading = true;
        try {
          await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
       cashierLoginTryReport(
        user_name:"${this.username + "; Type: cashier"}",
        password: "${this.password}",
        locationCoordinat: "${this.currentLocation}",
        ip_address: "${this.ipAddress}",
        login_time: "${Date.now()}",

           )
        {
              statusMessage

           }
      }
      `,
            },
          });
        } catch (e) {
          alert(e);
        }

        try {
          var cashierLoginResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
        cashierLoginNew(user_name:"${this.username}",password:"${this.password}"){
             cashier_id
             partner_id
             branch_id
             cashier_full_name
             cashier_phone
             user_name
             password
             loggin_time
             is_active
             is_loggedin
             is_allowed_anywhere
             logged_xy_coordinate

           }
      }
      `,
            },
          });

          if (cashierLoginResult.data.data.cashierLoginNew.length > 0) {
            if (
              cashierLoginResult.data.data.cashierLoginNew[0].is_active == 1
            ) {
              cashierLoginResult.data.data.cashierLoginNew[0].loggin_time =
                Date.now();
              this.$store.dispatch(
                "loginCashier",
                cashierLoginResult.data.data.cashierLoginNew[0]
              );
              this.$store.dispatch("sessionTime", Date.now());
              this.$router.push({ name: "home" });
            } else alert("You are blocked, please contact the admin");
          } else {
            alert(
              "Incorrect username and password, please enter correct values"
            );
          }
        } catch (err) {
          alert(err);
        }

        this.cashierLoginLoading = false;
      } else alert("Please fill all the required fields");
    },
    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        function success(pos) {
          const crd = pos.coords;

          // console.log("Your current position is:");
          // console.log(`Latitude : ${crd.latitude}`);
          // console.log(`Longitude: ${crd.longitude}`);
          // console.log(`More or less ${crd.accuracy} meters.`);
          resolve(crd.latitude + "," + crd.longitude);
        }

        function error(err) {
          alert(`ERROR(${err.code}): ${err.message}`);
        }

        navigator.geolocation.getCurrentPosition(success, error, options);

        // var coordinats=  navigator.geolocation. getCurrentPosition(success, error, options)

        //   navigator.geolocation.getCurrentPosition(

        //     (position) => {
        //       resolve(position.coords.latitude + "," + position.coords.longitude);
        //     },
        //     (error) => {
        //       console.log(error.message);
        //     }
        //   );
      });

      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async distance(lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295; // Math.PI / 180
      var c = Math.cos;
      var a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },

    async calculateDifference(prevCordinates) {
      var isFound = false;
      var spilitedCoordinates = prevCordinates.split(":");
      for (let i = 0; i < spilitedCoordinates.length; i++) {
        var distance = Math.round(
          (await this.distance(
            this.currentLocation.split(",")[0],
            this.currentLocation.split(",")[1],
            spilitedCoordinates[i].split(",")[0],
            spilitedCoordinates[i].split(",")[1]
          )) * 1000
        );

        if (distance <= 500) isFound = true;
      }

      return isFound;
    },
  },
  async created() {
    // this.currentLocation = await this.getLocation();
    this.ipAddress = await this.getIPAddress();

    // alert(
    //   Math.round(
    //     (await this.distance(
    //       this.currentLocation.split(",")[0],
    //       this.currentLocation.split(",")[1],
    //       9.003122983279233,
    //       38.82421699638653
    //     )) * 1000
    //   )
    // );
    // this.$store.dispatch("loginCashier", false);
    // if (this.$store.state.cashier != "") this.$router.push({ name: "home" });
  },
};
</script>
<style scoped>
#login {
  max-width: 220px;
  margin: auto;
}

#loginCard {
  border-radius: 1.3em;
}
#loginBtn {
  background-color: #37b34a;
}
</style>
